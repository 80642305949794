import React from 'react';
import { Card, Container, Header, Icon, Segment } from 'semantic-ui-react';
import { ScrollToTopOnMount } from '../../ScrollToTopOnMount';
import './Contact.css';

export class Contact extends React.PureComponent {
  render() {
    return (
      <Container>
        <ScrollToTopOnMount/>
        <Header as='h1' icon textAlign='center'>
          <Icon name='phone' circular/>
          <Header.Content>+48 605 545 922</Header.Content>
          <Header.Subheader>Centrum Obsługi Klienta</Header.Subheader>
        </Header>
        <Segment basic textAlign='center' size='large'>
          Pracujemy od poniedziałku do piątku w godzinach od 8.00 do 16.00 
        </Segment>
        <Segment basic textAlign='center' size='huge' className="contactSegment">
          Firma Handlowa Furnitex s.c.
          <br/>Radosław Roszak & Adrian Roszak
          <br/>ul. Przemysłowa 85
          <br/>62-510 Konin
        </Segment>
        <Card.Group className='contactCards' itemsPerRow={ 3 } stackable>
          <Card>
            <Card.Content textAlign="center">
              <Card.Header>Radosław Roszak</Card.Header>
              <Card.Meta>Obsługa sklepów stacjonarnych</Card.Meta>
              <Card.Description>
                <Icon name="phone"/>
                +48 609 668 069
                <br/>
                <a href="mailto:radek.roszak@furnitex.pl">radek.roszak@furnitex.pl</a>
              </Card.Description>
            </Card.Content>
          </Card>
          <Card>
            <Card.Content textAlign="center">
              <Card.Header>Małgorzata Marciniak</Card.Header>
              <Card.Meta>Koordynator ds. sprzedaży</Card.Meta>
              <Card.Description>
                <Icon name="phone"/>
                +48 605 545 922
                <br/>
                <a href="mailto:zamowienia@furnitex.pl">zamowienia@furnitex.pl</a>
              </Card.Description>
            </Card.Content>
          </Card>
          <Card>
            <Card.Content textAlign="center">
              <Card.Header>Adrian Roszak</Card.Header>
              <Card.Meta>Obsługa sklepów internetowych</Card.Meta>
              <Card.Description>
                <Icon name="phone"/>
                +48 608 357 848
                <br/>
                <a href="mailto:adrian.roszak@furnitex.pl">adrian.roszak@furnitex.pl</a>
              </Card.Description>
            </Card.Content>
          </Card>
        </Card.Group>

      </Container>
    );
  }
}
