import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Button, Form, Segment, Message, TextArea } from 'semantic-ui-react';
import { NewOrderProductsList } from './NewOrderProductsList';
import { OrderTotalPriceMessage } from './OrderTotalPriceMessage';
import { Redirect } from 'react-router-dom';
import { isAvailable } from '../../../utils/product';
import { ClearBasketConfirmationModal } from './ClearBasketConfirmationModal';
import './Orders.css';

export class NewOrderForm extends React.PureComponent {
  static propTypes = {
    updateProductInBasket: PropTypes.func.isRequired,
    updateNewOrderCustomerInfo: PropTypes.func.isRequired,
    newOrder: PropTypes.object.isRequired,
    order: PropTypes.func.isRequired,
    clearBasket: PropTypes.func.isRequired,
    showOffer: PropTypes.func.isRequired,
  }

  state = { updatedIds: [], now: moment.now() }

  /** Filters out products in basket with count == 0,
   *  except updated products if keepUpdated is set to true. */
  filterProductsInBasket(productsInBasket, keepUpdated, now) {
    let allProductsAvailable = true;
    let filteredProductsInBasket = [];
    for (let id in productsInBasket) {
      const productInBasket = productsInBasket[id];
      const hasBeenUpdated = this.state.updatedIds.indexOf(productInBasket.product.id) !== -1;
      if (productInBasket && ((keepUpdated && hasBeenUpdated) || productInBasket.count > 0)) {
        filteredProductsInBasket.push(productInBasket);
        allProductsAvailable = allProductsAvailable && 
          (productInBasket.count === 0 || isAvailable(now, productInBasket.product.availableSince));
      }
    }
    return { productsInBasket: filteredProductsInBasket, allProductsAvailable };
  }

  updateProductInBasket = (data) => {
    this.setState({ updatedIds: this.state.updatedIds.concat([ data.product.id ]) });
    this.props.updateProductInBasket(data);
  }

  render() {
    const { newOrder } = this.props;
    const now = this.state.now;

    const { allProductsAvailable, productsInBasket } = 
      this.filterProductsInBasket(newOrder.productsInBasket, true, now);

    if (productsInBasket.length === 0) {
      return <Redirect to='/products'/>;
    }

    const isAnythingToOrder = 
      this.filterProductsInBasket(newOrder.productsInBasket, false, now).productsInBasket.length !== 0;

    const availabilityWarning = !allProductsAvailable ?
      <Message warning>
        <Message.Header>Uwaga!</Message.Header>
        Część zamawianych produktów nie jest aktualnie dostępna. 
        Wciąż możesz złożyć zamówienie, jednak czas dostawy tych produktów jest nieokreślony. 
      </Message>
        :
      null;

    return (
      <div>
        <Segment basic>
          <NewOrderProductsList productsInBasket={ productsInBasket } 
                                updateProductInBasket={ this.updateProductInBasket }/>
        </Segment>
        <OrderTotalPriceMessage totalNetPriceValue={ newOrder.totalNetPriceValue }/>
        <Form>
          <CustomerInfoField customerInfo={ newOrder.customerInfo }
                             updateCustomerInfo={ this.props.updateNewOrderCustomerInfo }
                             />
        </Form>
        { availabilityWarning }
        <Segment basic textAlign="right">
          <Button onClick={ this.props.showOffer }>Wróć do oferty</Button>
          <ClearBasketConfirmationModal 
              trigger={ <Button disabled={ newOrder.totalCount === 0 }>Anuluj</Button> }
              clearBasket={ this.props.clearBasket }
              />
          <Button color='red'
                  disabled={ !isAnythingToOrder } 
                  onClick={ this.props.order }>Zamów</Button>
        </Segment>
      </div>
    );
  }
}

class CustomerInfoField extends React.Component {
  static propTypes = {
    customerInfo: PropTypes.string.isRequired,
    updateCustomerInfo: PropTypes.func.isRequired,
  }

  updateCustomerInfo = (event, data) => {
    this.props.updateCustomerInfo(data.value);
  }

  render() {
    return (
      <TextArea placeholder='Twoje uwagi (opcjonalne)'
                value={ this.props.customerInfo }
                onChange={ this.updateCustomerInfo }
                />
    );
  }
}
