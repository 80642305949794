const authEndpoint = "https://furnitex.pl/api/auth";
const contentEndpoint = "https://furnitex.pl/api/content";
const publicEndpoint = "https://furnitex.pl/api/public";
const storeEndpoint = "https://furnitex.pl/api/store";
const wholesaleEndpoint = "https://furnitex.pl/api/wholesale";

const Config = {
  endpoints: {
    auth: authEndpoint,
    content: contentEndpoint,
    store: storeEndpoint,
    public: publicEndpoint,
    wholesale: wholesaleEndpoint
  },
  urlBase: "https://furnitex.pl",
  emailConfirmationUrlPrefix: "https://furnitex.pl/registration/email-confirmation/",
  recoveryUrlPrefix: "https://furnitex.pl/login/recovery/",
  cloudinaryImagesPrefix: "https://res.cloudinary.com/softwrighters/image/upload/",
  imgWidth: {
    thumbnail: 400,
    normal: 800,
    fullscreen: 1280
  }
};

export default Config;
