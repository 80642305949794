import Config from '../config';
import { get, post, put, url } from './ApiClient';

function wholesaleUrl(path, params) {
  return url(Config.endpoints.wholesale, path, params);
}

const SessionCustomerError = {
  NotExists: 'NOT_EXISTS',
  Unknown: 'UNKNOWN',
};

function sessionCustomer(token) {
  return get(wholesaleUrl("customers/me"), token).then(
    response => response,
    error => {
      switch (error.statusText) {
        case 'Not Found':
          return Promise.reject(SessionCustomerError.NotExists);
        default:
          return Promise.reject(SessionCustomerError.Unknown);
      }
    }
  );
}

const ProductsError = {
  Unauthorized: 'UNAUTHORIZED',
  Unknown: 'UNKNOWN',
};

function products({ 
      sortBy,
      customerId, 
      categoryTag, 
      searchQuery, 
      flags, 
      sortOrder,
      lastIndex,
      lastId,
      take, 
      promoOnly,
      availableSince
    },
    token) {
  let params = {
    sortBy,
    categoryTag, 
    searchQuery, 
    flags, 
    sortOrder,
    lastIndex,
    lastId,
    take, 
    promoOnly,
    availableSince
  };
  const getPromise = customerId
    ? get(wholesaleUrl(`customers/${customerId}/products`, params), token)
    : get(wholesaleUrl('products', params), token);

  return getPromise.then(
    response => response,
    error => {
      switch (error.statusText) {
        case 'Unauthorized':
          return Promise.reject(ProductsError.Unauthorized);
        default:
          return Promise.reject(ProductsError.Unknown);
      }
    }
  );
}

const RetailProductsError = {
  Unknown: 'UNKNOWN',
};

function retailProducts({ 
      sortBy,
      categoryTag, 
      searchQuery, 
      flags, 
      sortOrder,
      lastIndex,
      lastId,
      take, 
      promoOnly,
      availableSince
    }) {
  let params = {
    sortBy,
    categoryTag, 
    searchQuery, 
    flags, 
    sortOrder,
    lastIndex,
    lastId,
    take, 
    promoOnly,
    availableSince
  };
  const getPromise = get(wholesaleUrl('retail/products', params));

  return getPromise.then(
    response => response,
    error => {
      switch (error.statusText) {
        default:
          return Promise.reject(ProductsError.Unknown);
      }
    }
  );
}

const OrdersError = {
  CustomerNotExists: 'CUSTOMER_NOT_EXISTS',
  Forbidden: 'FORBIDDEN',
  Unauthorized: 'UNAUTHORIZED',
  Unknown: 'UNKNOWN',
};

function orders({ customerId, state, take, sortOrder, lastId }, token) {
  const params = { customerId, state, take, sortOrder, lastId };
  return get(wholesaleUrl('orders', params), token).then(
    response => response,
    error => {
      switch (error.statusText) {
        case 'Not Found':
          return Promise.reject(OrdersError.CustomerNotExists);
        case 'Forbidden':
          return Promise.reject(OrdersError.Forbidden);
        case 'Unauthorized':
          return Promise.reject(OrdersError.Unauthorized);
        default:
          return Promise.reject(OrdersError.Unknown);
      }
    }
  );
}

const OrderError = {
  Forbidden: 'FORBIDDEN',
  Unauthorized: 'UNAUTHORIZED',
  Unkown: 'UNKNOWN',
};

function order({ customerId, orderedProducts, customerInfo }, token) {
  const data = { customerId, orderedProducts, customerInfo };
  return post(wholesaleUrl(`orders`), data, token).then(
    response => response,
    error => {
      switch (error.statusText) {
        case 'Forbidden':
          return Promise.reject(OrderError.Forbidden);
        case 'Unauthorized':
          return Promise.reject(OrderError.Unauthorized);
        default:
          return Promise.reject(OrderError.Unknown);
      }
    }
  );
}

const OrderDetailsError = {
  NotExists: 'NOT_EXISTS',
  Forbidden: 'FORBIDDEN',
  Unauthorized: 'UNAUTHORIZED',
  Unkown: 'UNKNOWN',
};

function orderDetails(id, token) {
  return get(wholesaleUrl(`orders/${id}`), token).then(
    response => response,
    error => {
      switch (error.statusText) {
        case 'Not Found':
          return Promise.reject(OrderDetailsError.NotExists);
        case 'Forbidden':
          return Promise.reject(OrderDetailsError.Forbidden);
        case 'Unauthorized':
          return Promise.reject(OrderDetailsError.Unauthorized);
        default:
          return Promise.reject(OrderDetailsError.Unknown);
      }
    }
  );
}

const ProductDetailsError = {
  NotExists: 'NOT_EXISTS',
  Forbidden: 'FORBIDDEN',
  Unauthorized: 'UNAUTHORIZED',
  Unkown: 'UNKNOWN',
};

function handleProductError(error) {
  switch (error.statusText) {
    case 'Not Found':
      return Promise.reject(ProductDetailsError.NotExists);
    case 'Unauthorized':
      return Promise.reject(ProductDetailsError.Unauthorized);
    case 'Forbidden':
      return Promise.reject(ProductDetailsError.Forbidden);
    default:
      return Promise.reject(ProductDetailsError.Unknown);
  }
}

function productDetails({ productId, customerId }, token) {
  if (customerId) {
    return get(wholesaleUrl(`customers/${customerId}/products/${productId}`), token).then(
      response => response,
      error => handleProductError(error)
    );
  }
  return get(wholesaleUrl(`products/${productId}`), token).then(
    response => response,
    error => handleProductError(error)
  );
}

const RetailProductDetailsError = {
  NotExists: 'NOT_EXISTS',
  Unkown: 'UNKNOWN',
};

function handleRetailProductError(error) {
  switch (error.statusText) {
    case 'Not Found':
      return Promise.reject(RetailProductDetailsError.NotExists);
    default:
      return Promise.reject(RetailProductDetailsError.Unknown);
  }
}

function retailProductDetails({ productId }) {
  return get(wholesaleUrl(`retail/products/${productId}`)).then(
    response => response,
    error => handleProductError(error)
  );
}

const CustomersError = {
  Unauthorized: 'UNAUTHORIZED',
  Forbidden: 'FORBIDDEN',
  Unknown: 'UNKNOWN',
};

function customers({ searchQuery, take, isActive, sortOrder, lastId }, token) {
  let params = {
    searchQuery,
    take,
    sortOrder,
    isActive,
    lastId,
  };
  return get(wholesaleUrl('customers', params), token).then(
    response => response,
    error => {
      switch (error.statusText) {
        case 'Unauthorized':
          return Promise.reject(CustomersError.Unauthorized);
        case 'Forbidden':
          return Promise.reject(CustomersError.Forbidden);
        default:
          return Promise.reject(CustomersError.Unknown);
      }
    }
  );
}

const CustomerError = {
  NotExists: 'NOT_EXISTS',
  Unauthorized: 'UNAUTHORIZED',
  Forbidden: 'FORBIDDEN',
  Unknown: 'UNKNOWN',
};

function customer(id, token) {
  return get(wholesaleUrl("customers/" + id), token).then(
    response => response,
    error => {
      switch (error.statusText) {
        case 'Not Found':
          return Promise.reject(CustomerError.NotExists);
        case 'Unauthorized':
          return Promise.reject(CustomerError.Unauthorized);
        case 'Forbidden':
          return Promise.reject(CustomerError.Forbidden);
        default:
          return Promise.reject(CustomerError.Unknown);
      }
    }
  );
}

const RegisterCustomerError = {
  AlreadyRegistered: 'ALREADY_REGISTERED',
  Unauthorized: 'UNAUTHORIZED',
  Unknown: 'UNKNOWN',
};

function registerCustomer(customer, token) {
  return post(wholesaleUrl("customers"), customer, token).then(
    response => response,
    error => {
      switch (error.statusText) {
        case 'Unauthorized':
          return Promise.reject(RegisterCustomerError.Unauthorized);
        case 'Conflict':
          return Promise.reject(RegisterCustomerError.AlreadyRegistered);
        default:
          return Promise.reject(RegisterCustomerError.Unknown);
      }
    }
  );
}

const UpdateCustomerError = {
  Unauthorized: 'UNAUTHORIZED',
  Forbidden: 'FORBIDDEN',
  NotExists: 'NOT_EXISTS',
  Unknown: 'UNKNOWN'
};

function updateCustomer(id, data, token) {
  return put(wholesaleUrl(`customers/${id}`), data, token).then(
    response => response,
    error => {
      switch (error.statusText) {
        case 'Unauthorized':
          return Promise.reject(UpdateCustomerError.Unauthorized);
        case 'Forbidden':
          return Promise.reject(UpdateCustomerError.Forbidden);
        case 'Not Found':
          return Promise.reject(UpdateCustomerError.NotExists);
        default:
          return Promise.reject(UpdateCustomerError.Unknown);
      }
    }
  );
}

const SaveProductForSaleError = {
  Unauthorized: 'UNAUTHORIZED',
  Forbidden: 'FORBIDDEN',
  Unknown: 'UNKNOWN'
};

function saveProductForSale(productId, data, token) {
  return put(wholesaleUrl(`products/${productId}`), data, token).then(
    response => response,
    error => {
      switch (error.statusText) {
        case 'Unauthorized':
          return Promise.reject(SaveProductForSaleError.Unauthorized);
        case 'Forbidden':
          return Promise.reject(SaveProductForSaleError.Forbidden);
        default:
          return Promise.reject(SaveProductForSaleError.Unknown);
      }
    }
  );
}

const UpdateOrderError = {
  Unauthorized: 'UNAUTHORIZED',
  Forbidden: 'FORBIDDEN',
  NotExists: 'NOT_EXISTS',
  Unknown: 'UNKNOWN'
};

function updateOrder(id, data, token) {
  return put(wholesaleUrl(`orders/${id}`), data, token).then(
    response => response,
    error => {
      switch (error.statusText) {
        case 'Unauthorized':
          return Promise.reject(UpdateOrderError.Unauthorized);
        case 'Forbidden':
          return Promise.reject(UpdateOrderError.Forbidden);
        case 'Not Found':
          return Promise.reject(UpdateOrderError.NotExists);
        default:
          return Promise.reject(UpdateOrderError.Unknown);
      }
    }
  );
}

const SendEmailToCustomersError = {
  Unauthorized: 'UNAUTHORIZED',
  Forbidden: 'FORBIDDEN',
  Success: 'SUCCESS',
};

function sendEmailToCustomers({ subject, body }, token) {
  return post(wholesaleUrl('notifications'), { subject, body }, token).then(
    response => response,
    error => {
      switch (error.statusText) {
        case 'Unauthorized':
          return Promise.reject(SendEmailToCustomersError.Unauthorized);
        case 'Forbidden':
          return Promise.reject(SendEmailToCustomersError.Forbidden);
        default:
          return Promise.reject(SendEmailToCustomersError.Unknown);
      }
    }
  );
}

const WholesaleApi = {
  RegisterCustomerError,
  registerCustomer,
  SessionCustomerError,
  sessionCustomer,
  ProductsError,
  products,
  RetailProductsError,
  retailProducts,
  OrderError,
  order,
  OrdersError,
  orders,
  OrderDetailsError,
  orderDetails,
  ProductDetailsError,
  productDetails,
  RetailProductDetailsError,
  retailProductDetails,
  CustomersError,
  customers,
  CustomerError,
  customer,
  UpdateCustomerError,
  updateCustomer,
  UpdateOrderError,
  updateOrder,
  SaveProductForSaleError,
  saveProductForSale,
  SendEmailToCustomersError,
  sendEmailToCustomers,
};

export default WholesaleApi;
