import React from 'react';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import 'react-datepicker/dist/react-datepicker.css';
import './ReduxDatePicker.css';

export class ReduxDatePicker extends React.PureComponent {
  handleChange = (value) => value 
    ? this.props.onChange(value.startOf('day').valueOf()) 
    : this.props.onChange(null);

  initialDate = () => !!this.props.value ? moment(this.props.value) : null;

  render() {
    return <DatePicker selected={ this.initialDate() } 
                       dateFormat="DD/MM/YYYY"
                       onChange={ this.handleChange }
                       todayButton='Dziś'
                       isClearable={ true }
                       />;
  }
}
