import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { PasswordRecovery } from './PasswordRecovery';
import { Login } from './Login';

export class LoginRouter extends React.PureComponent {
  defaultRedirect = () => <Redirect to='/'/>;
  render() {
    return (
      <Switch>
        <Route exact path='/login' component={ Login }/>
        <Route exact path='/login/recovery' component={ PasswordRecovery }/>
        <Route exact path='/login/recovery/:uuid' component={ PasswordRecovery }/>
        <Route path='/' render={ this.defaultRedirect }/>
      </Switch>
    );
  }
}
