import React from 'react';
import PropTypes from 'prop-types';
import { FullscreenPhotoShow } from './FullscreenPhotoShow.js';
import ImageGallery from 'react-image-gallery';
import "react-image-gallery/styles/css/image-gallery.css";
import "./PhotoGallery.css";

export class PhotoGallery extends React.PureComponent {
    static propTypes = {
      images: PropTypes.arrayOf(PropTypes.shape({
        normal: PropTypes.string.isRequired,
        fullscreen: PropTypes.string.isRequired
      })).isRequired,
    }

  state = { isFullscreen: false, imgIndex: 0 }

  goFullscreen = () => this.setState({ isFullscreen: true });

  leaveFullscreen = () => this.setState({ isFullscreen: false });

  slideChanged = (index) => this.setState({ imgIndex: index });
 
  render() {
    const fullscreenImages = this.props.images.map(i => i.fullscreen);
    const normalImages = this.props.images.map(i => i.normal);

    const fullscreen = this.state.isFullscreen
      ? <FullscreenPhotoShow images={ fullscreenImages } 
                             imageIndex={ this.state.imgIndex }
                             onCloseRequest={ this.leaveFullscreen }/>
      : null;

    return (
      <div>
        { fullscreen }
        <ImageGallery
          items={ normalImages.map(i => ({ original: i })) }
          slideInterval={ 2000 }
          showThumbnails={ false }
          showPlayButton={ false }
          onSlide={ this.slideChanged }
          renderFullscreenButton={ 
            (onClick, isFullscreen) => {
              return (
                normalImages.length > 0
                ? <button
                    type='button'
                    className={ `image-gallery-fullscreen-button${isFullscreen ? ' active' : ''}` }
                    onClick={ this.goFullscreen }
                  />
                : null
              );
            }
          }
        />
      </div>
    );
  }
}
