import React from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import { Container, Header, Icon, Message } from 'semantic-ui-react';
import { RegistrationForm } from './RegistrationForm';
import { RegistrationStep } from './RegistrationStep';
import { RegistrationState } from '../../../redux/registration';
import { ScrollToTopOnMount } from '../../ScrollToTopOnMount';
import { logout } from '../../../redux/login';

class RegistrationPure extends React.PureComponent {
  static propTypes = {
    registrationState: PropTypes.string.isRequired,
    user: PropTypes.object,
    logout: PropTypes.func.isRequired,
  };

  componentDidMount() {
    this.props.logout();
  }

  isRegistrationCompleted = () =>
    this.props.registrationState === RegistrationState.Success

  render() {
    const step =
      <RegistrationStep step={ this.isRegistrationCompleted() ? "confirm-email" : "create-account"}
                        className="mobile hidden"
                        />;
    const content =
      this.isRegistrationCompleted() ? this.renderRegistrationCompleted()
                                     : <RegistrationForm/>;
    return (
      <Container text textAlign='center'>
        <ScrollToTopOnMount/>
        <Header as='h1' icon textAlign='center'>
          <Icon name='signup' circular/>
          <Header.Content>Zarejestruj</Header.Content>
        </Header>
        { step }
        { content }
      </Container>
    );
  }

  renderRegistrationCompleted() {
    return (
      <Message success>
        <Message.Header>Sprawdź skrzynkę</Message.Header>
        <Icon name='mail outline'/>
        Wysłaliśmy do Ciebie e-mail z linkiem aktywacyjnym.
      </Message>
    );
  }
}

const mapStateToProps = state => ({
  registrationState: state.registration.registrationProcess.state,
  user: state.registration.form.user,
});

const mapDispatchToProps = dispatch => ({
  logout: () => dispatch(logout()),
});

export const Registration =
  connect(mapStateToProps, mapDispatchToProps)(RegistrationPure);
