import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Container, Header, Icon, Input, Loader, Segment } from 'semantic-ui-react';
import { actions, Control, Form } from 'react-redux-form';
import { Button, Card, Divider } from 'semantic-ui-react';
import { ScrollToTopOnMount } from '../../../ScrollToTopOnMount';
import { fetchCategories, CategoryListState } from '../../../../redux/store.js';
import { deleteCategory, DeleteCategoryState } from '../../../../redux/store.js';
import { upsertCategory, UpsertCategoryState } from '../../../../redux/store.js';
import { required } from '../../../../utils/common';
import { Messages } from "../../../Messages";
import './Categories.css';

class CategoriesPure extends React.PureComponent {
  static propTypes = {
    fetchCategories: PropTypes.func.isRequired,
    categoryList: PropTypes.object.isRequired,
    deleteCategory: PropTypes.func.isRequired,
    deleteCategoryProcess: PropTypes.object.isRequired,
    newCategoryName: PropTypes.string.isRequired,
    upsertCategory: PropTypes.func.isRequired,
    upsertCategoryProcess: PropTypes.object.isRequired,
    submitUpsertCategoryForm: PropTypes.func.isRequired,
  }

  componentDidMount() {
    this.props.fetchCategories();
  }

  onNewCategoryNameChange = (value) => {
    this.setState({ newCategoryName: value.trim() });
  }

  upsertCategoryErrorText() {
    const state = this.props.upsertCategoryProcess.state;
    switch (state) {
      case UpsertCategoryState.ErrorNoPermissions:
        return "Nie masz uprawnień.";
      case UpsertCategoryState.ErrorUnknown:
        return "Nieznany błąd. Spróbuj ponownie.";
      default:
        return null;
    }
  }

  renderUpsertCategoryLoader() {
    const state = this.props.upsertCategoryProcess.state;
    return state === UpsertCategoryState.InProgress
      ? <Segment basic>
          <Loader active/>
        </Segment>
      : null;
  }

  deleteCategoryErrorText() {
    const state = this.props.deleteCategoryProcess.state;
    switch (state) {
      case DeleteCategoryState.ErrorContainsProducts:
        return "Kategoria zawiera produkty.";
      case DeleteCategoryState.ErrorNoPermissions:
        return "Nie masz uprawnień.";
      case DeleteCategoryState.ErrorNotExists:
        return "Kategoria nie istnieje.";
      case DeleteCategoryState.ErrorUnknown:
        return "Nieznany błąd. Spróbuj ponownie.";
      default:
        return null;
    }
  }

  renderDeleteCategoryError(tag) {
    const message = this.deleteCategoryErrorText();
    return tag === this.props.deleteCategoryProcess.categoryTag && message !== null
      ? message : null;
  }

  renderDeleteCategoryLoader(tag) {
    const process = this.props.deleteCategoryProcess;
    return tag === process.categoryTag && DeleteCategoryState.InProgress === process.state
      ? <Segment basic>
          <Loader active/>
        </Segment> 
      : null;
  }

  deleteCategory = (tag) => () => this.props.deleteCategory(tag);

  upsertCategory = (category) => this.props.upsertCategory(category.name);

  render() {
    const { categoryList } = this.props;
    const state = categoryList.state;

    const loader = state === CategoryListState.Fetching
      ? <Segment basic>
          <Loader active={ true }/>
        </Segment>
      : null;

    const haveCategories = state === CategoryListState.Idle && categoryList.categories.length > 0;

    const unknownErrorMessage = state === CategoryListState.ErrorUnknown
      ? Messages.UnknownError 
      : null;

    const categoryCards = haveCategories
      ? categoryList.categories.map(c => {
        return (
          <Card raised key={ c.tag } className="category">
            <Card.Content>
              <Card.Header>{ c.name }</Card.Header>
              <Card.Meta>
                { this.renderDeleteCategoryError(c.tag) }
                { this.renderDeleteCategoryLoader(c.tag) }
              </Card.Meta>
            </Card.Content>
            <Card.Content extra>
              <Button basic 
                      compact 
                      color='red'
                      onClick={ this.deleteCategory(c.tag) }
                      >
                Usuń
              </Button>
            </Card.Content>
          </Card>
        );
      })
      : null;

    const addNewCard =
      <Card raised className="category">
        <Card.Content>
          <Form model="store.form.upsertCategory"
                onSubmit={ this.upsertCategory }
                >
            <Control model='.name' 
                     component={ CategoryNameInput } 
                     validators={{ required }}
                     />
          </Form>

          <Card.Meta>
            { this.renderUpsertCategoryLoader() }
            { this.upsertCategoryErrorText() }
          </Card.Meta>
        </Card.Content>
        <Card.Content extra>
          <Button basic
                  compact
                  color='green'
                  disabled={ this.props.newCategoryName === "" }
                  onClick={ this.props.submitUpsertCategoryForm }
                  >
            Dodaj
          </Button>
        </Card.Content>
      </Card>;

    const categories = haveCategories
      ? <Card.Group itemsPerRow={ 4 } 
                    textAlign='center' 
                    stackable
                    className="categories"
                    >
          { addNewCard }
          { categoryCards }
        </Card.Group>
      : null;

    return (
      <Container textAlign='center'>
        <ScrollToTopOnMount/>
        <Header as='h1' icon textAlign='center'>
          <Icon name='tags' circular/>
          <Header.Content>Kategorie</Header.Content>
        </Header>
        <Divider hidden/>
        { loader }
        { categories }
        { unknownErrorMessage }
      </Container>
    );

  }
}

class CategoryNameInput extends React.Component {
  render() {
    return <Input fluid
                  inverted
                  focus
                  placeholder="Nowa kategoria"
                  size='large'
                  value={ this.props.value }
                  onChange={ this.props.onChange }
                  />;
  }
}

const mapStateToProps = state => ({
  categoryList: state.store.categoryList,
  deleteCategoryProcess: state.store.deleteCategoryProcess,
  upsertCategoryProcess: state.store.upsertCategoryProcess,
  newCategoryName: state.store.form.upsertCategory.name,
});

const mapDispatchToProps = dispatch => ({
  fetchCategories: () => dispatch(fetchCategories()),
  deleteCategory: (tag) => dispatch(deleteCategory(tag)),
  upsertCategory: (name) => dispatch(upsertCategory(name)),
  submitUpsertCategoryForm: () => dispatch(actions.submit("store.form.upsertCategory"))
});

export const Categories = connect(mapStateToProps, mapDispatchToProps)(CategoriesPure);
