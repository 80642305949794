import React from 'react';
import { Icon } from 'semantic-ui-react';
import { PropTypes } from 'prop-types';
import { NavLink } from 'react-router-dom';

export class SimpleMenuItem extends React.PureComponent {
  static propTypes = {
    location: PropTypes.object.isRequired,
    icon: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    to: PropTypes.string.isRequired,
    compact: PropTypes.bool
  }

  render() {
    return (
      <NavLink className='item' to={ this.props.to }>
        <Icon name={ this.props.icon }/>
        { this.props.compact ? null : <span>{ this.props.name }</span> }
      </NavLink>
    );
  }
}

export class LoginLogout extends React.PureComponent {
  static propTypes = {
    user: PropTypes.object,
    location: PropTypes.object.isRequired,
    compact: PropTypes.bool
  }

  renderLogout() {
    return (
      <SimpleMenuItem to='/logout'
                      name='Wyloguj'
                      icon='sign out'
                      location={ this.props.location }
                      compact={ this.props.compact }
                      />
    );
  }

  renderLogin() {
    return (
      <SimpleMenuItem to='/login'
                      name='Zaloguj'
                      icon='sign in'
                      location={ this.props.location }
                      />
    );
  }

  render() {
    if (this.props.user) {
      return this.renderLogout();
    } else {
      return this.renderLogin();
    }
  }
}

export class Register extends React.PureComponent {
  static propTypes = {
    isLoggedIn: PropTypes.bool.isRequired,
    location: PropTypes.object.isRequired,
    compact: PropTypes.bool,
  }

  render() {
    if (!this.props.isLoggedIn) {
      return (
        <SimpleMenuItem to='/registration'
                        name='Zarejestruj'
                        icon='signup'
                        location={ this.props.location }
                        />
      );
    } else {
      return null;
    }
  }
}
