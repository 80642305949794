import React from 'react';
import PropTypes from 'prop-types';
import { Dropdown } from 'semantic-ui-react';
import '../utils/common';

export class ReduxItemPicker extends React.PureComponent {
  onChange = (tag) => {
    this.props.onChange(tag);
  }
  render() {
    return <ItemPicker selectedTag={ this.props.value } 
                       items={ this.props.items }
                       onResultSelect={ this.onChange }
                       fluid
                       />;
  }
}

export class ItemPicker extends React.PureComponent {
  static propTypes = {
    selectedTag: PropTypes.string,
    items: PropTypes.arrayOf(PropTypes.shape({
      tag: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired
    })).isRequired,
    onResultSelect: PropTypes.func.isRequired,
    placeholder: PropTypes.string,
    fluid: PropTypes.bool
  }

  handleChange = (e, data) => {
    this.props.onResultSelect(data.value);
  }

  render () {
    const options = this.props.items.map(item => ({
      key: item.tag,
      value: item.tag,
      text: item.name,
    }));
    return (
      <Dropdown search 
                selection
                placeholder={ this.props.placeholder }
                options={ options }
                value={ this.props.selectedTag }
                onChange={ this.handleChange }
                noResultsMessage="Nie znaleziono"
                fluid={ this.props.fluid }
                />
    );
  }
}
