import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { FileDocuments } from './FileDocuments';

export class FileDocumentsRouter extends React.PureComponent {
  defaultRedirect = () => <Redirect to='/'/>;
  render() {
    return (
      <Switch>
        <Route exact path='/file-documents' component={ FileDocuments }/>
        <Route path='/' render={ this.defaultRedirect }/>
      </Switch>
    );
  }
}
