// TODO: Many of those can be object methods.

export function orderedProductsNetPriceValue(orderedProducts) {
  let result = 0;
  for (const idx in orderedProducts) {
    const product = orderedProducts[idx];
    result += orderedProductNetPriceValue(product);
  }
  return result;
}

export function orderedProductNetPriceValue({ postDeliveryNetPriceValue, totalNetPriceValue }) {
  const postNetPriceValue = postDeliveryNetPriceValue ? postDeliveryNetPriceValue : 0;
  return totalNetPriceValue + postNetPriceValue;
}

export function productPrice(product) {
  const price = product.price;

  if (price === undefined) {
    return null;
  }

  if (price.discountPercent !== 0) {
    const promoNetPrice = price.netPrice * ((100 - price.discountPercent) / 100);
    return { netPrice: price.netPrice, promoNetPrice };
  }

  return { netPrice: price.netPrice };
}

export function productTotalNetPriceValue(product, count, withPostDelivery) {
  const itemPrice = productPrice(product, false);

  if (itemPrice === null) {
    return null;
  }

  const effectiveItemPrice = itemPrice.promoNetPrice !== undefined 
                           ? itemPrice.promoNetPrice : itemPrice.netPrice;
  const price = effectiveItemPrice * count;
  return withPostDelivery ? price + productPostDeliveryNetPriceValue(product, count) : price;
}

export function grossPrice(netPriceValue) {
  return netPriceValue * 1.23;
}

export function roundPriceValue(priceValue) {
  return Math.round(priceValue * 100) / 100;
}

export function addVatRoundToInteger(priceValue) {
  return Math.ceil(priceValue * 1.23);
}

export function productPostDeliveryNetPriceValue(product, count) {
  const delivery = product.postPackageDelivery;
  const packages = Math.ceil(count / delivery.packageCapacity);
  return packages * delivery.packageNetPrice;
}