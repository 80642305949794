import { PropTypes } from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Dropdown, Image, Menu } from 'semantic-ui-react';
import logo from '../../../logo-white.png';
import './MainMenu.css';
import { LoginLogout, Register, SimpleMenuItem } from './MenuItems';

export class MainMenu extends React.PureComponent {
  static propTypes = {
    location: PropTypes.object.isRequired,
    user: PropTypes.object,
    isCustomer: PropTypes.bool.isRequired,
    isActiveCustomer: PropTypes.bool.isRequired,
    isAdmin: PropTypes.bool.isRequired,
  }

  render() {
    const compact = this.props.compact;
    return (
      <Menu inverted>
        <Container>
          <Link to='/'>
            <Menu.Item header icon>
              <Image src={ logo } size={ compact ? 'tiny': 'small' }/>
            </Menu.Item>
          </Link>
          <SimpleMenuItem to='/products'
                          name='Oferta'
                          icon='shop'
                          location={ this.props.location }
                          compact={ compact }
                          />

          <SimpleMenuItem to='/contact'
                          name='Kontakt'
                          icon='phone'
                          location={ this.props.location }
                          compact={ compact }/>
 
          { this.props.isCustomer
              ? <SimpleMenuItem to='/orders'
                                name='Zamówienia'
                                icon='list ul'
                                location={ this.props.location }
                                compact={ compact }
                                />
              : null
          }

          { this.props.isAdmin
              ? <SimpleMenuItem to='/account/settings'
                                name='Konto'
                                icon='user'
                                location={ this.props.location }
                                compact={ compact }
                                />
              : null
          }

          { this.props.isActiveCustomer
              ? <SimpleMenuItem to='/file-documents'
                                name='Dokumenty'
                                icon='file'
                                location={ this.props.location }
                                compact={ compact }
                                />
              : null
          }

          <Menu.Menu position='right'>
            { this.props.isAdmin
                ? <Dropdown item simple text='Administracja'>
                    <Dropdown.Menu>
                      <SimpleMenuItem to='/admin/file-documents' name='Dokumenty' icon='file' location={ this.props.location } />
                      <SimpleMenuItem to='/admin/categories' name='Kategorie' icon='tags' location={ this.props.location } />
                      <SimpleMenuItem to='/admin/customers' name='Klienci' icon='address book' location={ this.props.location } />
                      <SimpleMenuItem to='/admin/products' name='Produkty' icon='book' location={ this.props.location } />
                      <SimpleMenuItem to='/admin/announcement' name='Ogłoszenie' icon='bullhorn' location={ this.props.location } />
                      <SimpleMenuItem to='/admin/orders' name='Zamówienia' icon='inbox' location={ this.props.location } />
                    </Dropdown.Menu>
                  </Dropdown>
                : !!this.props.user
                ? <Dropdown item simple text='Więcej'>
                    <Dropdown.Menu>
                      <SimpleMenuItem to='/public-api-info'
                                      name='Integracja'
                                      icon='plug'
                                      location={ this.props.location }
                                      />
                      <SimpleMenuItem to='/account/settings'
                                      name='Twoje konto'
                                      icon='user'
                                      location={ this.props.location }
                                      />
                    </Dropdown.Menu>
                  </Dropdown>
                : null
            }
            <LoginLogout location={ this.props.location }
                         user={ this.props.user }
                         compact={ compact }
                         />
            { !compact
                ? <Register location={ this.props.location }
                            isLoggedIn={ !!this.props.user }
                            />
                : null
            }
          </Menu.Menu>
        </Container>
      </Menu>
    );
  }
}
