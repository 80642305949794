import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Header, Item, ItemGroup, Label } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { ProductInBasketEdit } from './ProductInBasketEdit';
import { AvailabilityLabel } from '../Products/ProductLabels';
import { productTotalNetPriceValue, roundPriceValue } from '../../../utils/price';
import { productPostDeliveryNetPriceValue } from '../../../utils/price';
import './NewOrderProductsList.css';
import { tryResizeImage } from '../../../utils/common';
import Config from '../../../config';

export class NewOrderProductsList extends React.PureComponent {
  static propTypes = {
    productsInBasket: PropTypes.arrayOf(PropTypes.object).isRequired,
    updateProductInBasket: PropTypes.func.isRequired,
  }

  render() {
    const items = this.props.productsInBasket.map(productInBasket =>
      <NewOrderProductItem key={ productInBasket.product.id }
                           productInBasket={ productInBasket }
                           updateProductInBasket={ this.props.updateProductInBasket }
                           />
    );

    return (
      <ItemGroup divided className="newOrderProductsList">
        { items }
      </ItemGroup>
    );
  }
}

class NewOrderProductItem extends React.PureComponent {
  static propTypes = {
    updateProductInBasket: PropTypes.func.isRequired,
    productInBasket: PropTypes.object.isRequired,
  }

  render() {
    const { product, count, withPostDelivery } = this.props.productInBasket;
    const netPrice = roundPriceValue(productTotalNetPriceValue(product, count, withPostDelivery));
    const deliveryPrice = withPostDelivery 
      ? roundPriceValue(productPostDeliveryNetPriceValue(product, count)) : 0;
    const deliveryPriceInfo = withPostDelivery
      ? <Label>dostawa: { deliveryPrice } PLN</Label> : null;
    return (
      <Item>
        <Item.Image className="orderedProductItemImage" 
                    size="small" 
                    src={ tryResizeImage(product.images[0], Config.imgWidth.thumbnail) }
                    />
        <Item.Content verticalAlign="middle">
          <Grid columns={ 2 } verticalAlign="middle" stackable>
            <Grid.Row>
              <Grid.Column>
                <Header className="orderProductItemHeader">
                  <Header.Content>
                    <Link to={ '/products/' + product.id }>{ product.index }</Link>
                  </Header.Content>
                  <Header.Subheader>
                    <Item.Meta>{ product.name }</Item.Meta>
                  </Header.Subheader>
                </Header>
                <Item.Extra>
                  <AvailabilityLabel availableSince={ product.availableSince }/>
                  <Label color="blue">{ netPrice } PLN</Label>
                  { deliveryPriceInfo }
                </Item.Extra>
              </Grid.Column>
              <Grid.Column textAlign="right">
                <ProductInBasketEdit productInBasket={ this.props.productInBasket }
                                     updateProductInBasket={ this.props.updateProductInBasket }
                                     iconPosition="left"
                                     icon="shop"
                                     buttons="side"
                                     />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Item.Content>
      </Item>
    );
  }
}
