import { PropTypes } from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Segment } from 'semantic-ui-react';
import { fetchActiveAnnouncement } from '../../redux/announcements';
import { clearBasket } from '../../redux/orders';
import { AppMedia, MediaStyles } from '../AppMedia';
import { ActiveAnnouncementBar } from './Announcements/ActiveAnnouncementBar';
import { MainMenu } from './Menu/MainMenu';
import { OrderBar } from './Orders/OrderBar';
import './TopBar.css';

class TopBarPure extends React.PureComponent {
  static propTypes = {
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    newOrder: PropTypes.object.isRequired,
    user: PropTypes.object,
    isAdmin: PropTypes.bool.isRequired,
    isCustomer: PropTypes.bool.isRequired,
    isActiveCustomer: PropTypes.bool.isRequired,
    clearBasket: PropTypes.func.isRequired,
    viewport: PropTypes.object.isRequired,
    fetchActiveAnnouncement: PropTypes.func.isRequired,
    activeAnnouncement: PropTypes.object.isRequired,
  }

  showAnnouncementBarProps = (props) =>
    props.isAdmin || props.isActiveCustomer;

  showAnnouncementBar = () =>
    this.showAnnouncementBarProps(this.props);

  componentDidMount() {
    if (this.showAnnouncementBar()) {
      this.props.fetchActiveAnnouncement();
    }
  }

  componentDidUpdate(prevProps) {
    if (!this.showAnnouncementBarProps(prevProps) && this.showAnnouncementBar()) {
      this.props.fetchActiveAnnouncement();
    }
  }

  renderMainMenu() {
    return (
      <>
        <style>{MediaStyles}</style>
        <AppMedia.MediaContextProvider>
          <Segment.Group className="opaqueGroup">
            <Segment as={ AppMedia.Media } lessThan="tablet" className="opaqueSegment" basic>
              <MainMenu location={ this.props.location }
                        isCustomer={ this.props.isCustomer }
                        isActiveCustomer={ this.props.isActiveCustomer }
                        isAdmin={ this.props.isAdmin }
                        user={ this.props.user }
                        compact
                        />
            </Segment>
            <Segment as={ AppMedia.Media } greaterThanOrEqual="tablet" className="opaqueSegment" basic>
              <MainMenu location={ this.props.location }
                        isCustomer={ this.props.isCustomer } 
                        isActiveCustomer={ this.props.isActiveCustomer }
                        isAdmin={ this.props.isAdmin }
                        user={ this.props.user }
                        />
            </Segment>
          </Segment.Group>
        </AppMedia.MediaContextProvider>
      </>
    );
  }

  renderOrderBar() {
    return (
      <>
        <style>{MediaStyles}</style>
        <AppMedia.MediaContextProvider>
          <Segment.Group className="opaqueGroup">
            <Segment as={ AppMedia.Media } lessThan="tablet" className="opaqueSegment" basic>
              <OrderBar history={ this.props.history }
                        totalCount={ this.props.newOrder.totalCount }
                        totalNetPriceValue={ this.props.newOrder.totalNetPriceValue }
                        clearBasket={ this.props.clearBasket }
                        compact
                        />
            </Segment>
            <Segment as={ AppMedia.Media } greaterThanOrEqual="tablet" className="opaqueSegment" basic>
              <OrderBar history={ this.props.history }
                        totalCount={ this.props.newOrder.totalCount }
                        totalNetPriceValue={ this.props.newOrder.totalNetPriceValue }
                        clearBasket={ this.props.clearBasket }
                        />
            </Segment>
          </Segment.Group>
        </AppMedia.MediaContextProvider>
      </>
    );
  }

  renderActiveAnnouncementBar() {
    const announcement = this.props.activeAnnouncement.announcement;
    const isAnnouncementBarReady = this.showAnnouncementBar() && !!announcement && !!announcement.text;

    return isAnnouncementBarReady
      ? <ActiveAnnouncementBar text={ announcement.text }/>
      : null;
  }

  getClassName(withOrderBar, withBottomShadow) {
    let className = "";

    if (withOrderBar) {
      className = className + "withOrderBar ";
    }

    if (withBottomShadow) {
      className = className + "withBottomShadow ";
    }

    return className;
  }

  render() {
    const withBottomShadow = this.props.viewport.topPassed;
    const isNewOrderLocation = this.props.location.pathname === '/new-order';
    const withOrderBar = this.props.isCustomer && !isNewOrderLocation;
    const orderBar = withOrderBar ? this.renderOrderBar() : null;
    return (
      <div id="topBar" 
           className={ this.getClassName(withOrderBar, withBottomShadow) }
           >
        { this.renderMainMenu() }
        { this.renderActiveAnnouncementBar() }
        { orderBar }
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: state.login.session.user,
  isAdmin: !!state.login.session.user && state.login.session.user.isAdmin(),
  isCustomer: !!state.login.session.customer,
  isActiveCustomer: !!state.login.session.customer && state.login.session.customer.isActive,
  newOrder: state.orders.newOrder,
  activeAnnouncement: state.announcements.activeAnnouncement,
});

const mapDispatchToProps = dispatch => ({
  clearBasket: () => dispatch(clearBasket()),
  fetchActiveAnnouncement: () => dispatch(fetchActiveAnnouncement()),
});

export const TopBar = connect(mapStateToProps, mapDispatchToProps)(TopBarPure);
