import React from 'react';
import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';
import { Control, Errors, Form } from 'react-redux-form';
import { Link } from 'react-router-dom';
import { Message } from 'semantic-ui-react';
import { Messages } from '../../Messages';
import { register, RegistrationState } from '../../../redux/registration';
import { required, isEmailValid, isPasswordValid, trimString } from '../../../utils/common';

class ReduxRegistrationForm extends React.PureComponent {
  static props = {
    registrationState: PropTypes.string.isRequired,
  }

  registrationError = () => {
    switch (this.props.registrationState) {
      case RegistrationState.ErrorEmailTaken:
        return (
          <Message negative>
            <Message.Header>Adres e-mail już istnieje</Message.Header>
            <Link to="/login">Chcesz się zalogować?</Link>
          </Message>
        );
      case RegistrationState.ErrorWrongPassword:
        return Messages.InvalidPassword;
      case RegistrationState.ErrorUnknown:
        return Messages.UnknownError;
      default:
        return null;
    }
  }

  handleSubmit = (user) => {
    const data = {
      emailAddress: user.emailAddress,
      password: user.password,
      firstName: user.firstName.trim(),
      lastName: user.lastName.trim(),
    };
    this.props.dispatch(register(data));
  }

  render() {
    const inProgress = this.props.registrationState === RegistrationState.InProgress;
    const formClassName = (inProgress ? "loading " : "") + "ui form";
    return (
      <Form
        className={ formClassName }
        model="registration.form.user"
        onSubmit={ this.handleSubmit }
        >
        <div className="field">
          <Control 
            model=".emailAddress" 
            placeholder="E-mail"
            validators={{ required, validEmail: isEmailValid }}
            validateOn="blur"
            parser={ trimString }
          />
          <Errors
            className="errors"
            model=".emailAddress"
            show="touched"
            messages={{
              required: 'Wymagane',
              validEmail: 'Niepoprawny adres email',
            }}
          />
        </div>

        <div className="field">
          <Control model=".password"
            type="password"
            validators={{ required, isPasswordValid }}
            placeholder="Hasło"
            parser={ trimString }
          />
          <Errors
            className="errors"
            model=".password"
            show="touched"
            messages={{
              required: 'Wymagane',
              isPasswordValid: 'Wymagane minimum 8 znaków, w tym minimum 2 cyfry',
            }}
          />
        </div>

        <div className="field">
          <Control.text
            model=".firstName"
            placeholder="Imię"
            validators={{ required }}
          />
          <Errors
            className="errors"
            model=".firstName"
            show="touched"
            messages={{
              required: 'Wymagane',
            }}
          />
        </div>

        <div className="field">
          <Control.text
            model=".lastName"
            placeholder="Nazwisko"
            validators={{ required }}
            />
          <Errors
            className="errors"
            model=".lastName"
            show="touched"
            messages={{
              required: 'Wymagane',
            }}
          />
        </div>

        <button type="submit" className="ui button primary fluid">
          Utwórz konto
        </button>

        { this.registrationError() }
      </Form>
    );
  }
}

const mapStateToProps = state => ({
  registrationState: state.registration.registrationProcess.state,
});

export const RegistrationForm = connect(mapStateToProps)(ReduxRegistrationForm);
