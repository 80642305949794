import { combineForms } from 'react-redux-form';
import { combineReducers } from 'redux';
import Config from '../config';

const initRecoveryInitData = {
  emailAddress: '',
};

const initRecoveryFinalizeData = {
  emailAddress: '',
  password: '',
};

export const PasswordRecoveryInitState = {
  Idle: 'IDLE',
  InProgress: 'IN_PROGRESS',
  Success: 'SUCCESS',
  ErrorUnknown: 'ERROR_UNKNOWN'
};

const initRecoveryInitProcessState = {
  state: PasswordRecoveryInitState.Idle,
};

function recoveryInitProcessReducer(state = initRecoveryInitProcessState, action) {
  switch (action.type) {
    case 'PASSWORD_RECOVERY_INIT_STARTED': {
      const stateUpdate = { state: PasswordRecoveryInitState.InProgress };
      return Object.assign({}, state, stateUpdate);
    }
    case 'PASSWORD_RECOVERY_INIT_FINISHED': {
      const stateUpdate = { state: action.state };
      return Object.assign({}, state, stateUpdate);
    }
    default: {
      return state;
    }
  }
}

function recoveryInitStarted() {
  return {
    type: 'PASSWORD_RECOVERY_INIT_STARTED'
  };
}

function recoveryInitFinished(state) {
  return {
    type: 'PASSWORD_RECOVERY_INIT_FINISHED',
    state: state,
  };
}

export function initPasswordRecovery(emailAddress) {
  return (dispatch, getState, { AuthApi }) => {
    dispatch(recoveryInitStarted());
    const recoveryUrlPrefix = Config.recoveryUrlPrefix;
    return AuthApi.initPasswordRecovery({emailAddress, recoveryUrlPrefix}).then(
      response => dispatch(
        recoveryInitFinished(PasswordRecoveryInitState.Success)
      ),
      error => {
        dispatch(recoveryInitFinished(PasswordRecoveryInitState.ErrorUnknown));
      }
    );
  };
}

export const PasswordRecoveryFinalizeState = {
  Idle: 'IDLE',
  InProgress: 'IN_PROGRESS',
  Success: 'SUCCESS',
  ErrorWrongPassword: 'ERROR_WRONG_PASSWORD',
  ErrorUnknownUuid: 'ERROR_UNKNOWN_UUID',
  ErrorUnknown: 'ERROR_UNKNOWN'
};

const initRecoveryFinalizeProcessState = {
  state: PasswordRecoveryFinalizeState.Idle,
};

function recoveryFinalizeProcessReducer(state = initRecoveryFinalizeProcessState, action) {
  switch (action.type) {
    case 'PASSWORD_RECOVERY_FINALIZE_STARTED': {
      const stateUpdate = { state: PasswordRecoveryFinalizeState.InProgress };
      return Object.assign({}, state, stateUpdate);
    }
    case 'PASSWORD_RECOVERY_FINALIZE_FINISHED': {
      const stateUpdate = { state: action.state };
      return Object.assign({}, state, stateUpdate);
    }
    default: {
      return state;
    }
  }
}

function recoveryFinalizeStarted() {
  return {
    type: 'PASSWORD_RECOVERY_FINALIZE_STARTED'
  };
}

function recoveryFinalizeFinished(state) {
  return {
    type: 'PASSWORD_RECOVERY_FINALIZE_FINISHED',
    state: state,
  };
}

export function finalizePasswordRecovery(data) {
  return (dispatch, getState, { AuthApi }) => {
    dispatch(recoveryFinalizeStarted());
    return AuthApi.finalizePasswordRecovery(data).then(
      response => dispatch(
        recoveryFinalizeFinished(PasswordRecoveryFinalizeState.Success)
      ),
      error => {
        switch (error) {
          case AuthApi.FinalizePasswordRecoveryError.UnknownUuid:
            dispatch(recoveryFinalizeFinished(PasswordRecoveryFinalizeState.ErrorUnknownUuid));
            break;
          case AuthApi.FinalizePasswordRecoveryError.WrongPassword:
            dispatch(recoveryFinalizeFinished(PasswordRecoveryFinalizeState.ErrorWrongPassword));
            break;
          default:
            dispatch(recoveryFinalizeFinished(PasswordRecoveryFinalizeState.ErrorUnknown));
        }
      }
    );
  };
}

export function passwordRecoveryReducer() {
  return combineReducers({
    form: combineForms({
      recoveryInitData: initRecoveryInitData,
      recoveryFinalizeData: initRecoveryFinalizeData,
    }, 'passwordRecovery.form'),
    recoveryInitProcess: recoveryInitProcessReducer,
    recoveryFinalizeProcess: recoveryFinalizeProcessReducer
  });
}
