import React from 'react';
import thunk from 'redux-thunk';
import { applyMiddleware, createStore, combineReducers } from 'redux';
import { Provider } from 'react-redux';
import { BrowserRouter, Route } from 'react-router-dom';
import { loginReducer, initializeLoginRedux } from './redux/login';
import { fileDocumentsReducer } from './redux/fileDocuments';
import { registrationReducer } from './redux/registration';
import { storeReducer } from './redux/store';
import { ordersReducer } from './redux/orders';
import { passwordRecoveryReducer } from './redux/passwordRecovery';
import { customersReducer } from './redux/customers';
import { cookieReducer, initializeCookieRedux } from './redux/cookie';
import { Dashboard } from './components/Dashboard/Dashboard';
import AuthApi from './api/AuthApi';
import ContentApi from './api/ContentApi';
import PublicApi from './api/PublicApi';
import StoreApi from './api/StoreApi';
import WholesaleApi from './api/WholesaleApi';
import { announcementsReducer } from './redux/announcements';
import { publicReducer } from './redux/public';

const reducer = combineReducers({
  login: loginReducer(),
  registration: registrationReducer(),
  passwordRecovery: passwordRecoveryReducer(),
  store: storeReducer(),
  orders: ordersReducer(),
  customers: customersReducer(),
  cookie: cookieReducer,
  announcements: announcementsReducer(),
  fileDocuments: fileDocumentsReducer(),
  public: publicReducer()
});

const store = createStore(
  reducer,
  applyMiddleware(
    thunk.withExtraArgument({ AuthApi, ContentApi, PublicApi, StoreApi, WholesaleApi }), 
  )
);

initializeLoginRedux(store);
initializeCookieRedux(store);

export const App = (props) => (
  <Provider store={ store }>
    <BrowserRouter>
      <Route path='/' component={ Dashboard }/>
    </BrowserRouter>
  </Provider>
);
