import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Container, Header, Icon, Message } from 'semantic-ui-react';
import { Dimmer, Loader } from 'semantic-ui-react';
import { Redirect } from 'react-router-dom';
import { NewOrderForm} from './NewOrderForm';
import { order, clearBasket, updateProductInBasket } from '../../../redux/orders';
import { OrderState } from '../../../redux/orders';
import { updateNewOrderCustomerInfo } from '../../../redux/orders';
import { ScrollToTopOnMount } from '../../ScrollToTopOnMount';

class NewOrderPure extends React.PureComponent {
  static propTypes = {
    newOrder: PropTypes.object.isRequired,
    updateProductInBasket: PropTypes.func.isRequired,
    updateNewOrderCustomerInfo: PropTypes.func.isRequired,
    orderProcess: PropTypes.object.isRequired,
    order: PropTypes.func.isRequired,
    clearBasket: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
  }

  showOffer = () => {
    this.props.history.push("/products");
  }

  renderOrderSuccess(createdOrder) {
    const redirectUrl = '/orders/' + createdOrder.id;
    return <Redirect to={ redirectUrl } />;
  }

  renderOrderError() {
    return (
      <Message negative>
        <Message.Header>Ups!</Message.Header>
        Wystąpił błąd podczas składania zamówienia. Spróbuj ponownie. Jeśli problem się powtarza, prosimy o kontakt.
      </Message>
    );
  }

  renderNoPermissions() {
    return (
      <Message negative>
        <Message.Header>Brak uprawnień</Message.Header>
        Nie posiadasz wystarczających uprawnień do złożenia zamówienia.
      </Message>
    );
  }

  renderNewOrderDetails() {
    const { newOrder } = this.props;
    return (
      <div>
        <NewOrderForm newOrder={ newOrder }
                      updateProductInBasket={ this.props.updateProductInBasket }
                      updateNewOrderCustomerInfo={ this.props.updateNewOrderCustomerInfo }
                      order={ this.props.order }
                      clearBasket={ this.props.clearBasket } 
                      showOffer={ this.showOffer }
                      />
      </div>
    );
  }

  render() {
    const orderProcessState = this.props.orderProcess.state;
    const createdOrder = this.props.orderProcess.order;
    return (
      <Container>
        <ScrollToTopOnMount/>
        <Header as='h1' icon textAlign='center'>
          <Icon name='shipping' circular/>
          <Header.Content>Potwierdź zamówienie</Header.Content>
        </Header>
        <Dimmer active={ orderProcessState === OrderState.InProgress } page={true}>
          <Loader>Wysyłanie zamówienia</Loader>
        </Dimmer>
        { orderProcessState === OrderState.Success ? this.renderOrderSuccess(createdOrder) : null }
        { orderProcessState === OrderState.ErrorNoPermissions ? this.renderNoPermissions() : null }
        { orderProcessState === OrderState.ErrorUnknown ? this.renderOrderError() : null }
        { orderProcessState !== OrderState.Success ? this.renderNewOrderDetails() : null }
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  newOrder: state.orders.newOrder,
  orderProcess: state.orders.orderProcess,
});

const mapDispatchToProps = dispatch => ({
  updateProductInBasket: (data) => dispatch(updateProductInBasket(data)),
  updateNewOrderCustomerInfo: (text) => dispatch(updateNewOrderCustomerInfo(text)),
  order: (orderedProducts, customerInfo) => dispatch(order(orderedProducts, customerInfo)),
  clearBasket: () => dispatch(clearBasket()),
});

export const NewOrder = connect(mapStateToProps, mapDispatchToProps)(NewOrderPure);
