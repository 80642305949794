import React from 'react';
import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';
import { Control, Errors, Form } from 'react-redux-form';
import { Modal } from 'semantic-ui-react';
import { SendEmailToCustomersState, sendEmailToCustomers } from '../../../../redux/customers.js';
import { Messages } from '../../../Messages';
import { required } from '../../../../utils/common';

export class ReduxEmailModal extends React.PureComponent {
  static props = {
    sendEmailToCustomersState: PropTypes.object.isRequired,
    trigger: PropTypes.object.isRequired,
  }

  state = { isOpen: false }

  open = () => {
    this.setState({ isOpen: true });
  }

  close = () => {
    this.setState({ isOpen: false });
  }

  renderStatus = () => {
    switch (this.props.sendEmailToCustomersState) {
      case SendEmailToCustomersState.ErrorNoPermissions:
        return Messages.NoPermissions;
      case SendEmailToCustomersState.ErrorUnknown:
        return Messages.UnknownError;
      default:
        return null;
    }
  }

  handleSubmit = (data) => {
    const email = {
      subject: data.subject.trim(),
      body: data.body.trim(),
    };
    this.props.dispatch(sendEmailToCustomers(email))
      .then(_ => this.close())
      .catch(_ => console.log("Can't send e-mail"));
  }

  render() {
    const formClassName =
      (this.props.sendCustomersEmailState === SendEmailToCustomersState.Sending
        ? "loading " 
        : "")
      + "ui form";

    return (
      <Modal open={ this.state.isOpen }
             trigger={ this.props.trigger }
             onOpen={ this.open }
             onClose={ this.close }
             >
        <Modal.Header>Email do klientów</Modal.Header>
        <Modal.Content>
          <Form
            model="customers.form.customersEmail"
            onSubmit={ this.handleSubmit }
            className={ formClassName }
            >
            <div className="field">
              <Control
                model=".subject" 
                placeholder="Tytuł wiadomosci"
                validators={{ required }}
                validateOn="blur"
              />
              <Errors
                className="error"
                model=".subject"
                show="touched"
                messages={{
                  required: 'Wymagane',
                }}
              />
            </div>

            <div className="field">
              <Control.textarea
                model=".body" 
                placeholder="Treść wiadomości"
                validators={{ required }}
              />
              <Errors
                className="error"
                model=".body"
                show="touched"
                messages={{
                  required: 'Wymagane',
                }}
              />
            </div>

            { this.renderStatus() }

            <button className="ui button primary fluid" type="submit">
              Wyślij
            </button>
          </Form>
        </Modal.Content>
      </Modal>
    );
  }
}

const mapStateToProps = state => ({
  sendEmailToCustomersState: state.customers.sendEmailToCustomersProcess.state,
});

export const EmailModal = connect(mapStateToProps)(ReduxEmailModal);
