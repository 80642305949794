import React from 'react';
import { Grid, Header, Icon, Segment } from 'semantic-ui-react';
import './Footer.css';

export class Footer extends React.PureComponent {
  render() {
    return (
      <Segment inverted className="footer">
        <Grid stackable>
          <Grid.Row>
            <Grid.Column width={ 2 }></Grid.Column>
            <Grid.Column width={ 6 }>
              <Segment basic inverted>
                <Header as='h3'>Kim jesteśmy?</Header>
                <p>Firma Handlowa Furnitex s.c. została założona z myślą o importowaniu mebli z Azji. Każdego roku bierzemy udział w międzynarodowych targach mebli w Szanghaju, Guangzhou, Kuala Lumpur i Ho Chi Minh City.</p>
                <p>Zapewniamy szybkie i regularne dostawy własnym transportem bez minimum logistycznego.</p>
                <p><b>Do współpracy zapraszamy sklepy i hurtownie meblowe.</b></p>
              </Segment>
            </Grid.Column>
            <Grid.Column width={ 2 }></Grid.Column>
            <Grid.Column width={ 4 }>
              <Segment basic inverted>
                <Header as='h3'>
                    Dane kontaktowe
                </Header>
                <p>Firma Handlowa Furnitex s.c.
                  <br/>Radosław Roszak & Adrian Roszak
                  <br/>ul. Przemysłowa 85
                  <br/>62-510 Konin
                </p>
                <Icon name="phone"/>
                +48 605 545 922
                <br/>
                <Icon name="fax"/>
                fax +48 63 245 10 78
              </Segment>
            </Grid.Column>
            <Grid.Column width={ 2 }/>
          </Grid.Row>
        </Grid>
        <Segment basic inverted textAlign="center">
          Copyright © 2021 Wszystkie prawa zastrzeżone.
        </Segment>
      </Segment>
    );
  }
}