import { combineForms } from 'react-redux-form';
import { combineReducers } from 'redux';
import { unauthorized } from './login';

const initNewFileDocument = {
  name: ''
};

export const FileDocumentListState = {
  Idle: 'IDLE',
  Fetching: 'FETCHING',
  ErrorNoPermissions: 'ERROR_NO_PERMISSIONS',
  ErrorUnknown: 'ERROR_UNKNOWN',
};

const initFileDocumentListState = {
  fileDocuments: [],
  state: FileDocumentListState.Idle,
};

function compareDocuments(left, right) {
  return left.name.localeCompare(right.name);
}

function fileDocumentListReducer(state = initFileDocumentListState, action) {
  switch (action.type) {
    case 'FETCHING_FILE_DOCUMENTS_STARTED': {
      const stateUpdate = { state: FileDocumentListState.Fetching };
      return Object.assign({}, state, stateUpdate);
    }
    case 'FETCHING_FILE_DOCUMENTS_FINISHED': {
      const fileDocuments = !!action.fileDocuments ? [...action.fileDocuments].sort(compareDocuments) : null;
      const stateUpdate = { state: action.state, fileDocuments };
      return Object.assign({}, state, stateUpdate);
    }
    case 'DELETE_FILE_DOCUMENT_FINISHED': {
      if (action.state !== DeletedFileDocumentState.Idle || state.state !== FileDocumentListState.Idle)
        return state;

      const fileDocuments = [...state.fileDocuments];
      const stateUpdate = { 
        fileDocuments: fileDocuments.filter(fd => fd.uuid !== action.uuid)
      };
      return Object.assign({}, state, stateUpdate);
    }
    case 'UPLOAD_FILE_DOCUMENT_FINISHED': {
      if (action.state !== UploadedFileDocumentState.Idle || state.state !== FileDocumentListState.Idle)
        return state;

      const fileDocuments = [...state.fileDocuments];
      fileDocuments.push(action.fileDocument);
      const stateUpdate = { fileDocuments: fileDocuments.sort(compareDocuments) };
      return Object.assign({}, state, stateUpdate);
    }
    default: {
      return state;
    }
  }
}

export function fetchFileDocuments() {
  return (dispatch, getState, { ContentApi }) => {
    const fileDocumentList = getState().fileDocuments.fileDocumentList;
    if (fileDocumentList.state === FileDocumentListState.Fetching)
      return;

    dispatch(fetchingFileDocumentsStarted());
    const session = getState().login.session;
    return ContentApi.fileDocuments(session.token).then(
      data =>
        dispatch(fetchingFileDocumentsFinished(FileDocumentListState.Idle, data)),
      error => {
        switch (error) {
          case ContentApi.FileDocumentsError.Forbidden:
            dispatch(fetchingFileDocumentsFinished(FileDocumentListState.ErrorNoPermissions));
            break;
          case ContentApi.ActiveAnnouncementError.Unauthorized:
            dispatch(fetchingFileDocumentsFinished(FileDocumentListState.ErrorNoPermissions));
            dispatch(unauthorized());
            break;
          default:
            dispatch(fetchingFileDocumentsFinished(FileDocumentListState.ErrorUnknown));
        }
      }
    );
  };
}

function fetchingFileDocumentsStarted() {
  return {
    type: 'FETCHING_FILE_DOCUMENTS_STARTED',
  };
}

function fetchingFileDocumentsFinished(state, fileDocuments) {
  return {
    type: 'FETCHING_FILE_DOCUMENTS_FINISHED',
    state: state,
    fileDocuments: fileDocuments,
  };
}

export const UploadedFileDocumentState = {
  Idle: 'IDLE',
  Uploading: 'UPLOADING',
  ErrorNoPermissions: 'ERROR_NO_PERMISSIONS',
  ErrorUnknown: 'ERROR_UNKNOWN',
};

const initUploadedFileDocumentState = {
  state: UploadedFileDocumentState.Idle,
  fileDocument: null,
};

function uploadedFileDocumentReducer(state = initUploadedFileDocumentState, action) {
  switch (action.type) {
    case 'UPLOAD_FILE_DOCUMENT_STARTED': {
      const stateUpdate = { state: UploadedFileDocumentState.Uploading };
      return Object.assign({}, state, stateUpdate);
    }
    case 'UPLOAD_FILE_DOCUMENT_FINISHED': {
      const stateUpdate = { state: action.state, fileDocument: action.fileDocument };
      return Object.assign({}, state, stateUpdate);
    }
    default: {
      return state;
    }
  }
}

export function uploadFileDocument(documentName, file) {
  return (dispatch, getState, { ContentApi }) => {
    if (getState().fileDocuments.uploadedFileDocument.state !== UploadedFileDocumentState.Idle)
      return;

    dispatch(uploadFileDocumentStarted());
    const session = getState().login.session;
    return ContentApi.uploadFileDocument(documentName ,file, session.token).then(
      fileDocument =>
        dispatch(uploadFileDocumentFinished(UploadedFileDocumentState.Idle, fileDocument)),
      error => {
        switch (error) {
          case ContentApi.UploadFileDocumentError.Forbidden:
            dispatch(uploadFileDocumentFinished(UploadedFileDocumentState.ErrorNoPermissions));
            break;
          case ContentApi.UploadFileDocumentError.Unauthorized:
            dispatch(uploadFileDocumentFinished(UploadedFileDocumentState.ErrorNoPermissions));
            dispatch(unauthorized());
            break;
          default:
            dispatch(uploadFileDocumentFinished(UploadedFileDocumentState.ErrorUnknown));
        }
      }
    );
  };
}

function uploadFileDocumentStarted() {
  return {
    type: 'UPLOAD_FILE_DOCUMENT_STARTED',
  };
}

function uploadFileDocumentFinished(state, fileDocument) {
  return {
    type: 'UPLOAD_FILE_DOCUMENT_FINISHED',
    state: state,
    fileDocument: fileDocument,
  };
}

export const DeletedFileDocumentState = {
  Idle: 'IDLE',
  Deleting: 'DELETING',
  ErrorNoPermissions: 'ERROR_NO_PERMISSIONS',
  ErrorUnknown: 'ERROR_UNKNOWN',
};

const initDeletedFileDocumentState = {
  state: DeletedFileDocumentState.Idle,
  uuid: null,
};

function deletedFileDocumentReducer(state = initDeletedFileDocumentState, action) {
  switch (action.type) {
    case 'DELETE_FILE_DOCUMENT_STARTED': {
      const stateUpdate = { state: DeletedFileDocumentState.Deleting };
      return Object.assign({}, state, stateUpdate);
    }
    case 'DELETE_FILE_DOCUMENT_FINISHED': {
      const stateUpdate = { state: action.state };
      return Object.assign({}, state, stateUpdate);
    }
    default: {
      return state;
    }
  }
}

export function deleteFileDocument(uuid) {
  return (dispatch, getState, { ContentApi }) => {
    if (getState().fileDocuments.deletedFileDocument.state !== DeletedFileDocumentState.Idle)
      return;

    dispatch(deleteFileDocumentStarted(uuid));
    const session = getState().login.session;
    return ContentApi.deleteFileDocument(uuid, session.token).then(
      result =>
        dispatch(deleteFileDocumentFinished(uuid, DeletedFileDocumentState.Idle)),
      error => {
        switch (error) {
          case ContentApi.DeleteFileDocumentError.Forbidden:
            dispatch(deleteFileDocumentFinished(uuid, DeletedFileDocumentState.ErrorNoPermissions));
            break;
          case ContentApi.DeleteFileDocumentError.Unauthorized:
            dispatch(deleteFileDocumentFinished(uuid, DeletedFileDocumentState.ErrorNoPermissions));
            dispatch(unauthorized());
            break;
          default:
            dispatch(deleteFileDocumentFinished(uuid, DeletedFileDocumentState.ErrorUnknown));
        }
      }
    );
  };
}

function deleteFileDocumentStarted(uuid) {
  return {
    type: 'DELETE_FILE_DOCUMENT_STARTED',
    uuid: uuid,
  };
}

function deleteFileDocumentFinished(uuid, state) {
  return {
    type: 'DELETE_FILE_DOCUMENT_FINISHED',
    uuid: uuid,
    state: state,
  };
}

export const FileDocumentDownloadUrlState = {
  Idle: 'IDLE',
  Fetching: 'FETCHING',
  ErrorNoPermissions: 'ERROR_NO_PERMISSIONS',
  ErrorUnknown: 'ERROR_UNKNOWN',
};

const initFileDocumentDownloadUrl = {
  state: FileDocumentDownloadUrlState.Idle,
  uuid: null,
  url: null,
};

function fileDocumentDownloadUrlReducer(state = initFileDocumentDownloadUrl, action) {
  switch (action.type) {
    case 'FETCHING_FILE_DOCUMENT_DOWNLOAD_URL_STARTED': {
      const stateUpdate = { state: FileDocumentDownloadUrlState.Fetching };
      return Object.assign({}, state, stateUpdate);
    }
    case 'FETCHING_FILE_DOCUMENT_DOWNLOAD_URL_FINISHED': {
      const stateUpdate = { state: action.state, url: action.url };
      return Object.assign({}, state, stateUpdate);
    }
    default: {
      return state;
    }
  }
}

export function fetchFileDocumentDownloadUrl(uuid) {
  return (dispatch, getState, { ContentApi }) => {
    if (getState().fileDocuments.fileDocumentDownloadUrl.state !== FileDocumentDownloadUrlState.Idle)
      return;

    dispatch(fetchingFileDocumentDownloadUrlStarted(uuid));
    const session = getState().login.session;
    return ContentApi.fetchFileDocumentDownloadUrl(uuid, session.token).then(
      result =>
        dispatch(fetchingFileDocumentDownloadUrlFinished(uuid, FileDocumentDownloadUrlState.Idle, result.url)),
      error => {
        switch (error) {
          case ContentApi.FetchFileDocumentDownloadUrlError.Forbidden:
            dispatch(fetchingFileDocumentDownloadUrlFinished(uuid, FileDocumentDownloadUrlState.ErrorNoPermissions));
            break;
          case ContentApi.FetchFileDocumentDownloadUrlError.Unauthorized:
            dispatch(fetchingFileDocumentDownloadUrlFinished(uuid, FileDocumentDownloadUrlState.ErrorNoPermissions));
            dispatch(unauthorized());
            break;
          default:
            dispatch(fetchingFileDocumentDownloadUrlFinished(uuid, FileDocumentDownloadUrlState.ErrorUnknown));
        }
      }
    );
  };
}

function fetchingFileDocumentDownloadUrlStarted(uuid) {
  return {
    type: 'FETCHING_FILE_DOCUMENT_DOWNLOAD_URL_STARTED',
    uuid: uuid,
  };
}

function fetchingFileDocumentDownloadUrlFinished(uuid, state, url = null) {
  return {
    type: 'FETCHING_FILE_DOCUMENT_DOWNLOAD_URL_FINISHED',
    uuid: uuid,
    state: state,
    url: url,
  };
}


export function fileDocumentsReducer() {
  return combineReducers({
    form: combineForms({
      newFileDocument: initNewFileDocument 
    }, 'fileDocuments.form'),
    fileDocumentList: fileDocumentListReducer,
    uploadedFileDocument: uploadedFileDocumentReducer,
    deletedFileDocument: deletedFileDocumentReducer,
    fileDocumentDownloadUrl: fileDocumentDownloadUrlReducer,
  });
}
