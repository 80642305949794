import React from 'react';
import ReactToPrint from 'react-to-print';
import { Button, Container, Icon } from 'semantic-ui-react';
import './PrintTrigger.css';

export const PrintTrigger =
  ({ content }) => {
    const trigger = <Button className="printButton"><Icon name="print"/>Drukuj</Button>;
    return (
      <Container textAlign="center" className="printTriggerContainer">
        <ReactToPrint trigger={ () => trigger } content={ content }/>
      </Container>
    );
  };
