import React from 'react';
import PropTypes from 'prop-types';
import { Icon, Step } from 'semantic-ui-react';

export class OrderState extends React.PureComponent {
  static propTypes = {
    state: PropTypes.string.isRequired,
  }

  render() {
    const state = this.props.state;
    return (
      <Step.Group>
        <Step active={ state === "new" }>
          <Icon name='shop'/>
          <Step.Content>Nowe</Step.Content>
        </Step>
        <Step active={ state === "inprogress" || state === "partiallyclosed" }>
          <Icon name='truck' />
          <Step.Content>W realizacji</Step.Content>
        </Step>
        <Step active={ state === "closed" || state === "partiallyclosed" }>
          <Icon name='checkmark' />
          <Step.Content>Zrealizowane</Step.Content>
        </Step>
        <Step active={ state === "cancelled"} >
          <Icon name='delete' />
          <Step.Content>Anulowane</Step.Content>
        </Step>
      </Step.Group>
    );
  }
}
