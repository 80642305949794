import React from 'react';
import { PropTypes } from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Control, Errors, Form } from 'react-redux-form';
import { actions } from 'react-redux-form';
import { Message } from 'semantic-ui-react';
import { Messages } from '../../Messages';
import { updateCustomer, UpdateCustomerState } from '../../../redux/customers';
import { required, trimString } from '../../../utils/common';

class ReduxUpdateCustomerForm extends React.PureComponent {
  static props = {
    updateCustomerProcess: PropTypes.object.isRequired,
    customer: PropTypes.object.isRequired,
  }

  componentDidMount() {
    const data = {
      phoneNumber: this.props.customer.phoneNumber,
      firstName: this.props.customer.firstName,
      lastName: this.props.customer.lastName,
      companyName: this.props.customer.company.name,
      companyNip: this.props.customer.company.nip,
      additionalInfo: this.props.customer.additionalInfo
    };
    this.props.dispatch(actions.change('customers.form.updateCustomer', data));
  }

  updateCustomerError = () => {
    const process = this.props.updateCustomerProcess;
    if (process.customer && process.customer.id !== this.props.customer.id) {
      return null;
    }

    switch (process.state) {
      case UpdateCustomerState.ErrorUnknown:
        return Messages.UnknownError;
      case UpdateCustomerState.ErrorNotExists:
        return (
          <Message negative>
            <Message.Header>Twoje konto klienta nie istnieje</Message.Header>
            <Link to="/registration/customer">Załóż je tutaj.</Link>
          </Message>
        );
      case UpdateCustomerState.ErrorNoPermissions:
        return (
          <Message negative>
            <Message.Header>Brak uprawnień</Message.Header>
            Nie masz wystarczających uprawnień do aktualizacji danych.
          </Message>
        );
      default:
        return null;
    }
  }

  handleSubmit = (data) => {
    const customerData = {
      firstName: data.firstName.trim(),
      lastName: data.lastName.trim(),
      phoneNumber: data.phoneNumber,
      company: {
        name: data.companyName.trim(),
        nip: data.companyNip,
      },
      additionalInfo: trimString(data.additionalInfo),
    };
    this.props.dispatch(updateCustomer(this.props.customer.id, customerData));
  }

  render() {
    const inProgress = this.props.updateCustomerProcess.state === UpdateCustomerState.InProgress;
    const formClassName = (inProgress ? "loading " : "") + "ui form";
    return (
      <Form
        className={ formClassName }
        model="customers.form.updateCustomer"
        onSubmit={ this.handleSubmit }
      >

        <div className="field">
          <label>Numer telefonu</label>
          <Control 
            model=".phoneNumber"
            validators={{ required }}
            parser={ trimString }
          />
          <Errors
            className="errors"
            model=".phoneNumber"
            show="touched"
            messages={{
              required: 'Wymagane',
            }}
          />
        </div>

        <div className="field">
          <label>Nazwa firmy</label>
          <Control 
            model=".companyName"
            validators={{ required }}
          />
          <Errors
            className="errors"
            model=".companyName"
            show="touched"
            messages={{
              required: 'Wymagane',
            }}
          />
        </div>

        <div className="field">
          <label>NIP firmy</label>
          <Control 
            model=".companyNip"
            validators={{ required }}
            parser={ trimString }
          />
          <Errors
            className="errors"
            model=".companyNip"
            show="touched"
            messages={{
              required: 'Wymagane',
            }}
          />
        </div>

        <div className="field">
          <label>Imię</label>
          <Control 
            model=".firstName"
            validators={{ required }}
          />
          <Errors
            className="errors"
            model=".firstName"
            show="touched"
            messages={{
              required: 'Wymagane',
            }}
          />
        </div>

        <div className="field">
          <label>Nazwisko</label>
          <Control 
            model=".lastName"
            validators={{ required }}
          />
          <Errors
            className="errors"
            model=".lastName"
            show="touched"
            messages={{
              required: 'Wymagane',
            }}
          />
        </div>

        <div className="field">
          <label>Dodatkowe informacje</label>
          <Control.textarea
            model=".additionalInfo"
          />
        </div>

        <button type="submit" className="ui button primary fluid">
          Zapisz
        </button>

        { this.updateCustomerError() }
      </Form>
    );
  }
}

const mapStateToProps = state => ({
  updateCustomerProcess: state.customers.updateProcess,
  customer: state.login.session.customer,
});

export const UpdateCustomerForm = connect(mapStateToProps)(ReduxUpdateCustomerForm);
