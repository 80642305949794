import React from 'react';
import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Control, Errors, Form } from 'react-redux-form';
import { Message } from 'semantic-ui-react';
import { Redirect } from 'react-router-dom';
import { Messages } from '../../Messages';
import { CustomerRegistrationState } from '../../../redux/customers';
import { register } from '../../../redux/customers';
import { required, trimString } from '../../../utils/common';

class ReduxCustomerRegistrationForm extends React.PureComponent {
  static props = {
    user: PropTypes.shape({
      firstName: PropTypes.string.isRequired,
      lastName: PropTypes.string.isRequired,
      emailAddress: PropTypes.string.isRequired
    }).isRequired,
    registrationState: PropTypes.string.isRequired,
  }

  registrationError() {
    switch (this.props.registrationState) {
      case CustomerRegistrationState.ErrorAlreadyRegistered:
        return <Redirect to="/logout"/>;
      case CustomerRegistrationState.ErrorUnauthorized:
        return (
          <Message warning>
            <Message.Header>Sesja wygasła</Message.Header>
            <Link to="/login">Zaloguj się ponownie.</Link>
          </Message>
        );
      case CustomerRegistrationState.ErrorUnknown:
        return Messages.UnknownError;
      default:
        return null;
    }
  }

  handleSubmit = data => {
    const customer = {
      emailAddress: this.props.user.emailAddress,
      firstName: data.firstName.trim(),
      lastName: data.lastName.trim(),
      phoneNumber: data.phoneNumber,
      company: {
        name: data.companyName.trim(),
        nip: data.companyNip,
      },
      additionalInfo: trimString(data.additionalInfo),
    };
    this.props.dispatch(register(customer));
  }

  render() {
    const inProgress = this.props.registrationState === CustomerRegistrationState.InProgress;
    const formClassName = (inProgress ? "loading " : "") + "ui form";
    return (
      <Form
        model="customers.form.registerCustomer"
        onSubmit={ this.handleSubmit }
        className={ formClassName }
      >
        <div className="field">
          <Control 
            model=".phoneNumber"
            validators={{ required }}
            placeholder="Numer telefonu"
            parser={ trimString }
          />
          <Errors
            className="errors"
            model=".phoneNumber"
            show="touched"
            messages={{
              required: 'Wymagane',
            }}
          />
        </div>

        <div className="field">
          <Control 
            model=".companyName"
            validators={{ required }}
            placeholder="Nazwa firmy"
          />
          <Errors
            className="errors"
            model=".companyName"
            show="touched"
            messages={{
              required: 'Wymagane',
            }}
          />
        </div>

        <div className="field">
          <Control 
            model=".companyNip"
            validators={{ required }}
            placeholder="NIP firmy"
            parser={ trimString }
          />
          <Errors
            className="errors"
            model=".companyNip"
            show="touched"
            messages={{
              required: 'Wymagane',
            }}
          />
        </div>

        <div className="field">
          <Control 
            model=".firstName"
            validators={{ required }}
            placeholder="Imię"
          />
          <Errors
            className="errors"
            model=".firstName"
            show="touched"
            messages={{
              required: 'Wymagane',
            }}
          />
        </div>

        <div className="field">
          <Control 
            model=".lastName"
            validators={{ required }}
            placeholder="Nazwisko"
          />
          <Errors
            className="errors"
            model=".lastName"
            show="touched"
            messages={{
              required: 'Wymagane',
            }}
          />
        </div>

        <div className="field">
          <Control.textarea
            model=".additionalInfo"
            placeholder="Dodatkowe informacje"
          />
        </div>

        <button type="submit" className="ui button primary fluid">
          Zapisz
        </button>

        { this.registrationError() }
      </Form>
    );
  }
}

const mapStateToProps = state => ({
  registrationState: state.customers.registrationProcess.state,
  user: state.login.session.user,
});

export const CustomerRegistrationForm =
  connect(mapStateToProps)(ReduxCustomerRegistrationForm);
