import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { AvailabilityLabel } from '../../Products/ProductLabels';
import { Button, ItemGroup, Item, Label } from 'semantic-ui-react';
import { orderedProductNetPriceValue, roundPriceValue } from '../../../../utils/price';
import { tryResizeImage } from '../../../../utils/common';
import Config from '../../../../config';
import './Orders.css';
import '../../Orders/Orders.css';

export class OrderedProductsList extends React.PureComponent {
  static propTypes = {
    orderState: PropTypes.string.isRequired,
    products: PropTypes.arrayOf(PropTypes.object).isRequired,
    editProduct: PropTypes.func.isRequired,
    onCantEditInCurrentState: PropTypes.func,
  }

  render() {
    const items = this.props.products.map(product =>
      <OrderedProductItem 
        key={ product.productId } 
        orderState={ this.props.orderState }
        product={ product } 
        editProduct={ this.props.editProduct }
        onCantEditInCurrentState={ this.props.onCantEditInCurrentState }
      />
    );

    return (
      <ItemGroup divided className="newOrderForm">
        { items }
      </ItemGroup>
    );
  }
}

class OrderedProductItem extends React.PureComponent {
  static propTypes = {
    orderState: PropTypes.string.isRequired,
    product: PropTypes.object.isRequired,
    editProduct: PropTypes.func.isRequired,
    onCantEditInCurrentState: PropTypes.func,
  }

  editProduct = (product) => () => {
    this.props.editProduct(product);
  }

  renderEditButtons(product, netPriceValue) {
    const countButton = 
      <Button animated='vertical' 
              size='tiny' 
              color='blue' 
              onClick={ this.editProduct(product) }
              >
        <Button.Content hidden>Edytuj</Button.Content>
        <Button.Content visible>{ product.count } szt.</Button.Content>
      </Button>;

    const priceButton =
      <Button animated='vertical' 
              size='tiny'
              onClick={ this.editProduct(product) }>
        <Button.Content hidden>Edytuj</Button.Content>
        <Button.Content visible>{ netPriceValue } PLN</Button.Content>
      </Button>;

    return (
      <Item.Extra>
        { countButton }
        { priceButton }
      </Item.Extra>
    );
  }

  renderLabels(product, netPriceValue) {
    return (
      <Item.Extra>
        <Button color='blue' size='tiny' onClick={ this.props.onCantEditInCurrentState }>
          { product.count } szt.
        </Button>
        <Button size='tiny' onClick={ this.props.onCantEditInCurrentState }>
          { netPriceValue } PLN
        </Button>
      </Item.Extra>
    );
  }

  render() {
    const { product, orderState } = this.props;
    const deliveryInfo = product.postDeliveryNetPriceValue !== undefined
      ? <Label className="orderedProductItemLabel">dostawa 24h</Label> : null;

    const netPriceValue = roundPriceValue(orderedProductNetPriceValue(product));

    const link = "/products/" + product.productId;

    const availabilityLabel =
      <Item.Extra className="orderedProductItemMoreExtra">
        <AvailabilityLabel availableSince={ product.availableSince }/>
      </Item.Extra>;

    const editOrLabels = orderState === 'inprogress' || orderState === 'partiallyclosed'
      ? this.renderEditButtons(product, netPriceValue)
      : this.renderLabels(product, netPriceValue);
    
    return (
      <Item>
        <Item.Image className="orderedProductItemImage" 
                    size="small" 
                    src={ tryResizeImage(product.images[0], Config.imgWidth.thumbnail) }
                    />
        <Item.Content verticalAlign="middle">
          <Item.Header>
            <Link className="orderedProductItemLink" to={ link }>{ product.index }</Link>
          </Item.Header>
          <Item.Meta>{ product.name }</Item.Meta>
          { editOrLabels }
          { availabilityLabel }
          { deliveryInfo }
        </Item.Content>
      </Item>
    );
  }
}
