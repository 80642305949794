import React from 'react';
import PropTypes from 'prop-types';
import { Icon, Step } from 'semantic-ui-react';

export class RegistrationStep extends React.PureComponent {
  static props = {
    step: PropTypes.string.isRequired,
  }

  render() {
    return (
      <Step.Group>
        <Step active={ this.props.step === "create-account" }>
          <Icon name='add user'/>
          <Step.Content>
            <Step.Title>Rejestracja</Step.Title>
            <Step.Description>Utwórz konto.</Step.Description>
          </Step.Content>
        </Step>
        <Step active={ this.props.step === "confirm-email" }>
          <Icon name='envelope' />
          <Step.Content>
            <Step.Title>Weryfikacja</Step.Title>
            <Step.Description>Potwierdź e-mail.</Step.Description>
          </Step.Content>
        </Step>
        <Step active={ this.props.step === "create-customer" }>
          <Icon name='vcard' />
          <Step.Content>
            <Step.Title>Konto klienta</Step.Title>
            <Step.Description>Podaj dane.</Step.Description>
          </Step.Content>
        </Step>
        <Step active={ this.props.step === "wait"} >
          <Icon name='hourglass outline' />
          <Step.Content>
            <Step.Title>Aktywacja</Step.Title>
            <Step.Description>Poczekaj chwilę...</Step.Description>
          </Step.Content>
        </Step>
      </Step.Group>
    );
  }
}
