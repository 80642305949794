import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { Product } from './Product';
import { Products } from './Products';
import { PublicRoute } from '../PublicRoute';

export class ProductsRouter extends React.PureComponent {
  defaultRedirect = () => <Redirect to='/'/>;
  render() {
    return (
      <Switch>
        <PublicRoute exact path='/products' component={ Products }/>
        <PublicRoute exact path='/products/:id' component={ Product }/>
        <Route path='/' render={ this.defaultRedirect }/>
      </Switch>
    );
  }
}
