import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { AdminRoute } from '../AdminRoute';
import { ActiveAnnouncement } from './Announcements/ActiveAnnouncement';
import { Categories } from './Categories/Categories';
import { Customer } from './Customers/Customer';
import { Customers } from './Customers/Customers';
import { CustomerEdit } from './Customers/CustomerEdit';
import { FileDocuments } from '../FileDocuments/FileDocuments';
import { Order } from './Orders/Order';
import { Orders } from './Orders/Orders';
import { Products } from './Products/Products';
import { ProductAdd } from './Products/ProductAdd';
import { ProductEdit } from './Products/ProductEdit';

export class AdminRouter extends React.Component {
  defaultRedirect = () => <Redirect to='/admin/orders'/>;
  render() {
    return (
      <Switch>
        <AdminRoute path='/admin/announcement' component= { ActiveAnnouncement }/>
        <AdminRoute path='/admin/categories' component={ Categories }/>
        <AdminRoute path='/admin/customers/:id/edit' component={ CustomerEdit }/>
        <AdminRoute path='/admin/customers/:id' component={ Customer }/>
        <AdminRoute path='/admin/customers' component={ Customers }/>
        <AdminRoute path='/admin/file-documents' component={ FileDocuments }/>
        <AdminRoute path='/admin/orders/:id' component={ Order }/>
        <AdminRoute path='/admin/orders' component={ Orders }/>
        <AdminRoute path='/admin/products/new' component={ ProductAdd }/>
        <AdminRoute path='/admin/products/:id' component={ ProductEdit }/>
        <AdminRoute path='/admin/products' component={ Products }/>
        <Route path='/' render={ this.defaultRedirect }/>
      </Switch>
    );
  }
}
