import AuthApi from '../api/AuthApi';

const storage = window.localStorage;

function key(what) {
  return 'session.' + what;
}

function storeObj(key, obj, toJson=true) {
  if (obj) {
    storage.setItem(key, toJson ? JSON.stringify(obj) : obj);
  } else {
    storage.removeItem(key);
  }
}

function parseJsonValue(what) {
  return what ? JSON.parse(what) : null;
}

function user() {
  const data = parseJsonValue(storage.getItem(key('user')));
  return data ? Object.assign(Object.create(AuthApi.User), data) : null;
}

function storeUser(user) {
  storeObj(key('user'), user);
}

function token() {
  return storage.getItem(key('token'));
}

function storeToken(token) {
  storeObj(key('token'), token, false);
}

function customer() {
  return parseJsonValue(storage.getItem(key('customer')));
}

function storeCustomer(customer) {
  storeObj(key('customer'), customer);
}

function store({ user, token, customer }) {
  storeUser(user);
  storeToken(token);
  storeCustomer(customer);
}

function load() {
  return { user: user(), token: token(), customer: customer() };
}

export const SessionStorage = {
  store,
  load
};
