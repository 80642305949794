import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Container, Loader, Segment, Message, Divider, Visibility } from 'semantic-ui-react';
import { ProductCardsGrid } from './ProductCardsGrid';
import { CategoryFilter } from './CategoryFilter';
import { FlagFilter } from './FlagFilter';
import { SearchField } from '../../SearchField';
import { Messages } from '../../Messages';
import { ScrollToTopOnMount } from '../../ScrollToTopOnMount';
import { updateProductInBasket } from '../../../redux/orders';
import { fetchCategories, fetchProducts } from '../../../redux/store';
import { requestProductsDelayedFetch } from '../../../redux/store';
import { CategoryListState, ProductListState } from '../../../redux/store';
import { ProductsSearchBar } from './ProductsSearchBar';
import './Products.css';

const ProductsBatchTake = 24;

const ConflictingFlags = {
  'new': ['promo'],
  'promo': ['new']
};

class ProductsPure extends React.PureComponent {
  static propTypes = {
    history: PropTypes.object.isRequired,
    fetchProducts: PropTypes.func.isRequired,
    productList: PropTypes.object.isRequired,
    searchProducts: PropTypes.func.isRequired,
    productsSearchQuery: PropTypes.string.isRequired,
    fetchCategories: PropTypes.func.isRequired,
    categoryList: PropTypes.object.isRequired,
    customerId: PropTypes.number,
    isCustomerInactive: PropTypes.bool.isRequired,
    isAdmin: PropTypes.bool.isRequired,
    newOrder: PropTypes.object.isRequired,
    updateProductInBasket: PropTypes.func.isRequired,
    user: PropTypes.object,
  }

  searchProducts = (searchQuery) => {
    this.props.searchProducts(
      this.props.productList.categoryTag,
      searchQuery,
      this.props.productList.flags,
      this.props.isAdmin,
      this.props.customerId,
    );
  }

  fetchProducts = ({ take,
                     categoryTag = this.props.productList.categoryTag,
                     searchQuery = this.props.productList.searchQuery,
                     flags = this.props.productList.flags } = {}) => {
    if (take === undefined) {
      const currTake = this.props.productList.take;
      take = currTake && currTake % ProductsBatchTake === 0 ? currTake : ProductsBatchTake;
    }
    if (flags.indexOf('instock') === -1) {
      flags = flags.concat(['instock']);
    }
    this.props.fetchProducts(
      categoryTag, searchQuery, flags, this.props.isAdmin, this.props.customerId, take);
  }

  onCategoryToggled = (categoryTag) => {
    const set = this.props.productList.categoryTag !== categoryTag;

    // Clear search query and reset products number
    this.fetchProducts({ take: ProductsBatchTake, 
                         categoryTag: set ? categoryTag : null,
                         searchQuery: "" });
  }

  onFlagToggled = (flag) => {
    const clearCategory = flag === 'new' || flag === 'promo';
    const { flags, categoryTag } = this.props.productList;
    if (flags.indexOf(flag) === -1) {
      // Activate
      const conflicts = ConflictingFlags[flag] !== undefined ? ConflictingFlags[flag] : [];
      this.fetchProducts({ take: ProductsBatchTake,
                           flags: flags.filter(f => conflicts.indexOf(f) === -1).concat([flag]),
                           categoryTag: clearCategory ? null : categoryTag });
    } else {
      // Deactivate
      this.fetchProducts({ take: ProductsBatchTake,
                           flags: flags.filter(f => f !== flag),
                           categoryTag: clearCategory ? null : categoryTag });
    }
  }

  openProductPage = (id) => {
    this.props.history.push('/products/' + id);
  }

  tryFetchMoreProducts = () => {
    const productList = this.props.productList;
    if (productList.products.length === productList.take) {
      this.fetchProducts({ take: productList.take + ProductsBatchTake });
    }
  }

  componentDidMount() {
    this.fetchProducts();
    this.props.fetchCategories();
  }

  renderSearch = () => (
    <SearchField searchValue={ this.props.productsSearchQuery }
                 onSearchValueChanged={ this.searchProducts }
                 label="czego szukasz?"
                 size="big"
                 fluid
                 />
  );

  renderFilters() {
    const categoryList = this.props.categoryList;

    if (categoryList.state === CategoryListState.Fetching) {
      return (
        <Segment basic className="categoriesLoader">
          <Loader active={ true } />
        </Segment>
      );
    }

    return (
      <Segment basic>
        <CategoryFilter categories={ categoryList.categories }
                        activeCategoryTag={ this.props.productList.categoryTag }
                        onCategoryToggled={ this.onCategoryToggled }
                        />
        <Divider className="filtersDivider"/>
        <FlagFilter activeFlags={ this.props.productList.flags } 
                    onFlagToggled={ this.onFlagToggled }
                    showSaleFlags={ !!this.props.customerId || this.props.isAdmin }
                    />
      </Segment>
    );
  }

  renderProductsGrid() {
    const productList = this.props.productList;
    const state = productList.state;
    const haveProducts = productList.products.length > 0;
    const isSuccess = state === ProductListState.Idle || state === ProductListState.FetchingMore;

    const loader = state === ProductListState.Fetching
      ? <Loader active className="products" />
      : null;
    
    const productsGrid = haveProducts && isSuccess
      ? <ProductCardsGrid products={ productList.products } 
                          updateProductInBasket={ this.props.updateProductInBasket } 
                          newOrder={ this.props.newOrder }
                          isCustomer={ !!this.props.customerId }
                          isAdmin={ this.props.isAdmin }
                          openProductPage={ this.openProductPage }
                          />
      : null;

    const noProductsMsg = !haveProducts && isSuccess
      ? <Message>Brak produktów</Message>
      : null;

    const unknownErrorMsg = state === ProductListState.ErrorUnknown ? Messages.UnknownError : null;

    const unauthorizedErrorMsg = state === ProductListState.ErrorNoPermissions
      ? <Message negative>
          <Message.Header>Brak uprawnień</Message.Header>
          Nie masz wystarczających uprawnień do przeglądania oferty.
        </Message>
      : null;

    const loaderMore = state === ProductListState.FetchingMore
      ? <Loader active className="products" />
      : null;

    return (
      <Segment basic className="products">
        <ScrollToTopOnMount/>
        { loader }
        { noProductsMsg }
        { unknownErrorMsg }
        { unauthorizedErrorMsg }
        <Visibility onBottomVisible={ this.tryFetchMoreProducts } once={ false }>
          { productsGrid }
        </Visibility>
        <Segment basic textAlign="center">
          { loaderMore }
        </Segment>
      </Segment>
    );
  }

  render() {
    const inactiveUserMessage = this.props.isCustomerInactive
      ? <Message info
                 className="inactiveUserMessage"
                 icon="hourglass half" 
                 header="Prosimy o cierpliwość" 
                 content="Twoje konto nie zostało jeszcze aktywowane. Funkcjonalność serwisu jest ograniczona."/>
      : null;

    return (
      <div>
        <ProductsSearchBar renderSearch={ this.renderSearch } />
        <Container>
          { inactiveUserMessage }
          { this.renderFilters() }
          { this.renderProductsGrid() }
        </Container>
      </div>
    );
  }
}

const mapStateToProps = state => { 
  const session = state.login.session;
  return {
    categoryList: state.store.categoryList,
    productList: state.store.productList,
    newOrder: state.orders.newOrder,
    customerId: session.customer && session.customer.isActive ? session.customer.id : null,
    isCustomerInactive: !!session.customer && !session.customer.isActive,
    isAdmin: !!session.user && session.user.isAdmin(),
    productsSearchQuery: state.store.productsDelayedFetchRequest.searchQuery,
    user: session.user,
  };
};

const mapDispatchToProps = dispatch => ({
  updateProductInBasket: (data) => dispatch(updateProductInBasket(data)),
  fetchCategories: () => dispatch(fetchCategories()),
  fetchProducts: (categoryTag, searchQuery, flags, admin, customerId, take) => 
    dispatch(fetchProducts({ 
      take,
      categoryTag,
      searchQuery,
      flags,
      productsFor: { admin, customerId }
    })),
  searchProducts: (categoryTag, searchQuery, flags, admin, customerId) => {
    dispatch(requestProductsDelayedFetch({
      take: ProductsBatchTake,
      categoryTag,
      searchQuery,
      flags,
      productsFor: { admin, customerId }
    }));
  },
});

export const Products = connect(mapStateToProps, mapDispatchToProps)(ProductsPure);
