import { combineReducers } from 'redux';
import { unauthorized } from './login';
import { productTotalNetPriceValue, orderedProductNetPriceValue } from '../utils/price';

export const OrderState = {
  Idle: 'IDLE',
  InProgress: 'IN_PROGRESS',
  Success: 'SUCCESS',
  ErrorNoPermissions: 'ERROR_NO_PERMISSIONS',
  ErrorUnknown: 'ERROR_UNKNOWN'
};

const initOrderProcessState = {
  state: OrderState.Idle,
  order: null,
};

function orderProcessReducer(state = initOrderProcessState, action) {
  switch (action.type) {
    case 'ORDER_STARTED': {
      const stateUpdate = { state: OrderState.InProgress };
      return Object.assign({}, state, stateUpdate);
    }
    case 'ORDER_FINISHED': {
      const stateUpdate = { state: action.state, order: action.order };
      return Object.assign({}, state, stateUpdate);
    }
    case 'UPDATE_PRODUCT_IN_BASKET': {
      const stateUpdate = { state: OrderState.Idle };
      return Object.assign({}, state, stateUpdate);
    }
    default: {
      return state;
    }
  }
}

function orderStarted() {
  return {
    type: 'ORDER_STARTED'
  };
}

function orderFinished(state, order) {
  return {
    type: 'ORDER_FINISHED',
    state: state,
    order: order
  };
}

export function order() {
  return (dispatch, getState, { WholesaleApi }) => {
    dispatch(orderStarted());

    const newOrder = getState().orders.newOrder;
    const customerInfo = newOrder.customerInfo;
    let orderedProducts = [];
    for (let id in newOrder.productsInBasket) {
      const productInBasket = newOrder.productsInBasket[id];
      if (productInBasket && productInBasket.count > 0) {
        orderedProducts.push({ 
          productId: productInBasket.product.id, 
          count: productInBasket.count, 
          withPostDelivery: productInBasket.withPostDelivery
        });
      }
    }

    const session = getState().login.session;
    if (!session.customer || !session.customer.isActive) {
      return Promise.resolve(dispatch(orderFinished(OrderState.ErrorNoPermissions)));
    }

    const data = { customerId: session.customer.id, orderedProducts, customerInfo };
    return WholesaleApi.order(data, session.token).then(
      order => {
        dispatch(orderFinished(OrderState.Success, order));
        dispatch(clearBasket());
      },
      error => {
        switch (error) {
          case WholesaleApi.OrderError.Forbidden:
            dispatch(orderFinished(OrderState.ErrorNoPermissions));
            break;
          case WholesaleApi.OrderError.Unauthorized:
            dispatch(unauthorized());
            dispatch(orderFinished(OrderState.ErrorNoPermissions));
            break;
          default:
            dispatch(orderFinished(OrderState.ErrorUnknown));
            break;
        }
      }
    );
  };
}

export const OrderSummaryListState = {
  Idle: 'IDLE',
  Fetching: 'FETCHING',
  FetchingMore: 'FETCHING_MORE',
  ErrorNoPermissions: 'ERROR_NO_PERMISSIONS',
  ErrorUnknown: 'ERROR_UNKNOWN'
};

const initOrderSummaryListState = {
  state: OrderSummaryListState.Idle,
  orderSummaries: [],
  ordersState: null,
  customerId: null,
  take: null,
};

function fetchingOrderSummariesStarted(customerId, ordersState, append) {
  return {
    type: 'FETCHING_ORDER_SUMMARIES_STARTED',
    state: append ? OrderSummaryListState.FetchingMore : OrderSummaryListState.Fetching,
    customerId,
    ordersState,
  };
}

function fetchingOrderSummariesFinished(
  state, orderSummaries = [], ordersState = null, customerId = null, take = null, append = false) {
  return {
    type: 'FETCHING_ORDER_SUMMARIES_FINISHED',
    state,
    orderSummaries,
    ordersState,
    customerId,
    append,
    take,
  };
}

function orderSummaryListReducer(state = initOrderSummaryListState, action) {
  switch (action.type) {
    case 'FETCHING_ORDER_SUMMARIES_STARTED': {
      const stateUpdate = { 
        state: action.state, 
        ordersState: action.ordersState, 
        customerId: action.customerId,
      };
      return Object.assign({}, state, stateUpdate);
    }
    case 'FETCHING_ORDER_SUMMARIES_FINISHED': {
      const orderSummaries = action.state === OrderSummaryListState.Idle && action.append
        ? state.orderSummaries.concat(action.orderSummaries) 
        : action.orderSummaries;
      const take = action.state === OrderSummaryListState.Idle && action.append
        ? action.take + state.take
        : action.take;
      const stateUpdate = { 
        state: action.state, 
        orderSummaries: orderSummaries, 
        ordersState: action.ordersState,
        customerId: action.customerId,
        take,
      };
      return Object.assign({}, state, stateUpdate);
    }
    case 'ORDER_FINISHED':
    case 'UPDATE_ORDER_FINISHED': {
      if (action.state === 'SUCCESS') {
        return Object.assign({}, state, initOrderSummaryListState);
      }
      return state;
    }
    default:
      return state;
  }
}

export function fetchOrderSummaries({ customerId = null, ordersState = null, take }) {
  return (dispatch, getState) => {
    const summaryList = getState().orders.orderSummaryList;
    if (summaryList.state === OrderSummaryListState.Fetching ||
        summaryList.state === OrderDetailsState.FetchingMore) {
      return;
    }
    const sameQuery = summaryList.customerId === customerId && 
                      summaryList.ordersState === ordersState;
    if (sameQuery && take <= summaryList.take) {
      return dispatch(
        fetchingOrderSummariesFinished(
          OrderSummaryListState.Idle, 
          summaryList.orderSummaries.slice(0, take), 
          ordersState, 
          customerId, 
          take,
          false
        )
      );
    } else if (sameQuery && summaryList.take !== null) {
      return dispatch(fetchMoreOrderSummaries(take - summaryList.take));
    }
    return dispatch(doFetchOrderSummaries(customerId, ordersState, take));
  };
}

function fetchMoreOrderSummaries(take) {
  return (dispatch, getState) => {
    const summaryList = getState().orders.orderSummaryList;
    const summaries = summaryList.orderSummaries;
    const lastId = summaries.length > 0 ? summaries[summaries.length - 1].id : null;
    return dispatch(
      doFetchOrderSummaries(
        summaryList.customerId,
        summaryList.ordersState,
        take,
        "desc",
        lastId,
        true,
      )
    );
  };
}

function doFetchOrderSummaries(customerId, state, take, sortOrder, lastId, append) {
  return (dispatch, getState, { WholesaleApi }) => {
    dispatch(fetchingOrderSummariesStarted(customerId, state, append));
    const session = getState().login.session;
    return WholesaleApi.orders({ customerId, state, take, sortOrder, lastId }, session.token).then(
      orderSummaries => dispatch(
        fetchingOrderSummariesFinished(
          OrderSummaryListState.Idle,
          orderSummaries,
          state,
          customerId,
          take,
          append)
      ),
      error => {
        switch (error) {
          case WholesaleApi.OrdersError.Forbidden:
            dispatch(fetchingOrderSummariesFinished(OrderSummaryListState.ErrorNoPermissions));
            break;
          case WholesaleApi.OrdersError.Unauthorized:
            dispatch(unauthorized());
            dispatch(fetchingOrderSummariesFinished(OrderSummaryListState.ErrorNoPermissions));
            break;
          default:
            dispatch(fetchingOrderSummariesFinished(OrderSummaryListState.ErrorUnknown));
        }
      }
    );
  };
}

export const OrderDetailsState = {
  Idle: 'IDLE',
  Fetching: 'FETCHING',
  ErrorNotExists: 'ERROR_NOT_EXISTS',
  ErrorNoPermissions: 'ERROR_NO_PERMISSIONS',
  ErrorUnknown: 'ERROR_UNKNOWN'
};

const initOrderDetailsState = {
  state: OrderDetailsState.Idle,
  order: null,
};

function orderDetailsReducer(state = initOrderDetailsState, action) {
  switch (action.type) {
    case 'FETCHING_ORDER_STARTED': {
      const stateUpdate = { state: OrderDetailsState.Fetching };
      return Object.assign({}, state, stateUpdate);
    }
    case 'FETCHING_ORDER_FINISHED': {
      const stateUpdate = { state: action.state, order: action.order };
      return Object.assign({}, state, stateUpdate);
    }
    case 'UPDATE_ORDER_FINISHED': {
      if (action.state === 'SUCCESS' && state.order && state.order.id === action.order.id) {
        return Object.assign({}, state, { order: action.order });
      }
      return state;
    }
    default:
      return state;
  }
}

function fetchingOrderStarted() {
  return {
    type: 'FETCHING_ORDER_STARTED'
  };
}

function fetchingOrderFinished(state, order) {
  return {
    type: 'FETCHING_ORDER_FINISHED',
    state: state,
    order: order
  };
}

export function fetchOrder(orderId) {
  return (dispatch, getState, { WholesaleApi }) => {
    dispatch(fetchingOrderStarted());
    const token = getState().login.session.token;
    return WholesaleApi.orderDetails(orderId, token).then(
      order => dispatch(fetchingOrderFinished(OrderDetailsState.Idle, order)),
      error => {
        switch (error) {
          case WholesaleApi.OrderDetailsError.Forbidden:
            dispatch(fetchingOrderFinished(OrderDetailsState.ErrorNoPermissions));
            break;
          case WholesaleApi.OrderDetailsError.Unauthorized:
            dispatch(unauthorized());
            dispatch(fetchingOrderFinished(OrderDetailsState.ErrorNoPermissions));
            break;
          case WholesaleApi.OrderDetailsError.NotExists:
            dispatch(fetchingOrderFinished(OrderDetailsState.ErrorNotExists));
            break;
          default:
            dispatch(fetchingOrderFinished(OrderDetailsState.ErrorUnknown));
        }
      }
    );
  };
}

const initNewOrder = {
  productsInBasket: {}, // { id: { product, count, withPostDelivery } }
  totalNetPriceValue: 0,
  totalCount: 0,
  customerInfo: ""
};

function newOrderReducer(state = initNewOrder, action) {
  switch (action.type) {
    case 'UPDATE_PRODUCT_IN_BASKET': {
      const basketItem = state.productsInBasket[action.data.product.id];
      const product = basketItem ? basketItem.product : null;
      const prevCount = basketItem ? basketItem.count : 0;
      const prevDelivery = basketItem ? basketItem.withPostDelivery : false;
      const prevPrice = product ? productTotalNetPriceValue(product, prevCount, prevDelivery) : 0;
      const price = productTotalNetPriceValue(action.data.product, 
                                              action.data.count, 
                                              action.data.withPostDelivery);
      const totalCount = state.totalCount + action.data.count - prevCount;
      const totalNetPriceValue = totalCount === 0 ? 0 : state.totalNetPriceValue + price - prevPrice;
      let productsInBasketUpdate = {};
      productsInBasketUpdate[action.data.product.id] = action.data;
      const stateUpdate = { 
        totalNetPriceValue,
        totalCount,
        productsInBasket: Object.assign({}, state.productsInBasket, productsInBasketUpdate),
      };
      return Object.assign({}, state, stateUpdate);
    }
    case 'CLEAR_BASKET':
    case 'LOGGED_OUT': {
      return Object.assign({}, state, initNewOrder);
    }
    case 'UPDATE_NEW_ORDER_CUSTOMER_INFO': {
      return Object.assign({}, state, { customerInfo: action.text });
    }
    default:
      return state;
  }
}

export function updateProductInBasket({ product, count, withPostDelivery }) {
  return {
    type: 'UPDATE_PRODUCT_IN_BASKET',
    data: { product, count, withPostDelivery },
  };
}

export function updateNewOrderCustomerInfo(text) {
  return {
    type: 'UPDATE_NEW_ORDER_CUSTOMER_INFO',
    text: text,
  };
}

export function clearBasket() {
  return {
    type: 'CLEAR_BASKET'
  };
}

const initOrderToUpdate = {
  id: null,
  state: null,
  totalNetPriceValue: 0,
  // { id: { count, totalNetPriceValue, postDeliveryNetPriceValue } }
  orderedProducts: {},
  isDirty: false,
};

export function initializeOrderToUpdate(order) {
  let orderedProducts = Object.create(null);
  let totalNetPriceValue = 0;
  for (let idx in order.orderedProducts) {
    const product = order.orderedProducts[idx];
    totalNetPriceValue += orderedProductNetPriceValue(product);
    orderedProducts[product.productId] = Object.assign({}, product);
  }
  return {
    type: 'INIT_ORDER_TO_UPDATE',
    order: { 
      id: order.id,
      state: order.state,
      orderedProducts,
      totalNetPriceValue: totalNetPriceValue,
      isDirty: false,
    }
  };
}

export function changeOrderToUpdateState(state) {
  return {
    type: 'CHANGE_ORDER_TO_UPDATE_STATE',
    state: state,
  };
}

export function updateOrderedProduct(data) {
  return {
    type: 'UPDATE_ORDERED_PRODUCT',
    productId: data.productId,
    data: {
      count: data.count,
      totalNetPriceValue: data.totalNetPriceValue,
      postDeliveryNetPriceValue: data.postDeliveryNetPriceValue,
    }
  };
}

function orderToUpdateReducer(state = initOrderToUpdate, action) {
  switch (action.type) {
    case 'INIT_ORDER_TO_UPDATE': {
      return Object.assign({}, state, action.order);
    }
    case 'CHANGE_ORDER_TO_UPDATE_STATE': {
      return Object.assign({}, state, { state: action.state, isDirty: true });
    }
    case 'UPDATE_ORDERED_PRODUCT': {
      const productUpdate = Object.assign({}, state.orderedProducts[action.productId], action.data);
      let orderedProducts = Object.assign({}, state.orderedProducts);
      const currentPrice = orderedProductNetPriceValue(orderedProducts[action.productId]);
      const newPrice = orderedProductNetPriceValue(productUpdate);
      const totalNetPriceValue = state.totalNetPriceValue - currentPrice + newPrice;
      orderedProducts[action.productId] = productUpdate;
      return Object.assign({}, state, { orderedProducts, totalNetPriceValue, isDirty: true });
    }
    default: {
      return state;
    }
  }
}

export const UpdateOrderState = {
  Idle: 'IDLE',
  InProgress: 'IN_PROGRESS',
  Success: 'SUCCESS',
  ErrorNotExists: 'ERROR_NOT_EXISTS',
  ErrorNoPermissions: 'ERROR_NO_PERMISSIONS',
  ErrorUnknown: 'ERROR_UNKNOWN',
};

const initUpdateOrderProcessState = {
  order: null,
  orderId: null,
  state: UpdateOrderState.Idle,
};

function updateOrderStarted(orderId) {
  return {
    type: 'UPDATE_ORDER_STARTED',
    orderId: orderId,
  };
}

function updateOrderFinished(state, order = null) {
  return {
    type: 'UPDATE_ORDER_FINISHED',
    state: state,
    order: order,
  };
}

export function resetUpdateOrderProcess() {
  return {
    type: 'RESET_UPDATE_ORDER_PROCESS',
  };
}

export function updateOrder() {
  return (dispatch, getState, { WholesaleApi }) => {
    const id = getState().orders.orderToUpdate.id;

    dispatch(updateOrderStarted(id));

    const session = getState().login.session;
    if (!session.user.isAdmin()) {
      return Promise.resolve(dispatch(orderFinished(OrderState.ErrorNoPermissions)));
    }

    const state = getState().orders.orderToUpdate.state;
    const orderToUpdate = getState().orders.orderToUpdate;
    let orderedProducts = [];
    for (let id in orderToUpdate.orderedProducts) {
      const product = Object.assign({}, orderToUpdate.orderedProducts[id]);
      orderedProducts.push(product);
    }

    const data = { orderedProducts, state };
    return WholesaleApi.updateOrder(id, data, session.token).then(
      order => {
        dispatch(updateOrderFinished(UpdateOrderState.Success, order));
        dispatch(initializeOrderToUpdate(order));
      },
      error => {
        switch (error) {
          case WholesaleApi.UpdateOrderError.Forbidden:
            dispatch(orderFinished(UpdateOrderState.ErrorNoPermissions));
            break;
          case WholesaleApi.UpdateOrderError.Unauthorized:
            dispatch(unauthorized());
            dispatch(orderFinished(UpdateOrderState.ErrorNoPermissions));
            break;
          case WholesaleApi.UpdateOrderError.NotExists:
            dispatch(orderFinished(UpdateOrderState.ErrorNotExists));
            break;
          default:
            dispatch(orderFinished(UpdateOrderState.ErrorUnknown));
            break;
        }
      }
    );
  };
}

function updateOrderProcessReducer(state = initUpdateOrderProcessState, action) {
  switch (action.type) {
    case 'UPDATE_ORDER_STARTED': {
      return Object.assign({}, state, { 
        state: UpdateOrderState.InProgress, 
        orderId: action.orderId 
      });
    }
    case 'UPDATE_ORDER_FINISHED': {
      return Object.assign({}, state, {
        state: action.state,
        order: action.order,
      });
    }
    case 'RESET_UPDATE_ORDER_PROCESS': {
      return initUpdateOrderProcessState;
    }
    default: {
      return state;
    }
  }
}

export function ordersReducer() {
  return combineReducers({
    newOrder: newOrderReducer,
    orderProcess: orderProcessReducer,
    orderSummaryList: orderSummaryListReducer,
    orderDetails: orderDetailsReducer,
    orderToUpdate: orderToUpdateReducer,
    updateOrderProcess: updateOrderProcessReducer,
  });
}
