import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { PrivateRoute } from '../PrivateRoute';
import { Order } from './Order';
import { Orders } from './Orders';
import { NewOrder } from './NewOrder';

export class OrdersRouter extends React.PureComponent {
  defaultRedirect = () => <Redirect to='/'/>;
  render() {
    return (
      <Switch>
        <PrivateRoute exact path='/orders' component={ Orders }/>
        <PrivateRoute exact path='/orders/:id' component={ Order }/>
        <PrivateRoute exact path='/new-order' component={ NewOrder }/>
        <Route path='/' render={ this.defaultRedirect }/>
      </Switch>
    );
  }
}
