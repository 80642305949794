import Config from "../config";

export function dropEndingSlash(url) {
  return url.endsWith('/') ? url.substr(0, url.length - 1) : url;
}

export function dropBeginningSlash(url) {
  return url.startsWith('/') ? url.substr(1, url.length) : url;
}

export function joinUrl(prefix, suffix) {
  return dropEndingSlash(prefix) + '/' + dropBeginningSlash(suffix);
}

export function isEmailValid(emailAddress) {
  // Only requires '@' and no spaces around.
  return emailAddress === "" || /[^\s]+@[^\s]+$/.test(emailAddress);
}

export function trimString(string) {
  return string ? string.trim() : string;
}

export function required(value) {
  return value && value.trim().length;
}

export function isPasswordValid(password) {
  if (password === "") return true;
  if (password.length < 8) return false;
  let count = 0;
  for (let i in password) {
    if (!Number.isNaN(Number.parseInt(password[i], 10))) {
      count += 1;
    }
  }
  return count >= 2;
}

export function isDiscountValid(discount) {
  const value = Number(discount);
  return !Number.isNaN(value) && 0 <= value && value <= 100;
}

export function isCountValid(count) {
  const value = Number(count);
  return !Number.isNaN(value) && 0 <= value && value % 1 === 0;
}

export function isPriceValid(price) {
  const value = Number(price);
  return !Number.isNaN(value) && 0 <= value;
}

export function escapeRegexp(string) {
  return string.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&');
}

export function tryResizeImage(url, width) {
  if (url && url.startsWith(Config.cloudinaryImagesPrefix)) {
    return resizeCloudinaryImage(url, width);
  }
  return url;
}

const cloudinaryImagesPrefixLength = Config.cloudinaryImagesPrefix.length;

function resizeCloudinaryImage(url, width) {
  return Config.cloudinaryImagesPrefix + 
    "w_" + width
    + "/" + url.substr(cloudinaryImagesPrefixLength, url.length);
}
