import React from 'react';
import { Modal } from 'semantic-ui-react';

export const ClearBasketConfirmationModal = ({ trigger, clearBasket }) => (
  <Modal trigger={ trigger } 
         size='small'
         header='Opróżnić koszyk?'
         content='Wszystkie produkty zostaną usunięte z koszyka. Czy chcesz kontynuować?'
         actions={[
           { 
             key: 'no', 
             content: 'Nie', 
             color: 'red', 
           },
           {  
             key: 'yes', 
             content: 'Tak', 
             color: 'green', 
             onClick: clearBasket,
            },
         ]}
  />
);
