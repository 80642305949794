import React from 'react';
import { Redirect } from 'react-router-dom';
import { PrivateRoute } from './PrivateRoute';
import { connect } from 'react-redux';
import { isUrl } from './RoutesCommon';

const AdminRoutePure =
  ({ component, render, isAdmin, ...rest }) => (
    <PrivateRoute {...rest} render={ props => {
      return (
        !isAdmin && !isUrl(props, '/')
          ? <Redirect to='/' />
          : component ? React.createElement(component, props) : render(props)
      );
    }}/>
  );

const mapStateToProps = state => {
  const session = state.login.session;
  return {
    isAdmin: !!session.user && session.user.isAdmin(),
  };
};

export const AdminRoute = connect(mapStateToProps)(AdminRoutePure);
