import React from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import { Redirect } from 'react-router-dom';
import { Container, Header, Icon, Message } from 'semantic-ui-react';
import { RegistrationStep } from './RegistrationStep';
import { CustomerRegistrationForm } from './CustomerRegistrationForm';
import { CustomerRegistrationState } from '../../../redux/customers';
import { ScrollToTopOnMount } from '../../ScrollToTopOnMount';

class CustomerRegistrationPure extends React.PureComponent {
  static propTypes = {
    registrationState: PropTypes.string.isRequired,
    hasSessionCustomer: PropTypes.bool.isRequired,
  }

  render() {
    if (this.props.registrationState === CustomerRegistrationState.Idle
          && this.props.hasSessionCustomer) {
      return <Redirect to='/'/>;
    }

    const isSuccess = this.props.registrationState === CustomerRegistrationState.Success;
    const step = <RegistrationStep step={ isSuccess ? "wait" : "create-customer" } 
                                   className="mobile hidden"
                                   />;
    const content = isSuccess ? this.renderRegistrationCompleted()
                              : <CustomerRegistrationForm/>;
    return (
      <Container text textAlign='center'>
        <ScrollToTopOnMount/>
        <Header as='h1' icon textAlign='center'>
          <Icon name='vcard' circular/>
          <Header.Content>Konto klienta</Header.Content>
        </Header>
        { step }
        { content }
      </Container>
    );
  }

  renderRegistrationCompleted() {
    return (
      <Message success>
        <Message.Header>Dziękujemy</Message.Header>
        <Icon name='hourglass outline'/>
        Nasz zespół musi potwierdzić Twoje konto. Poinformujemy Cię, kiedy to nastąpi.
      </Message>
    );
  }
}

const mapStateToProps = state => ({
  hasSessionCustomer: !!state.login.session.customer,
  registrationState: state.customers.registrationProcess.state,
});

export const CustomerRegistration =
  connect(mapStateToProps)(CustomerRegistrationPure);
