import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Item, ItemGroup, Label } from 'semantic-ui-react';
import { roundPriceValue } from '../../../utils/price';
import { AvailabilityLabel } from '../Products/ProductLabels';
import './Orders.css';
import { tryResizeImage } from '../../../utils/common';
import Config from '../../../config';

export class OrderProductsList extends React.PureComponent {
  static propTypes = {
    orderedProducts: PropTypes.arrayOf(PropTypes.object).isRequired,
    orderClosed: PropTypes.bool.isRequired,
  }

  render() {
    const items = this.props.orderedProducts.map(product => {
      const deliveryInfo = product.postDeliveryNetPriceValue !== undefined 
      ? <Label>dostawa 24h</Label> : null;

      const deliveryPrice = product.postDeliveryNetPriceValue !== undefined
        ? product.postDeliveryNetPriceValue : 0;

      const netPrice = product.totalNetPriceValue + deliveryPrice;

      const link = "/products/" + product.productId;

      const availabilityLabel = !this.props.orderClosed
        ? <AvailabilityLabel availableSince={ product.availableSince }/>
        : null;

      return (
        <Item key={ product.productId }>
          <Item.Image className="orderedProductItemImage" 
                      size="small" 
                      src={ tryResizeImage(product.images[0], Config.imgWidth.thumbnail) } />
          <Item.Content verticalAlign="middle">
            <Item.Header>
              <Link className="orderedProductItemLink" to={ link }>{ product.index }</Link>
            </Item.Header>
            <Item.Meta>{ product.name }</Item.Meta>
            <Item.Extra>
              <Label>{ product.count } szt.</Label>
              { deliveryInfo }
              <Label color="blue">{ roundPriceValue(netPrice) } PLN</Label>
              { availabilityLabel }
            </Item.Extra>
          </Item.Content>
        </Item>
      );
    });

    return (
      <ItemGroup divided>
        { items }
      </ItemGroup>
    );
  }
}
