import React from 'react';
import { Redirect } from 'react-router-dom';
import { PublicRoute } from './PublicRoute';
import { connect } from 'react-redux';
import { isUrl, LoginUrl } from './RoutesCommon';

const PrivateRoutePure =
  ({ component, render, isUserLoggedIn, isCustomerRegistrationRequired, ...rest }) => (
    <PublicRoute {...rest} render={ props => {
      return (
        !isUserLoggedIn && !isUrl(props, LoginUrl) 
          ? <Redirect to={{
              pathname: LoginUrl,
              state: { from: props.location },
            }} />
          : component ? React.createElement(component, props) : render(props)
      );
    }}/>
  );

const mapStateToProps = state => {
  const session = state.login.session;
  return {
    isUserLoggedIn: !!session.user,
  };
};

export const PrivateRoute = connect(mapStateToProps)(PrivateRoutePure);
