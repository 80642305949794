import { combineReducers } from 'redux';
import { actions, combineForms } from 'react-redux-form';
import { unauthorized } from './login';
import * as R from 'ramda';
import moment from 'moment';

const DefaultFetchDelayMs = 500;

const initProductsDelayedFetchRequest = {
  requestNumber: 0,
  searchQuery: "",
};

function productsDelayedFetchRequestReducer(state = initProductsDelayedFetchRequest, action) {
  switch (action.type) {
    case 'NEXT_PRODUCTS_DELAYED_FETCH_REQUEST': {
      const stateUpdate = { requestNumber: action.requestNumber, searchQuery: action.searchQuery };
      return Object.assign({}, state, stateUpdate);
    }
    case 'FETCHING_PRODUCTS_STARTED': {
      if (action.searchQuery !== state.searchQuery) {
        return { requestNumber: state.requestNumber + 1, searchQuery: action.searchQuery };
      }
      return state;
    }
    default:
      return state;
  }
}

function nextProductsDelayedFetchRequest(requestNumber, searchQuery) {
  return {
    type: 'NEXT_PRODUCTS_DELAYED_FETCH_REQUEST',
    requestNumber: requestNumber,
    searchQuery: searchQuery,
  };
}

export function requestProductsDelayedFetch({ 
    categoryTag = null, 
    searchQuery, 
    flags = [], 
    productsFor,
    take,
    delayMs = DefaultFetchDelayMs }) {
  return (dispatch, getState) => {
    const nextRequestNumber = getState().store.productsDelayedFetchRequest.requestNumber + 1;
    dispatch(nextProductsDelayedFetchRequest(nextRequestNumber, searchQuery));
    setTimeout(() => dispatch(
      maybeFetchProducts(nextRequestNumber, categoryTag, searchQuery, flags, productsFor, take)
    ), delayMs);
  };
}

function maybeFetchProducts(requestNumber, categoryTag, searchQuery, flags, productsFor, take) {
  return (dispatch, getState) => {
    const currentRequestNumber = getState().store.productsDelayedFetchRequest.requestNumber;
    if (currentRequestNumber === requestNumber) {
      dispatch(fetchProducts({ take, categoryTag, searchQuery, flags, productsFor }));
    }
  };
}

export const ProductListState = {
  Idle: 'IDLE',
  Fetching: 'FETCHING',
  FetchingMore: 'FETCHING_MORE',
  ErrorNoPermissions: 'ERROR_NO_PERMISSIONS',
  ErrorUnknown: 'ERROR_UNKNOWN'
};

const initProductListState = {
  products: [],
  categoryTag: null,
  searchQuery: "",
  flags: [],
  state: ProductListState.Idle,
  productsFor: null,
  take: null,
};

function productListReducer(state = initProductListState, action) {
  switch (action.type) {
    case 'FETCHING_PRODUCTS_STARTED': {
      const stateUpdate = { 
        state: action.state, 
        categoryTag: action.categoryTag,
        searchQuery: action.searchQuery,
        flags: action.flags,
        productsFor: action.productsFor,
      };
      return Object.assign({}, state, stateUpdate);
    }
    case 'FETCHING_PRODUCTS_FINISHED': {
      const products = action.state === ProductListState.Idle && action.append
        ? state.products.concat(action.products) 
        : action.products;
      const take = action.state === ProductListState.Idle && action.append
        ? state.take + action.take
        : action.take;
      const stateUpdate = { 
        state: action.state, 
        products,
        categoryTag: action.categoryTag,
        searchQuery: action.searchQuery,
        flags: action.flags,
        productsFor: action.productsFor,
        take
      };
      return Object.assign({}, state, stateUpdate);
    }
    case 'SAVE_PRODUCT_FINISHED':
    case 'DELETE_PRODUCT_PHOTO_FINISHED':
    case 'UPLOAD_PRODUCT_PHOTO_FINISHED': {
      if (action.state === 'SUCCESS') {
        const product = action.product;
        const idx = R.findIndex(R.propEq('id', product.id))(state.products);
        if (idx !== -1) {
          const updated = Object.assign({}, state.products[idx], product);
          const products = R.update(idx, updated)(state.products);
          return Object.assign({}, state, { products });
        }
      }
      return state;
    }
    case 'SAVE_PRODUCT_FOR_SALE_FINISHED': {
      if (action.state === SaveProductForSaleState.Success) {
        const productId = action.productForSale.id;
        const idx = R.findIndex(R.propEq('id', productId))(state.products);
        if (idx !== -1) {
          const updated = Object.assign({}, state.products[idx], action.productForSale);
          const products = R.update(idx, updated)(state.products);
          return Object.assign({}, state, { products });
        }
      }
      return state;
    }
    default: {
      return state;
    }
  }
}

function fetchingProductsStarted(categoryTag, searchQuery, flags, productsFor, append) {
  return {
    type: 'FETCHING_PRODUCTS_STARTED',
    categoryTag,
    searchQuery,
    flags,
    productsFor,
    state: append ? ProductListState.FetchingMore : ProductListState.Fetching,
  };
}

function fetchingProductsFinished(
    state, 
    products = [], 
    categoryTag = null, 
    searchQuery = "", 
    flags = [], 
    productsFor = null,
    take = null,
    append = false) {
  return {
    type: 'FETCHING_PRODUCTS_FINISHED',
    state: state,
    products: products,
    categoryTag: categoryTag,
    searchQuery: searchQuery,
    flags: flags,
    productsFor: productsFor,
    take: take,
    append: append,
  };
}

function sameProductsFor(currFor, newFor) {
  if (!!currFor ^ !!newFor) {
    return false;
  }
  if (!!currFor.admin !== !!newFor.admin) {
    return false;
  }
  if (!currFor.customerId && !newFor.customerId) {
    return true;
  }
  return currFor.customerId === newFor.customerId;
}

export 
function fetchProducts({
    take,
    categoryTag = null, 
    searchQuery = "", 
    flags = [], 
    productsFor = null }) {
  return (dispatch, getState) => {
    const productList = getState().store.productList;

    if (productList.state === ProductListState.Fetching || 
        productList.state === ProductListState.FetchingMore) {
      return;
    }

    const sameQuery = 
      productList.categoryTag === categoryTag &&
      productList.searchQuery === searchQuery &&
      productList.flags.toString() === flags.toString() &&
      sameProductsFor(productList.productsFor, productsFor);

    if (sameQuery && take <= productList.take) {
      return dispatch(
        fetchingProductsFinished(
          ProductListState.Idle,
          productList.products.slice(0, take), 
          categoryTag, 
          searchQuery, 
          flags,
          productsFor,
          take,
          false
        )
      );
    } else if (sameQuery && productList.take !== null) {
      return dispatch(fetchMoreProducts(take - productList.take));
    }
    return dispatch(
      doFetchProducts(categoryTag, searchQuery, flags, productsFor, take)
    );
  };
}

function fetchMoreProducts(take) {
  return (dispatch, getState) => {
    const productList = getState().store.productList;
    const products = productList.products;
    const lastId = products.length > 0 ? products[products.length - 1].id : null;
    const lastIndex = products.length > 0 ? products[products.length - 1].index : null;
    return dispatch(
      doFetchProducts(
        productList.categoryTag,
        productList.searchQuery,
        productList.flags,
        productList.productsFor,
        take,
        lastId,
        lastIndex,
        true,
      )
    );
  };
}

function doFetchProducts(
    categoryTag, searchQuery, productFlags, productsFor, take, lastId, lastIndex, append
  ) {
  return (dispatch, getState, { WholesaleApi }) => {
    dispatch(
      fetchingProductsStarted(categoryTag, searchQuery, productFlags, productsFor, append)
    );
    const session = getState().login.session;
    const promoOnly = productFlags.indexOf("promo") !== -1;
    const sortAlpha = productFlags.indexOf("sort-alphabetically") !== -1;
    const availableSince = productFlags.indexOf("available") !== -1 ? moment.now() : undefined;
    const flags = productFlags.filter(f => 
      f !== "promo" && f !== "available" && f !== "sort-alphabetically"
    );
    const requestData = {
      sortBy: sortAlpha ? 'index' : 'id',
      customerId: productsFor && productsFor.customerId, 
      categoryTag, 
      searchQuery, 
      flags, 
      lastId,
      lastIndex,
      take, 
      promoOnly, 
      availableSince 
    };
    if (productsFor && (!!productsFor.customerId || productsFor.admin)) {
      return WholesaleApi.products(requestData, session.token).then(
        products => dispatch(
          fetchingProductsFinished(
            ProductListState.Idle, 
            products, 
            categoryTag, 
            searchQuery, 
            productFlags, 
            productsFor,
            take,
            append 
          )
        ),
        error => {
          switch (error) {
            case WholesaleApi.ProductsError.Unauthorized:
              dispatch(fetchingProductsFinished(ProductListState.ErrorNoPermissions));
              dispatch(unauthorized());
              break;
            default:
              dispatch(fetchingProductsFinished(ProductListState.ErrorUnknown));
          }
        }
      );
    }
    return WholesaleApi.retailProducts(requestData).then(
      products => dispatch(
        fetchingProductsFinished(
          ProductListState.Idle,
          products, 
          categoryTag, 
          searchQuery, 
          productFlags,
          productsFor,
          take,
          append
        )
      ),
      error => dispatch(fetchingProductsFinished(ProductListState.ErrorUnknown))
    );
  };
}

export const ProductDetailsState = {
  Idle: 'IDLE',
  Fetching: 'FETCHING',
  ErrorNoPermissions: 'ERROR_NO_PERMISSIONS',
  ErrorNotExists: 'ERROR_NOT_EXISTS',
  ErrorUnknown: 'ERROR_UNKNOWN',
};

const initProductDetailsState = {
  product: null,
  state: ProductDetailsState.Idle,
  productFor: null,
};

function productDetailsReducer(state = initProductDetailsState, action) {
  switch (action.type) {
    case 'FETCHING_PRODUCT_STARTED': {
      const stateUpdate = { state: ProductDetailsState.Fetching };
      return Object.assign({}, state, stateUpdate);
    }
    case 'FETCHING_PRODUCT_FINISHED': {
      const stateUpdate = { 
        state: action.state, 
        product: action.product, 
        productFor: action.productFor,
      };
      return Object.assign({}, state, stateUpdate);
    }
    case 'SAVE_PRODUCT_FINISHED':
    case 'DELETE_PRODUCT_PHOTO_FINISHED':
    case 'UPLOAD_PRODUCT_PHOTO_FINISHED': {
      if (action.state === 'SUCCESS' && 
          state.product 
          && state.product.id === action.product.id) {
        return Object.assign({}, state, { product: action.product });
      }
      return state;
    }
    case 'SAVE_PRODUCT_FOR_SALE_FINISHED': {
      if (action.state === SaveProductForSaleState.Success &&
          state.product &&
          state.product.id === action.productForSale.id) {
        const updated = Object.assign({}, state.product, action.productForSale);
        return Object.assign({}, state, { product: updated });
      }
      return state;
    }
    default: {
      return state;
    }
  }
}

function fetchingProductStarted() {
  return {
    type: 'FETCHING_PRODUCT_STARTED'
  };
}

function fetchingProductFinished(state, product = null, productFor = null) {
  return {
    type: 'FETCHING_PRODUCT_FINISHED',
    product: product,
    state: state,
    productFor: productFor,
  };
}

export function fetchProduct(productId, productFor) {
  return (dispatch, getState, { WholesaleApi }) => {
    dispatch(fetchingProductStarted());
    const session = getState().login.session;
    if (productFor && (!!productFor.customerId || productFor.admin)) {
      const request = { productId, customerId: productFor.customerId };
      return WholesaleApi.productDetails(request, session.token).then(
        product => 
          dispatch(fetchingProductFinished(ProductDetailsState.Idle, product, productFor)),
        error => {
          switch (error) {
            case WholesaleApi.ProductDetailsError.NotExists:
              dispatch(fetchingProductFinished(ProductDetailsState.ErrorNotExists));
              break;
            case WholesaleApi.ProductDetailsError.Forbidden:
              dispatch(fetchingProductFinished(ProductDetailsState.NoPermissions));
              break;
            case WholesaleApi.ProductDetailsError.Unauthorized:
              dispatch(fetchingProductFinished(ProductDetailsState.NoPermissions));
              dispatch(unauthorized());
              break;
            default:
              dispatch(fetchingProductFinished(ProductDetailsState.ErrorUnknown));
          }
        }
      );
    }
    return WholesaleApi.retailProductDetails({ productId }).then(
      data => dispatch(fetchingProductFinished(ProductDetailsState.Idle, data)),
      error => {
        switch (error) {
          case WholesaleApi.RetailProductDetailsError.NotExists:
            dispatch(fetchingProductFinished(ProductDetailsState.ErrorNotExists));
            break;
          default:
            dispatch(fetchingProductFinished(ProductDetailsState.ErrorUnknown));
        }
      }
    );
  };
}

export const CategoryListState = {
  Idle: 'IDLE',
  Fetching: 'FETCHING',
  ErrorUnknown: 'ERROR_UNKNOWN'
};

const initCategoryListState = {
  categories: [],
  state: CategoryListState.State
};

function categoryListReducer(state = initCategoryListState, action) {
  switch (action.type) {
    case 'FETCHING_CATEGORIES_STARTED': {
      const stateUpdate = { state: CategoryListState.Fetching };
      return Object.assign({}, state, stateUpdate);
    }
    case 'FETCHING_CATEGORIES_FINISHED': {
      const categories = action.categories ? action.categories : [];
      const stateUpdate = { state: action.state, categories };
      return Object.assign({}, state, stateUpdate);
    }
    case 'UPSERT_CATEGORY_FINISHED': {
      if (action.state === UpsertCategoryState.Success) {
        const newCategory = action.category;
        const categories = !state.categories.find(c => c.tag === newCategory.tag)
          ? [action.category].concat(state.categories) : state.categories;
        return Object.assign({}, state,  { categories });
      }
      return state;
    }
    case 'DELETE_CATEGORY_FINISHED': {
      if (action.state === DeleteCategoryState.Success) {
        const categories = state.categories.filter(c => c.tag !== action.categoryTag);
        return Object.assign({}, state,  { categories });
      }
      return state;
    }
    default: {
      return state;
    }
  }
}

function fetchingCategoriesStarted() {
  return {
    type: 'FETCHING_CATEGORIES_STARTED'
  };
}

function fetchingCategoriesFinished(state, categories) {
  return {
    type: 'FETCHING_CATEGORIES_FINISHED',
    state: state,
    categories: categories
  };
}

export function fetchCategories() {
  return (dispatch, getState, { StoreApi }) => {
    if (getState().store.categoryList.categories.length > 0 ||
        getState().store.categoryList.state === CategoryListState.Fetching) {
      return;
    }
    dispatch(fetchingCategoriesStarted());
    return StoreApi.categories().then(
      categories => dispatch(fetchingCategoriesFinished(CategoryListState.Idle, categories)),
      error => dispatch(fetchingCategoriesFinished(CategoryListState.ErrorUnknown))
    );
  };
}

export const UpsertCategoryState = {
  Idle: 'IDLE',
  InProgress: 'IN_PROGRESS',
  Success: 'SUCCESS',
  ErrorNoPermissions: 'ERROR_NO_PERMISSIONS',
  ErrorUnknown: 'ERROR_UNKNOWN',
};

const initUpsertCategory = {
  name: "",
};

const initUpsertCategoryState = {
  state: UpsertCategoryState.Idle,
  category: null,
};

function upsertCategoryStarted(category) {
  return {
    type: 'UPSERT_CATEGORY_STARTED',
    category: category,
  };
}

function upsertCategoryFinished(state, category) {
  return {
    type: 'UPSERT_CATEGORY_FINISHED',
    state,
    category,
  };
}

export function resetUpsertCategoryProcess() {
  return {
    type: 'RESET_UPSERT_CATEGORY_PROCESS',
  };
}

function normalize(name) {
  return name.trim();
}

function tagify(normalizedName) {
  return normalizedName.toLowerCase().replace(/ +/g, '_');
}

export function upsertCategory(name) {
  const category = { name: normalize(name), tag: tagify(normalize(name)) };
  return (dispatch, getState, { StoreApi }) => {
    dispatch(upsertCategoryStarted(category));
    const token = getState().login.session.token;
    return StoreApi.upsertCategory(category, token).then(
      result => {
        dispatch(upsertCategoryFinished(UpsertCategoryState.Success, category));
        dispatch(actions.reset('store.form.upsertCategory'));
      },
      error => {
        switch (error) {
          case StoreApi.UpsertCategoryError.Unauthorized:
            dispatch(unauthorized());
            dispatch(upsertCategoryFinished(UpsertCategoryState.ErrorNoPermissions, category));
            break;
          case StoreApi.UpsertCategoryError.Forbidden:
            dispatch(upsertCategoryFinished(UpsertCategoryState.ErrorNoPermissions, category));
            break;
          default:
            dispatch(upsertCategoryFinished(UpsertCategoryState.ErrorUnknown, category));
        }
      }
    );
  };
}

function upsertCategoryProcessReducer(state = initUpsertCategoryState, action) {
  switch (action.type) {
    case 'UPSERT_CATEGORY_STARTED': {
      const stateUpdate = { state: UpsertCategoryState.InProgress, category: action.category };
      return Object.assign({}, state, stateUpdate);
    }
    case 'UPSERT_CATEGORY_FINISHED': {
      const stateUpdate = { state: action.state };
      return Object.assign({}, state, stateUpdate);
    }
    case 'RESET_UPSERT_CATEGORY_PROCESS': {
      return initUpsertCategoryState;
    }
    default: {
      return state;
    }
  }
}

export const DeleteCategoryState = {
  Idle: 'IDLE',
  InProgress: 'IN_PROGRESS',
  Success: 'SUCCESS',
  ErrorContainsProducts: 'ERROR_CONTAINS_PRODUCTS',
  ErrorNoPermissions: 'ERROR_NO_PERMISSIONS',
  ErrorNotExists: 'ERROR_NOT_EXISTS',
  ErrorUnknown: 'ERROR_UNKNOWN',
};

const initDeleteCategoryState = {
  state: DeleteCategoryState.Idle,
  categoryTag: null,
};

function deleteCategoryStarted(categoryTag) {
  return {
    type: 'DELETE_CATEGORY_STARTED',
    categoryTag,
  };
}

function deleteCategoryFinished(state, categoryTag) {
  return {
    type: 'DELETE_CATEGORY_FINISHED',
    state,
    categoryTag,
  };
}

export function resetDeleteCategoryProcess() {
  return {
    type: 'RESET_DELETE_CATEGORY_PROCESS',
  };
}

export function deleteCategory(tag) {
  return (dispatch, getState, { StoreApi }) => {
    dispatch(deleteCategoryStarted(tag));
    const token = getState().login.session.token;
    return StoreApi.deleteCategory(tag, token).then(
      result => dispatch(deleteCategoryFinished(DeleteCategoryState.Success, tag)),
      error => {
        switch (error) {
          case StoreApi.DeleteCategoryError.Unauthorized:
            dispatch(unauthorized());
            dispatch(deleteCategoryFinished(DeleteCategoryState.ErrorNoPermissions, tag));
            break;
          case StoreApi.DeleteCategoryError.Forbidden:
            dispatch(deleteCategoryFinished(DeleteCategoryState.ErrorNoPermissions, tag));
            break;
          case StoreApi.DeleteCategoryError.ContainsProducts:
            dispatch(deleteCategoryFinished(DeleteCategoryState.ErrorContainsProducts, tag));
            break;
          case StoreApi.DeleteCategoryError.NotExists:
            dispatch(deleteCategoryFinished(DeleteCategoryState.ErrorNotExists, tag));
            break;
          default:
            dispatch(deleteCategoryFinished(DeleteCategoryState.ErrorUnknown, tag));
        }
      }
    );
  };
}

function deleteCategoryProcessReducer(state = initDeleteCategoryState, action) {
  switch (action.type) {
    case 'DELETE_CATEGORY_STARTED': {
      const stateUpdate = { state: SaveProductState.InProgress, categoryTag: action.categoryTag };
      return Object.assign({}, state, stateUpdate);
    }
    case 'DELETE_CATEGORY_FINISHED': {
      const stateUpdate = { state: action.state, product: action.product };
      return Object.assign({}, state, stateUpdate);
    }
    case 'RESET_DELETE_CATEGORY_PROCESS': {
      return initDeleteCategoryState;
    }
    default: {
      return state;
    }
  }
}

const initProduct = {
  index: '',
  name: '',
  description: '',
  inStock: true,
  isNew: true,
  availableSince: null,
  categoryTag: '',
};

export const SaveProductState = {
  Idle: 'IDLE',
  InProgress: 'IN_PROGRESS',
  Success: 'SUCCESS',
  ErrorNotExists: 'ERROR_NOT_EXISTS',
  ErrorNoPermissions: 'ERROR_NO_PERMISSIONS',
  ErrorIndexGroupFull: 'ERROR_INDEX_GROUP_FULL',
  ErrorUnknown: 'ERROR_UNKNOWN'
};

const initSaveProductProcessState = {
  state: SaveProductState.Idle,
  product: null,
};

function updateProduct({ id, ...data }, token, onSuccess, onError) {
  return (dispatch, getState, { StoreApi }) => {
    return StoreApi.updateProduct(id, data, token).then(onSuccess, onError);
  };
}

function canSaveProduct(state) {
  return (
    state.store.saveProductProcess.state !== SaveProductState.InProgress &&
    state.store.uploadProductPhotoProcess.state !== UploadProductPhotoState.InProgress &&
    state.store.deleteProductPhotoProcess.state !== DeleteProductPhotoState.InProgress
  );
}

function saveProductProcessReducer(state = initSaveProductProcessState, action) {
  switch (action.type) {
    case 'SAVE_PRODUCT_STARTED': {
      const stateUpdate = { state: SaveProductState.InProgress };
      return Object.assign({}, state, stateUpdate);
    }
    case 'SAVE_PRODUCT_FINISHED': {
      const stateUpdate = { state: action.state, product: action.product };
      return Object.assign({}, state, stateUpdate);
    }
    case 'RESET_SAVE_PRODUCT_PROCESS': {
      return initSaveProductProcessState;
    }
    default: {
      return state;
    }
  }
}

function saveProductStarted() {
  return {
    type: 'SAVE_PRODUCT_STARTED',
  };
}

function saveProductFinished(state, product = null) {
  return {
    type: 'SAVE_PRODUCT_FINISHED',
    state: state,
    product: product,
  };
}

export function resetSaveProductProcess() {
  return {
    type: 'RESET_SAVE_PRODUCT_PROCESS',
  };
}

export function saveProduct({ id, ...data }) {
  return (dispatch, getState, { StoreApi }) => {
    if (!canSaveProduct(getState())) {
      return;
    }
    dispatch(saveProductStarted());
    const token = getState().login.session.token;
    const promise = id
      ? StoreApi.updateProduct(id, data, token)
      : StoreApi.createProduct(data, token);
    return promise.then(
      response => dispatch(
        saveProductFinished(SaveProductState.Success, response)
      ),
      error => {
        switch (error) {
          case StoreApi.UpdateProductError.Unauthorized:
          case StoreApi.CreateProductError.Unauthorized:
            dispatch(unauthorized());
            dispatch(saveProductFinished(SaveProductState.ErrorNoPermissions));
            break;
          case StoreApi.UpdateProductError.Forbidden:
          case StoreApi.CreateProductError.Forbidden:
            dispatch(saveProductFinished(SaveProductState.ErrorNoPermissions));
            break;
          case StoreApi.CreateProductError.IndexGroupFull:
            dispatch(saveProductFinished(SaveProductState.ErrorIndexGroupFull));
            break;
          case StoreApi.UpdateProductError.NotExists:
            dispatch(saveProductFinished(SaveProductState.ErrorNotExists));
            break;
          default:
            dispatch(saveProductFinished(SaveProductState.ErrorUnknown));
        }
      }
    );
  };
}

export const UploadProductPhotoState = {
  Idle: 'IDLE',
  InProgress: 'IN_PROGRESS',
  Success: 'SUCCESS',
  ErrorUnknown: 'ERROR_UNKNOWN',
  ErrorNoPermissions: 'ERROR_NO_PERMISSIONS',
  ErrorNotExists: 'ERROR_NOT_EXISTS'
};

const initUploadProductPhotoProcessState = {
  state: UploadProductPhotoState.Idle,
  product: null,
};

function uploadProductPhotoProcessReducer(state = initUploadProductPhotoProcessState, action) {
  switch (action.type) {
    case 'UPLOAD_PRODUCT_PHOTO_STARTED': {
      const stateUpdate = { state: UploadProductPhotoState.InProgress };
      return Object.assign({}, state, stateUpdate);
    }
    case 'UPLOAD_PRODUCT_PHOTO_FINISHED': {
      const stateUpdate = { state: action.state, product: action.product };
      return Object.assign({}, state, stateUpdate);
    }
    case 'RESET_UPLOAD_PRODUCT_PHOTO_PROCESS': {
      return initUploadProductPhotoProcessState;
    }
    default: {
      return state;
    }
  }
}

function uploadProductPhotoStarted() {
  return {
    type: 'UPLOAD_PRODUCT_PHOTO_STARTED',
  };
}

function uploadProductPhotoFinished(state, product = null) {
  return {
    type: 'UPLOAD_PRODUCT_PHOTO_FINISHED',
    state: state,
    product: product,
  };
}

export function resetUploadProductPhotoProcess() {
  return {
    type: 'RESET_UPLOAD_PRODUCT_PHOTO_PROCESS',
  };
}

export function uploadProductPhoto(product, file) {
  return (dispatch, getState, { StoreApi }) => {
    if (!canSaveProduct(getState())) {
      return;
    }
    dispatch(uploadProductPhotoStarted());
    const token = getState().login.session.token;
    return StoreApi.requestProductPhotoUploadUrl(product.id, token).then(
      urlResponse => StoreApi.uploadPhoto(urlResponse, file).then(
        response => {
          const url = !!response.secure_url ? response.secure_url : response.url;
          const images = product.images.concat([url]);
          const updated = Object.assign({}, product, { images });
          dispatch(
            updateProduct(
              updated, 
              token,
              success => 
                dispatch(uploadProductPhotoFinished(UploadProductPhotoState.Success, updated)),
              error => {
                if (error === StoreApi.UpdateProductError.Unauthorized) {
                  dispatch(unauthorized);
                }
                dispatch(uploadProductPhotoFinished(UploadProductPhotoState.ErrorUnknown));
              }
            )
          );
        },
        error => dispatch(uploadProductPhotoFinished(UploadProductPhotoState.ErrorUnknown))
      ),
      error => {
        switch (error) {
          case StoreApi.ProductPhotoUploadUrlError.Unauthorized:
            dispatch(unauthorized());
            dispatch(uploadProductPhotoFinished(UploadProductPhotoState.ErrorNoPermissions));
            break;
          case StoreApi.ProductPhotoUploadUrlError.Forbidden:
            dispatch(uploadProductPhotoFinished(UploadProductPhotoState.ErrorNoPermissions));
            break;
          default:
            dispatch(uploadProductPhotoFinished(UploadProductPhotoState.ErrorUnknown));
        }
      }
    );
  };
}

export const DeleteProductPhotoState = {
  Idle: 'IDLE',
  InProgress: 'IN_PROGRESS',
  Success: 'SUCCESS',
  ErrorUnknown: 'ERROR_UNKNOWN',
  ErrorNoPermissions: 'ERROR_NO_PERMISSIONS',
  ErrorNotExists: 'ERROR_NOT_EXISTS'
};

const initDeleteProductPhotoProcessState = {
  state: DeleteProductPhotoState.Idle,
  product: null,
};

function deleteProductPhotoProcessReducer(state = initDeleteProductPhotoProcessState, action) {
  switch (action.type) {
    case 'DELETE_PRODUCT_PHOTO_STARTED': {
      const stateUpdate = { state: DeleteProductPhotoState.InProgress };
      return Object.assign({}, state, stateUpdate);
    }
    case 'DELETE_PRODUCT_PHOTO_FINISHED': {
      const stateUpdate = { state: action.state, product: action.product };
      return Object.assign({}, state, stateUpdate);
    }
    case 'RESET_DELETE_PRODUCT_PHOTO_PROCESS': {
      return initDeleteProductPhotoProcessState;
    }
    default: {
      return state;
    }
  }
}

export function resetDeleteProductPhotoProcess() {
  return {
    type: 'RESET_DELETE_PRODUCT_PHOTO_PROCESS',
  };
}

function deleteProductPhotoStarted() {
  return {
    type: 'DELETE_PRODUCT_PHOTO_STARTED'
  };
}

function deleteProductPhotoFinished(state, product) {
  return {
    type: 'DELETE_PRODUCT_PHOTO_FINISHED',
    state: state,
    product: product,
  };
}

export function deleteProductPhoto(product, photoUrl) {
  return (dispatch, getState, { StoreApi }) => {
    if (!canSaveProduct(getState())) {
      return;
    }
    dispatch(deleteProductPhotoStarted());
    const token = getState().login.session.token;
    const updatedImages = product.images.filter(p => p !== photoUrl);
    const updated = Object.assign({}, product, { images: updatedImages });
    return dispatch(
      updateProduct(
        updated,
        token,
        success => 
          dispatch(deleteProductPhotoFinished(DeleteProductPhotoState.Success, updated)),
        error => {
          switch (error) {
            case StoreApi.UpdateProductError.Unauthorized:
              dispatch(unauthorized());
              dispatch(deleteProductPhotoFinished(DeleteProductPhotoState.ErrorNoPermissions));
              break;
            case StoreApi.UpdateProductError.Forbidden:
              dispatch(deleteProductPhotoFinished(DeleteProductPhotoState.ErrorNoPermissions));
              break;
            case StoreApi.UpdateProductError.NotExists:
              dispatch(deleteProductPhotoFinished(DeleteProductPhotoState.ErrorNotExists));
              break;
            default:
              dispatch(deleteProductPhotoFinished(DeleteProductPhotoState.ErrorUnknown));
          }
        }
      )
    );
  };
}

const initProductForSale = {
  netPrice: 0,
  discountPercent: 0,
  packageNetPrice: 0,
  packageCapacity: 0,
};

export const SaveProductForSaleState = {
  Idle: 'IDLE',
  InProgress: 'IN_PROGRESS',
  Success: 'SUCCESS',
  ErrorNoPermissions: 'ERROR_NO_PERMISSIONS',
  ErrorUnknown: 'ERROR_UNKNOWN'
};

const initSaveProductForSaleProcessState = {
  state: SaveProductForSaleState.Idle,
  price: null,
  postPackageDelivery: null,
  productId: null,
};

function saveProductForSaleProcessReducer(state = initSaveProductForSaleProcessState, action) {
  switch (action.type) {
    case 'SAVE_PRODUCT_FOR_SALE_STARTED': {
      const stateUpdate = { state: SaveProductForSaleState.InProgress };
      return Object.assign({}, state, stateUpdate);
    }
    case 'SAVE_PRODUCT_FOR_SALE_FINISHED': {
      const stateUpdate = { 
        state: action.state, 
        productForSale: action.productForSale, 
      };
      return Object.assign({}, state, stateUpdate);
    }
    case 'RESET_SAVE_PRODUCT_FOR_SALE_PROCESS': {
      return initSaveProductForSaleProcessState;
    }
    default: {
      return state;
    }
  }
}

function saveProductForSaleStarted() {
  return {
    type: 'SAVE_PRODUCT_FOR_SALE_STARTED',
  };
}

function saveProductForSaleFinished(state, productForSale = null) {
  return {
    type: 'SAVE_PRODUCT_FOR_SALE_FINISHED',
    state: state,
    productForSale: productForSale,
  };
}

export function resetSaveProductForSaleProcess() {
  return {
    type: 'RESET_SAVE_PRODUCT_FOR_SALE_PROCESS',
  };
}

export function saveProductForSale(id, { price, postPackageDelivery }) {
  return (dispatch, getState, { WholesaleApi }) => {
    dispatch(saveProductForSaleStarted());
    const token = getState().login.session.token;
    return WholesaleApi.saveProductForSale(id, { price, postPackageDelivery }, token).then(
      response => dispatch(
        saveProductForSaleFinished(SaveProductForSaleState.Success, { id, price, postPackageDelivery })
      ),
      error => {
        switch (error) {
          case WholesaleApi.SaveProductForSaleError.Unauthorized:
            dispatch(unauthorized());
            dispatch(saveProductForSaleFinished(SaveProductForSaleState.ErrorNoPermissions));
            break;
          case WholesaleApi.SavePriceError.Forbidden:
            dispatch(saveProductForSaleFinished(SaveProductForSaleState.ErrorNoPermissions));
            break;
          default:
            dispatch(saveProductForSaleFinished(SaveProductForSaleState.ErrorUnknown));
        }
      }
    );
  };
}

export function storeReducer() {
  return combineReducers({
    productList: productListReducer,
    productDetails: productDetailsReducer,
    productsDelayedFetchRequest: productsDelayedFetchRequestReducer,
    categoryList: categoryListReducer,
    saveProductProcess: saveProductProcessReducer,
    saveProductForSaleProcess: saveProductForSaleProcessReducer,
    uploadProductPhotoProcess: uploadProductPhotoProcessReducer,
    deleteProductPhotoProcess: deleteProductPhotoProcessReducer,
    deleteCategoryProcess: deleteCategoryProcessReducer,
    upsertCategoryProcess: upsertCategoryProcessReducer,
    form: combineForms({
      saveProduct: initProduct,
      saveProductForSale: initProductForSale,
      upsertCategory: initUpsertCategory,
    }, 'store.form'),
  });
}
