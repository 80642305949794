import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { Message, Table } from 'semantic-ui-react';
import './FileDocumentsTable.css';
import { FileDocumentModal } from './FileDocumentModal';
import { FileDocumentDownloadUrlState } from '../../../redux/fileDocuments';

export class FileDocumentsTable extends React.PureComponent {
  static propTypes = {
    fileDocuments: PropTypes.array.isRequired,
    fileDocumentDownloadUrl: PropTypes.object.isRequired,
    fetchFileDocumentDownloadUrl: PropTypes.func.isRequired,
    deleteFileDocument: PropTypes.func,
  }

  state = {
    selectedFileDocument: null,
    isFileDocumentModalOpen: false,
  };

  onFileDocumentSelected = (fileDocument) => {
    this.props.fetchFileDocumentDownloadUrl(fileDocument.uuid);
    this.setState({
      selectedFileDocument: fileDocument,
      isFileDocumentModalOpen: true
    });
  }

  closeFileDocumentModal = () => {
    this.setState({
      selectedFileDocument: null,
      isFileDocumentModalOpen: false
    });
  }

  downloadSelectedFileDocument = () => {
    if (!!this.state.selectedFileDocument) {
      this.props.downloadFileDocument(this.state.selectedFileDocument.uuid);
    }
    this.closeFileDocumentModal();
  }

  deleteSelectedFileDocument = !!this.props.deleteFileDocument
    ? () => {
      if (!!this.state.selectedFileDocument) {
        this.props.deleteFileDocument(this.state.selectedFileDocument.uuid);
      }
      this.closeFileDocumentModal();
    }
    : null;

  render() {
    const { fileDocuments } = this.props;

    const haveFileDocuments = fileDocuments.length > 0;

    const noFileDocumentsMsg = !haveFileDocuments
      ? <Message>Brak dokumentów</Message>
      : null;

    const fileDocumentRows = fileDocuments.map(fileDocument =>
      <Table.Row key={ fileDocument.uuid }
                 className="fileDocumentRow"
                 onClick={ () => this.onFileDocumentSelected(fileDocument) }
                 >
        <Table.Cell>{ fileDocument.name }</Table.Cell>
        <Table.Cell>{ fileDocument.fileName }</Table.Cell>
        <Table.Cell>{ moment(fileDocument.created).format('DD-MM-YYYY, HH:mm') }</Table.Cell>
      </Table.Row>
    );

    const documentsTable = !haveFileDocuments
      ? null
      : <Table singleLine selectable>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Dokument</Table.HeaderCell>
              <Table.HeaderCell>Nazwa pliku</Table.HeaderCell>
              <Table.HeaderCell>Data utworzenia</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            { fileDocumentRows }
          </Table.Body>
        </Table>;

    return (
      <>
        <FileDocumentModal isOpen={ this.state.isFileDocumentModalOpen }
                           close={ this.closeFileDocumentModal }
                           fileDocument={ this.state.selectedFileDocument }
                           deleteFileDocument={ this.deleteSelectedFileDocument }
                           fileDocumentDownloadUrl={ this.props.fileDocumentDownloadUrl }
                           fetchFileDocumentDownloadUrl={ this.props.fetchFileDocumentDownloadUrl }
                           />
        { noFileDocumentsMsg }
        { documentsTable }
      </>
    );
  }
}