import React from 'react';
import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';
import { Container, Grid, Header, Icon, Message } from 'semantic-ui-react';
import { UpdateUserForm } from './UpdateUserForm';
import { UpdateCustomerForm } from './UpdateCustomerForm';
import { ScrollToTopOnMount } from '../../ScrollToTopOnMount';
import { UpdateCustomerState } from '../../../redux/customers';
import { UpdateUserState } from '../../../redux/registration';

class AccountSettingsPure extends React.PureComponent {
  static props = {
    updateUserState: PropTypes.string.isRequired,
    updateCustomerProcess: PropTypes.object.isRequired,
    customer: PropTypes.object,
  }

  render() {
    const isCustomerCase = this.props.customer
      ? <Grid.Row columns={ 2 }>
          <Grid.Column>
            <Header as='h3'>Konto klienta</Header>
            <Container textAlign='left'>
              <UpdateCustomerForm/>
              { this.renderUpdateCustomerSuccess() }
            </Container>
          </Grid.Column>
          <Grid.Column>
            <Header as='h3'>Konto logowania</Header>
            <Container textAlign='left'>
              <UpdateUserForm/>
              { this.renderUpdateUserSuccess() }
            </Container>
          </Grid.Column>
        </Grid.Row>
      : null;

    const notCustomerCase = !this.props.customer
      ? <Grid.Row columns={ 1 }>
          <Grid.Column>
            <Container textAlign='left'>
              <UpdateUserForm/>
              { this.renderUpdateUserSuccess() }
            </Container>
          </Grid.Column>
        </Grid.Row>
      : null;

    return (
      <SettingsContainer wide={ !!this.props.customer }>
        <ScrollToTopOnMount/>
        <Header as='h1' icon textAlign='center'>
          <Icon name='settings' circular/>
          <Header.Content>Twoje konto</Header.Content>
        </Header>
        <Grid divided='vertically' stackable>
          { isCustomerCase }
          { notCustomerCase }
        </Grid>
      </SettingsContainer>
    );
  }

  renderUpdateCustomerSuccess() {
    const process = this.props.updateCustomerProcess;
    const success = 
      this.props.customer &&
      process.state === UpdateCustomerState.Success &&
      this.props.customer.id === process.customer.id;
    if (success) {
      return <Message success>Zapisano</Message>;
    }
    return null;
  }

  renderUpdateUserSuccess() {
    if (this.props.updateUserState === UpdateUserState.Success) {
      return <Message success>Zapisano</Message>;
    }
    return null;
  }
}

const SettingsContainer = (props) => (
  props.wide 
    ? <Container textAlign='center'>{ props.children }</Container>
    : <Container text textAlign='center'>{ props.children }</Container>
);

const mapStateToProps = state => ({
  updateCustomerProcess: state.customers.updateProcess,
  updateUserState: state.registration.updateUserProcess.state,
  customer: state.login.session.customer,
});

export const AccountSettings =
  connect(mapStateToProps)(AccountSettingsPure);
