import { combineForms } from 'react-redux-form';
import { combineReducers } from 'redux';
import { unauthorized } from './login';
import * as R from 'ramda';

const initCustomer = {
  firstName: '',
  lastName: '',
  phoneNumber: '',
  companyName: '',
  companyNip: '',
  additionalInfo: ''
};

export const CustomerRegistrationState = {
  Idle: 'IDLE',
  InProgress: 'IN_PROGRESS',
  Success: 'SUCCESS',
  ErrorAlreadyRegistered: 'ERROR_ALREADY_REGISTERED',
  ErrorUnauthorized: 'ERROR_UNAUTHORIZED',
  ErrorUnknown: 'ERROR_UNKNOWN'
};

const initRegistrationProcessState = {
  state: CustomerRegistrationState.Idle,
};

function registrationProcessReducer(state = initRegistrationProcessState, action) {
  switch (action.type) {
    case 'CUSTOMER_REGISTRATION_STARTED': {
      const stateUpdate = { state: CustomerRegistrationState.InProgress };
      return Object.assign({}, state, stateUpdate);
    }
    case 'CUSTOMER_REGISTRATION_FINISHED': {
      const stateUpdate = { state: action.state };
      return Object.assign({}, state, stateUpdate);
    }
    default: {
      return state;
    }
  }
}

function customerRegistrationStarted() {
  return {
    type: 'CUSTOMER_REGISTRATION_STARTED'
  };
}

function customerRegistrationFinished(state, customer) {
  return {
    type: 'CUSTOMER_REGISTRATION_FINISHED',
    state: state,
    customer: customer,
  };
}

export function register(customer) {
  return (dispatch, getState, { WholesaleApi }) => {
    dispatch(customerRegistrationStarted());
    const token = getState().login.session.token;
    return WholesaleApi.registerCustomer(customer, token).then(
      response => dispatch(
        customerRegistrationFinished(CustomerRegistrationState.Success, response)
      ),
      error => {
        switch (error) {
          case WholesaleApi.RegisterCustomerError.Unauthorized:
            dispatch(unauthorized());
            dispatch(customerRegistrationFinished(CustomerRegistrationState.ErrorUnauthorized));
            break;
          case WholesaleApi.RegisterCustomerError.AlreadyRegistered:
            dispatch(customerRegistrationFinished(CustomerRegistrationState.ErrorAlreadyRegistered));
            break;
          default:
            dispatch(customerRegistrationFinished(CustomerRegistrationState.ErrorUnknown));
        }
      }
    );
  };
}

export const UpdateCustomerState = {
  Idle: 'IDLE',
  InProgress: 'IN_PROGRESS',
  Success: 'SUCCESS',
  ErrorNotExists: 'ERROR_NOT_EXISTS',
  ErrorNoPermissions: 'ERROR_NO_PERMISSIONS',
  ErrorUnknown: 'ERROR_UNKNOWN'
};

const initUpdateProcessState = {
  state: UpdateCustomerState.Idle,
  customer: null,
  customerId: null,
};

function updateProcessReducer(state = initUpdateProcessState, action) {
  switch (action.type) {
    case 'UPDATE_CUSTOMER_STARTED': {
      const stateUpdate = { state: UpdateCustomerState.InProgress, customerId: action.customerId };
      return Object.assign({}, state, stateUpdate);
    }
    case 'UPDATE_CUSTOMER_FINISHED': {
      const stateUpdate = { state: action.state, customer: action.customer };
      return Object.assign({}, state, stateUpdate);
    }
    case 'RESET_UPDATE_CUSTOMER_PROCESS': {
      return initUpdateProcessState;
    }
    default: {
      return state;
    }
  }
}

function updateCustomerStarted(customerId) {
  return {
    type: 'UPDATE_CUSTOMER_STARTED',
    customerId: customerId,
  };
}

function updateCustomerFinished(state, customer) {
  return {
    type: 'UPDATE_CUSTOMER_FINISHED',
    state: state,
    customer: customer,
  };
}

export function resetUpdateCustomerProcess() {
  return {
    type: 'RESET_UPDATE_CUSTOMER_PROCESS',
  };
}

export function updateCustomer(id, data) {
  return (dispatch, getState, { WholesaleApi }) => {
    dispatch(updateCustomerStarted(id));
    const token = getState().login.session.token;
    return WholesaleApi.updateCustomer(id, data, token).then(
      response => dispatch(
        updateCustomerFinished(UpdateCustomerState.Success, response)
      ),
      error => {
        switch (error) {
          case WholesaleApi.UpdateCustomerError.Unauthorized:
            dispatch(unauthorized());
            dispatch(updateCustomerFinished(UpdateCustomerState.ErrorNoPermissions));
            break;
          case WholesaleApi.UpdateCustomerError.Forbidden:
            dispatch(updateCustomerFinished(UpdateCustomerState.ErrorNoPermissions));
            break;
          case WholesaleApi.UpdateCustomerError.NotExists:
            dispatch(updateCustomerFinished(UpdateCustomerState.ErrorNotExists));
            break;
          default:
            dispatch(updateCustomerFinished(UpdateCustomerState.ErrorUnknown));
        }
      }
    );
  };
}

const DefaultFetchDelayMs = 500;

const initCustomersDelayedFetchRequest = {
  requestNumber: 0,
  searchQuery: "",
};

function customersDelayedFetchRequestReducer(state = initCustomersDelayedFetchRequest, action) {
  switch (action.type) {
    case 'NEXT_CUSTOMERS_DELAYED_FETCH_REQUEST': {
      const stateUpdate = { requestNumber: action.requestNumber, searchQuery: action.searchQuery };
      return Object.assign({}, state, stateUpdate);
    }
    case 'FETCHING_CUSTOMERS_STARTED': {
      if (action.searchQuery !== state.searchQuery) {
        return { requestNumber: state.requestNumber + 1, searchQuery: action.searchQuery };
      }
      return state;
    }
    default:
      return state;
  }
}

function nextCustomersDelayedFetchRequest(requestNumber, searchQuery) {
  return {
    type: 'NEXT_CUSTOMERS_DELAYED_FETCH_REQUEST',
    requestNumber: requestNumber,
    searchQuery: searchQuery,
  };
}

export function requestCustomersDelayedFetch({ 
    searchQuery, 
    isActive,
    take,
    delayMs = DefaultFetchDelayMs }) {
  return (dispatch, getState) => {
    const nextRequestNumber = getState().customers.customersDelayedFetchRequest.requestNumber + 1;
    dispatch(nextCustomersDelayedFetchRequest(nextRequestNumber, searchQuery));
    setTimeout(() => dispatch(
      maybeFetchCustomers(nextRequestNumber, searchQuery, isActive, take)
    ), delayMs);
  };
}

function maybeFetchCustomers(requestNumber, searchQuery, isActive, take) {
  return (dispatch, getState) => {
    const currentRequestNumber = getState().customers.customersDelayedFetchRequest.requestNumber;
    if (currentRequestNumber === requestNumber) {
      dispatch(fetchCustomers({ take, searchQuery, isActive }));
    }
  };
}

export const CustomerListState = {
  Idle: 'IDLE',
  Fetching: 'FETCHING',
  FetchingMore: 'FETCHING_MORE',
  ErrorNoPermissions: 'ERROR_NO_PERMISSIONS',
};

const initCustomerListState = {
  customers: [],
  searchQuery: "",
  isActive: null,
  take: null,
  state: CustomerListState.Idle,
};

function customerListReducer(state = initCustomerListState, action) {
  switch (action.type) {
    case 'FETCHING_CUSTOMERS_STARTED': {
      const stateUpdate = { 
        state: action.state,
        searchQuery: action.searchQuery,
        isActive: action.isActive,
      };
      return Object.assign({}, state, stateUpdate);
    }
    case 'FETCHING_CUSTOMERS_FINISHED': {
      const customers = action.state === CustomerListState.Idle && action.append
        ? state.customers.concat(action.customers) 
        : action.customers;
      const take = action.state === CustomerListState.Idle && action.append
        ? state.take + action.take
        : action.take;
      const stateUpdate = { 
        state: action.state, 
        customers,
        searchQuery: action.searchQuery,
        isActive: action.isActive,
        take
      };
      return Object.assign({}, state, stateUpdate);
    }
    case 'UPDATE_CUSTOMER_FINISHED': {
      if (action.state === UpdateCustomerState.Success) {
        const customer = action.customer;
        const idx = R.findIndex(R.propEq('id', customer.id))(state.customers);
        if (idx !== -1) {
          const customers = R.update(idx, action.customer)(state.customers);
          return Object.assign({}, state, { customers });
        }
      }
      return state;
    }
    default: {
      return state;
    }
  }
}

function fetchingCustomersStarted(searchQuery, isActive, append) {
  return {
    type: 'FETCHING_CUSTOMERS_STARTED',
    searchQuery: searchQuery,
    isActive: isActive,
    state: append ? CustomerListState.FetchingMore : CustomerListState.Fetching,
  };
}

function fetchingCustomersFinished(state, 
                                   customers = [], 
                                   searchQuery = "",
                                   isActive = false,
                                   take = null,
                                   append = false) {
  return {
    type: 'FETCHING_CUSTOMERS_FINISHED',
    state: state,
    customers: customers,
    searchQuery: searchQuery,
    isActive: isActive,
    take: take,
    append: append,
  };
}

export 
function fetchCustomers({
    take,
    searchQuery = "",
    isActive = false }) {
  return (dispatch, getState) => {
    const customerList = getState().customers.customerList;

    if (customerList.state === CustomerListState.Fetching || 
        customerList.state === CustomerListState.FetchingMore) {
      return;
    }

    const sameQuery = customerList.searchQuery === searchQuery 
                   && customerList.isActive === isActive;

    if (sameQuery && take <= customerList.take) {
      return dispatch(
        fetchingCustomersFinished(
          CustomerListState.Idle,
          customerList.customers.slice(0, take), 
          searchQuery, 
          isActive,
          take,
          false
        )
      );
    } else if (sameQuery && customerList.take !== null) {
      return dispatch(fetchMoreCustomers(take - customerList.take));
    }
    return dispatch(
      doFetchCustomers(searchQuery, isActive, take)
    );
  };
}

function fetchMoreCustomers(take) {
  return (dispatch, getState) => {
    const customerList = getState().customers.customerList;
    const customers = customerList.customers;
    const lastId = customers.length > 0 ? customers[customers.length - 1].id : null;
    return dispatch(
      doFetchCustomers(
        customerList.searchQuery,
        customerList.isActive,
        take,
        "desc",
        lastId,
        true,
      )
    );
  };
}

function doFetchCustomers(searchQuery, isActive, take, sortOrder, lastId, append) {
  return (dispatch, getState, { WholesaleApi }) => {
    dispatch(fetchingCustomersStarted(searchQuery, isActive, append));
    const token = getState().login.session.token;
    return WholesaleApi.customers({ searchQuery, isActive, take, lastId }, token).then(
      customers => dispatch(
        fetchingCustomersFinished(
          CustomerListState.Idle, customers, searchQuery, isActive, take, append
        )
      ),
      error => {
        switch (error) {
          case WholesaleApi.CustomersError.Unauthorized:
            dispatch(fetchingCustomersFinished(CustomerListState.ErrorNoPermissions));
            dispatch(unauthorized());
            break;
          case WholesaleApi.CustomersError.Forbidden:
            dispatch(fetchingCustomersFinished(CustomerListState.ErrorNoPermissions));
            break;

          default:
            dispatch(fetchingCustomersFinished(CustomerListState.ErrorUnknown));
        }
      }
    );
  };
}

export const CustomerDetailsState = {
  Idle: 'IDLE',
  Fetching: 'FETCHING',
  ErrorNotExists: 'NOT_EXISTS',
  ErrorNoPermissions: 'ERROR_NO_PERMISSIONS',
  ErrorUnknown: 'ERROR_UNKNOWN',
};

const initCustomerDetailsState = {
  customer: null,
  state: CustomerDetailsState.Idle,
};

function customerDetailsReducer(state = initCustomerDetailsState, action) {
  switch (action.type) {
    case 'FETCHING_CUSTOMER_STARTED': {
      const stateUpdate = { state: CustomerDetailsState.Fetching };
      return Object.assign({}, state, stateUpdate);
    }
    case 'FETCHING_CUSTOMER_FINISHED': {
      const stateUpdate = { 
        state: action.state, 
        customer: action.customer,
      };
      return Object.assign({}, state, stateUpdate);
    }
    case 'UPDATE_CUSTOMER_FINISHED': {
      if (action.state === UpdateCustomerState.Success &&
          !!state.customer &&
          action.customer.id === state.customer.id) {
        const stateUpdate = { customer: action.customer };
        return Object.assign({}, state, stateUpdate);
      }
      return state;
    }
    default: {
      return state;
    }
  }
}

function fetchingCustomerStarted() {
  return {
    type: 'FETCHING_CUSTOMER_STARTED',
  };
}

function fetchingCustomerFinished(state, customer = null) {
  return {
    type: 'FETCHING_CUSTOMER_FINISHED',
    state: state,
    customer: customer,
  };
}

export function fetchCustomer(id) {
  return (dispatch, getState, { WholesaleApi }) => {
    dispatch(fetchingCustomerStarted());
    const token = getState().login.session.token;
    return WholesaleApi.customer(id, token).then(
      customer => dispatch(fetchingCustomerFinished(CustomerDetailsState.Idle, customer)),
      error => {
        switch (error) {
          case WholesaleApi.CustomerError.Unauthorized:
            dispatch(fetchingCustomerFinished(CustomerDetailsState.ErrorNoPermissions));
            dispatch(unauthorized());
            break;
          case WholesaleApi.CustomerError.Forbidden:
            dispatch(fetchingCustomerFinished(CustomerDetailsState.ErrorNoPermissions));
            break;
          case WholesaleApi.CustomerError.NotExists:
            dispatch(fetchingCustomerFinished(CustomerDetailsState.ErrorNotExists));
            break;
          default:
            dispatch(fetchingCustomerFinished(CustomerListState.ErrorUnknown));
        }
      }
    );
  };
}

const initEmailToCustomers = {
  subject: '',
  body: '',
};

export const SendEmailToCustomersState = {
  Idle: 'IDLE',
  Sending: 'SENDING',
  Success: 'SUCCESS',
  ErrorNoPermissions: 'ERROR_NO_PERMISSIONS',
  ErrorUnknown: 'ERROR_UNKNOWN',
};

const initSendEmailToCustomersState = {
  state: SendEmailToCustomersState.Idle,
};

function emailToCustomersReducer(state = initSendEmailToCustomersState, action) {
  switch (action.type) {
    case 'SENDING_EMAIL_TO_CUSTOMERS_STARTED': {
      const stateUpdate = { state: SendEmailToCustomersState.Sending };
      return Object.assign({}, state, stateUpdate);
    }
    case 'SENDING_EMAIL_TO_CUSTOMERS_FINISHED': {
      const stateUpdate = { state: action.state };
      return Object.assign({}, state, stateUpdate);
    }
    default: {
      return state;
    }
  }
}

function sendingEmailToCustomersStarted() {
  return {
    type: 'SENDING_EMAIL_TO_CUSTOMERS_STARTED',
  };
}

function sendingEmailToCustomersFinished(state) {
  return {
    type: 'SENDING_EMAIL_TO_CUSTOMERS_FINISHED',
    state: state,
  };
}

export function sendEmailToCustomers({ subject, body }) {
  return (dispatch, getState, { WholesaleApi }) => {
    dispatch(sendingEmailToCustomersStarted());
    const token = getState().login.session.token;
    return WholesaleApi.sendEmailToCustomers({ subject, body }, token).then(
      _ => { 
        dispatch(sendingEmailToCustomersFinished(SendEmailToCustomersState.Success));
        return Promise.resolve();
      },
      error => {
        switch (error) {
          case WholesaleApi.SendEmailToCustomersError.Unauthorized:
            dispatch(sendingEmailToCustomersFinished(SendEmailToCustomersState.ErrorNoPermissions));
            dispatch(unauthorized());
            break;
          case WholesaleApi.SendEmailToCustomersError.Forbidden:
            dispatch(sendingEmailToCustomersFinished(SendEmailToCustomersState.ErrorNoPermissions));
            break;
          default:
            dispatch(sendingEmailToCustomersFinished(SendEmailToCustomersState.ErrorUnknown));
        }
        return Promise.reject();
      }
    );
  };
}

export function customersReducer() {
  return combineReducers({
    form: combineForms({
      registerCustomer: initCustomer,
      updateCustomer: initCustomer,
      customersEmail: initEmailToCustomers,
    }, 'customers.form'),
    registrationProcess: registrationProcessReducer,
    updateProcess: updateProcessReducer,
    customerList: customerListReducer,
    customerDetails: customerDetailsReducer,
    customersDelayedFetchRequest: customersDelayedFetchRequestReducer,
    sendEmailToCustomersProcess: emailToCustomersReducer,
  });
}
