import React from 'react';
import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Container, Header, Icon, Loader, Message, Segment, Table } from 'semantic-ui-react';
import { Messages } from "../../../Messages"; 
import { ScrollToTopOnMount } from '../../../ScrollToTopOnMount';
import { SimpleText } from '../../../SimpleText';
import { fetchCustomer, CustomerDetailsState  } from '../../../../redux/customers.js';
import { OrdersList } from '../../Orders/OrdersList';
import { fetchOrderSummaries } from '../../../../redux/orders.js';

class CustomerPure extends React.PureComponent {
  static props = {
    match: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    orderSummaryList: PropTypes.object.isRequired,
    customerDetails: PropTypes.object.isRequired,
    fetchCustomer: PropTypes.func.isRequired,
    fetchOrderSummaries: PropTypes.func.isRequired,
  }

  orderSelected = (orderId) => {
    const redirectUrl = '/admin/orders/' + orderId;
    this.props.history.push(redirectUrl);
  }

  componentDidMount() {
    if (this.props.match.params.id) {
      this.props.fetchCustomer(this.props.match.params.id);
    }
  }

  render() {
    const customerId = this.props.match.params.id;
    const { customerDetails } = this.props;
    const { state } = customerDetails;
    const customer = state === CustomerDetailsState.Idle ? customerDetails.customer : null;

    const loaderView = state === CustomerDetailsState.Fetching 
      ? <Segment basic>
          <Loader active={ true }/>
        </Segment>
      : null;

    const notExistsView = state === CustomerDetailsState.ErrorNotExists 
      ? <Message negative>
          <Message.Header>Błąd</Message.Header>
          Klient nie istnieje.
        </Message>
      : null;

    const noPermissionsMessage = state === CustomerDetailsState.ErrorNoPermissions
      ? Messages.NoPermissions 
      : null;

    const unknownErrorMessageView = state === CustomerDetailsState.ErrorUnknown
      ? Messages.UnknownError 
      : null;

    const customerInfo = customer
      ? customer.company.name
      : "Klient nr " + customerId;

    const customerAdditionalInfoRow = customer && customer.additionalInfo
      ? <Table.Row>
          <Table.Cell width={ 2 }><b>Informacja</b></Table.Cell>
          <Table.Cell>
            <SimpleText text={ customer.additionalInfo }/>
          </Table.Cell>
        </Table.Row>
      : null;

    const customerDetailsView = customer
      ? <div>
          <Segment basic>
            <Link to={ `/admin/customers/${customerId}/edit` } className="ui button">
              <Icon name='edit'/>
               Edytuj
            </Link>
            <Table basic='very'>
              <Table.Body>
                <Table.Row>
                  <Table.Cell width={ 2 }><b>Numer telefonu</b></Table.Cell>
                  <Table.Cell>{ customer.phoneNumber }</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell width={ 2 }><b>Email</b></Table.Cell>
                  <Table.Cell>{ customer.emailAddress }</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell width={ 2 }><b>NIP firmy</b></Table.Cell>
                  <Table.Cell>{ customer.company.nip }</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell width={ 2 }><b>Imię</b></Table.Cell>
                  <Table.Cell>{ customer.firstName }</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell width={ 2 }><b>Nazwisko</b></Table.Cell>
                  <Table.Cell>{ customer.lastName }</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell width={ 2 }><b>Zniżka ogólna</b></Table.Cell>
                  <Table.Cell>{ customer.discountPercent }%</Table.Cell>
                </Table.Row>
                { customerAdditionalInfoRow }
              </Table.Body>
            </Table>
          </Segment>
        </div>
      : null;

    const ordersList = customer
      ? <Segment basic>
          <Header as='h2'>Zamówienia</Header>
          <OrdersList fetchOrderSummaries={ this.props.fetchOrderSummaries }
                        orderSummaryList={ this.props.orderSummaryList }
                        customerId={ Number(customerId) }
                        onOrderSelected={ this.orderSelected }
                        />
        </Segment>
      : null;


    return (
      <Container>
        <ScrollToTopOnMount/>
        <Header as='h1' icon textAlign='center'>
          <Icon name='address book' circular/>
          <Header.Content>{ customerInfo }</Header.Content>
        </Header>
        { notExistsView }
        { unknownErrorMessageView }
        { noPermissionsMessage }
        { loaderView }
        { customerDetailsView }
        { ordersList }
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  customerDetails: state.customers.customerDetails,
  orderSummaryList: state.orders.orderSummaryList,
});

const mapDispatchToProps = dispatch => ({
  fetchCustomer: (id) => dispatch(fetchCustomer(id)),
  fetchOrderSummaries: (take, customerId, ordersState) => 
    dispatch(fetchOrderSummaries({ take, customerId, ordersState })),
});

export const Customer =
  connect(mapStateToProps, mapDispatchToProps)(CustomerPure);
