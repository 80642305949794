import Config from '../config';
import { put, post, url } from './ApiClient';

function authUrl(path, params) {
  return url(Config.endpoints.auth, path, params);
}

const LoginError = {
  InvalidCredentials: 'INVALID_CREDENTIALS',
  InactiveUser: 'INACTIVE_USER',
  Unknown: 'UNKNOWN',
};

const User = {
  isAdmin: function() { return this.groups.indexOf('admin') !== -1; },
};

function login({emailAddress, password}) {
  return post(authUrl("tokens"), { emailAddress, password }).then(
    response => {
      const user = Object.assign(Object.create(User), response.user);
      return { token: response.token, user };
    },
    error => {
      switch (error.statusText) {
        case 'Unauthorized':
          return Promise.reject(LoginError.InvalidCredentials);
        case 'Forbidden':
          return Promise.reject(LoginError.InactiveUser);
        default:
          return Promise.reject(LoginError.ErrorUnknown);
      }
    }
  );
}

const RegisterError = {
  EmailTaken: 'EMAIL_TAKEN',
  WrongPassword: 'WRONG_PASSWORD',
  Unknown: 'UNKNOWN',
};

function register({ emailAddress, password, firstName, lastName }) {
  const emailConfirmationUrlPrefix = Config.emailConfirmationUrlPrefix;
  const data = { emailAddress, password, firstName, lastName, emailConfirmationUrlPrefix };
  return post(authUrl("users"), data)
    .then(
      response => response,
      error => {
        switch (error.statusText) {
          case 'Bad Request':
            return Promise.reject(RegisterError.WrongPassword);
          case 'Conflict':
            return Promise.reject(RegisterError.EmailTaken);
          default:
            return Promise.reject(RegisterError.Unknown);
        }
      }
    );
}

const UpdateUserError = {
  WrongPassword: 'WRONG_PASSWORD',
  NotExists: 'NOT_EXISTS',
  Unauthorized: 'UNAUTHORIZED',
  Forbidden: 'FORBIDDEN',
  Unknown: 'UNKNOWN',
};

function updateUser(emailAddress, { password, firstName, lastName }, token) {
  const data = { password, firstName, lastName };
  return put(authUrl(`users/${emailAddress}`), data, token)
    .then(
      response => response,
      error => {
        switch (error.statusText) {
          case 'Bad Request':
            return Promise.reject(UpdateUserError.WrongPassword);
          case 'Not Found':
            return Promise.reject(UpdateUserError.NotExists);
          case 'Unauthorized':
            return Promise.reject(UpdateUserError.Unauthorized);
          case 'Forbidden':
            return Promise.reject(UpdateUserError.Forbidden);
          default:
            return Promise.reject(UpdateUserError.Unknown);
        }
      }
    );
}

const InitPasswordRecoveryError = {
  Unknown: 'UNKNOWN',
};

function initPasswordRecovery({ emailAddress, recoveryUrlPrefix }) {
  return post(authUrl(`users/${emailAddress}/passwords`), {recoveryUrlPrefix}).then(
    response => response,
    error => Promise.reject(InitPasswordRecoveryError.Unknown)
  );
}

const FinalizePasswordRecoveryError = {
  UnknownUuid: 'UNKNOWN_UUID',
  WrongPassword: 'WRONG_PASSWORD',
  Unknown: 'UNKNOWN',
};

function finalizePasswordRecovery({ emailAddress, uuid, password }) {
  return put(authUrl(`users/${emailAddress}/passwords/${uuid}`), {password}).then(
    response => response,
    error => {
      switch (error.statusText) {
        case 'Not Found':
          return Promise.reject(FinalizePasswordRecoveryError.UnknownUuid);
        case 'Bad Request':
          return Promise.reject(FinalizePasswordRecoveryError.WrongPassword);
        default:
          return Promise.reject(FinalizePasswordRecoveryError.Unknown);
      }
    }
  );
}

const ConfirmEmailAddressError = {
  IllegalToken: 'ILLEGAL_TOKEN',
  AlreadyConfirmed: 'ALREADY_CONFIRMED',
  Unknown: 'UNKNOWN'
};

function confirmEmailAddress(token) {
  return put(authUrl(`users/confirmations/${token}`)).then(
    response => response,
    error => {
      switch (error.statusText) {
        case 'Conflict':
          return Promise.reject(ConfirmEmailAddressError.AlreadyConfirmed);
        case 'Forbidden':
          return Promise.reject(ConfirmEmailAddressError.IllegalToken);
        default:
          return Promise.reject(ConfirmEmailAddressError.Unknown);
      }
    }
  );
}

const AuthApi = {
  LoginError,
  login,
  RegisterError,
  register,
  InitPasswordRecoveryError,
  initPasswordRecovery,
  FinalizePasswordRecoveryError,
  finalizePasswordRecovery,
  ConfirmEmailAddressError,
  confirmEmailAddress,
  User,
  UpdateUserError,
  updateUser,
};

export default AuthApi;
