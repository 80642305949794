import React from 'react';
import PropTypes from 'prop-types';
import { Button, Icon } from 'semantic-ui-react';

export class NewOrderPlainButton extends React.PureComponent {
  static propTypes = {
    disabled: PropTypes.bool.isRequired,
    fluid: PropTypes.bool,
    history: PropTypes.object.isRequired,
    attached: PropTypes.string,
    compact: PropTypes.bool,
    size: PropTypes.string,
  }

  placeNewOrder = () => {
    this.props.history.push('/new-order');
  }

  render() {
    return (
      <Button onClick={ this.placeNewOrder }
              fluid={ this.props.fluid }
              color='red'
              size={ this.props.size }
              compact={ this.props.compact }
              attached={ this.props.attached }
              disabled={ this.props.disabled }
              className="plainOrderButton"
              >
        <Icon name='truck'/>
        ZAMÓW
      </Button>
    );
  }
}
