import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { PrivateRoute } from '../PrivateRoute';
import { ApiInfo } from './ApiInfo';

export class ApiRouter extends React.PureComponent {
  defaultRedirect = () => <Redirect to='/'/>;
  render() {
    return (
      <Switch>
        <PrivateRoute exact path='/public-api-info' component={ ApiInfo }/>
        <Route path='/' render={ this.defaultRedirect }/>
      </Switch>
    );
  }
}
