import React from 'react';
import PropTypes from 'prop-types';
import { actions, Control, Errors, LocalForm } from 'react-redux-form';
import { Divider, Form, Modal } from 'semantic-ui-react';
import { roundPriceValue } from '../../../../utils/price';
import { isCountValid, isPriceValid } from '../../../../utils/common';

export class OrderedProductEditModal extends React.PureComponent {
  static propTypes = {
    product: PropTypes.object.isRequired,
    onClose: PropTypes.func.isRequired,
    edit: PropTypes.func.isRequired,
  }

  state = { withPostDelivery: false }
  constructor(props) {
    super(props);
    this.state = { 
      withPostDelivery: props.postDeliveryNetPriceValue !== undefined,
      count: props.count,
    };
  }

  attachDispatch = (dispatch) => {
    this.formDispatch = dispatch;
  }

  handleUpdate = (form) => {
    if (form.count.focus) {
      const count = Number(form.count.value);
      if (form.count.valid && !isNaN(count)) {
        if (count !== this.state.count) {
          this.setState({ count });
        }
        const itemPrice = form.count.initialValue > 0 
          ? form.totalNetPriceValue.initialValue / form.count.initialValue
          : 0;
        const currentPrice = roundPriceValue(count * itemPrice);
        if (currentPrice !== form.totalNetPriceValue.value) {
          this.formDispatch(actions.change('product.totalNetPriceValue', currentPrice));
        }
        if (count === 0 && form.withPostDelivery.value) {
          this.formDispatch(actions.change('product.postDeliveryNetPriceValue', 0));
          this.formDispatch(actions.change('product.withPostDelivery', false));
        }
        if (count > 0 && !form.withPostDelivery.value && form.withPostDelivery.initialValue) {
          const deliveryPrice = form.postDeliveryNetPriceValue.initialValue;
          this.formDispatch(actions.change('product.postDeliveryNetPriceValue', deliveryPrice));
          this.formDispatch(actions.change('product.withPostDelivery', form.withPostDelivery.initialValue));
        }
      }
    } 
    const withPostDelivery = form.withPostDelivery.value;
    if (this.state.withPostDelivery !== withPostDelivery) {
      this.setState({ withPostDelivery });
    }
  }

  handleSubmit = (productId, result) => {
    const data = {
      productId,
      count: Number(result.count),
      totalNetPriceValue: Number(result.totalNetPriceValue),
      postDeliveryNetPriceValue: result.withPostDelivery && result.count > 0
        ? Number(result.postDeliveryNetPriceValue) : undefined,
    };
    this.props.edit(data);
    this.props.onClose();
  };

  render() {
    const { product } = this.props;
    const { count, postDeliveryNetPriceValue, totalNetPriceValue } = product;

    const visiblePostDeliveryNetPriceValue = postDeliveryNetPriceValue !== undefined
      ? roundPriceValue(postDeliveryNetPriceValue) : 0;

    const initialState = { 
      count, 
      totalNetPriceValue: roundPriceValue(totalNetPriceValue), 
      postDeliveryNetPriceValue: visiblePostDeliveryNetPriceValue,
      withPostDelivery: postDeliveryNetPriceValue !== undefined,
    };

    return (
      <Modal width='8' 
             size='small' 
             open={ true }
             onClose={ this.props.onClose }>
        <Modal.Header>{ product.index + ` (${product.name})`}</Modal.Header>
        <Modal.Content>
          <LocalForm className='ui form'
                     model='product'
                     onSubmit={ (result) => this.handleSubmit(product.productId, result) } 
                     onUpdate={ this.handleUpdate }
                     getDispatch= { (dispatch) => this.attachDispatch(dispatch )}
                     initialState={ initialState }>
            <Form.Field>
              <label>Sztuk</label>
              <Control.text model='.count' validators={{ isCountValid }}/>
              <Errors
                className="errors"
                model=".count"
                show="touched"
                messages={{
                  isCountValid: 'Niepoprawna wartość',
                }}
              />
            </Form.Field>
            <Form.Field>
              <label>Cena za towar (netto PLN)</label>
              <Control.text model='.totalNetPriceValue'
                            validators={{ isPriceValid }}
                            />
              <Errors
                className="errors"
                model=".totalNetPriceValue"
                show="touched"
                messages={{
                  isPriceValid: 'Niepoprawna wartość',
                }}
              />
            </Form.Field>
            <Form.Field disabled={ !this.state.withPostDelivery }>
              <label>Cena za dostawę 24h (netto PLN)</label>
              <Control.text model='.postDeliveryNetPriceValue'
                            validators={{ isPriceValid }}
                            />
              <Errors
                className="errors"
                model=".postDeliveryNetPriceValue"
                show="touched"
                messages={{
                  isPriceValid: 'Niepoprawna wartość',
                }}
              />
            </Form.Field>
            <div className='ui checkbox'>
              <Control.checkbox disabled={ this.state.count === 0 } model='.withPostDelivery'/>
              <label>Dostawa 24h</label>
            </div>
            <Divider/>
            <button type="submit" className="ui button positive fluid">
              OK
            </button>
          </LocalForm>
        </Modal.Content>
      </Modal>
    );
  }
}
