import { PropTypes } from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { actions, Control, Errors, Form } from 'react-redux-form';
import { deleteActiveAnnouncement, DeleteActiveAnnouncementState, newActiveAnnouncement, NewActiveAnnouncementState } from '../../../../redux/announcements';
import { required } from '../../../../utils/common';
import { Messages } from '../../../Messages';

const maxAnnouncementLength = 1024;

class ReduxNewActiveAnnouncementForm extends React.PureComponent {

  static props = {
    activeAnnouncement: PropTypes.object.isRequired,
    newActiveAnnouncementState: PropTypes.object.isRequired,
  }

  componentDidMount() {
    if (!!this.props.activeAnnouncement.id) {
      this.fillFormData();
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.activeAnnouncement !== this.props.activeAnnouncement) {
      this.fillFormData();
    }
  }

  fillFormData = () =>
    this.props.dispatch(actions.change('announcements.form.announcement', this.props.activeAnnouncement));

  newActiveAnnouncementError = () => {
    switch (this.props.newActiveAnnouncementState) {
      case NewActiveAnnouncementState.ErrorNoPermissions:
        return Messages.NoPermissions;
      case NewActiveAnnouncementState.ErrorUnknown:
        return Messages.UnknownError;
      default:
        return null;
    }
  }

  handleSubmit = (announcement) =>
    this.props.dispatch(newActiveAnnouncement(announcement))

  handleDelete = () => {
    if (!!this.props.activeAnnouncement.id) {
      this.props.dispatch(deleteActiveAnnouncement(this.props.activeAnnouncement.id));
    }
  }

  render() {
    const formClassName =
      (
        (this.props.newActiveAnnouncementState === NewActiveAnnouncementState.Creating
         || this.props.deleteActiveAnnouncementState === DeleteActiveAnnouncementState.InProgress
        ) ? "loading " : ""
      ) + "ui form";

    return (
      <Form
        model="announcements.form.announcement"
        onSubmit={ this.handleSubmit }
        className={ formClassName }
      >
        <div className="field">
          <Control.textarea
            model=".text" 
            placeholder="Tekst ogłoszenia"
            validators={{ required, maxLength: (text) => text.length <= maxAnnouncementLength }}
          />
          <Errors
            className="error"
            model=".text"
            show="touched"
            messages={{
              required: 'Wymagane',
              maxLength: 'Maksymalna długość ogłoszenia to ' + maxAnnouncementLength + ' znaki.',
            }}
          />
        </div>

        <button className="ui button primary" type="submit">
          Aktualizuj
        </button>

        <button className="ui button" onClick={ this.handleDelete } type="button">
          Usuń ogłoszenie
        </button>

        { this.newActiveAnnouncementError() }
      </Form>
    );
  }
}

const mapStateToProps = state => ({
  activeAnnouncement: state.announcements.activeAnnouncement.announcement,
  newActiveAnnouncementState: state.announcements.newActiveAnnouncement.state,
  deleteActiveAnnouncementState: state.announcements.deleteActiveAnnouncement.state,
});

export const NewActiveAnnouncementForm =
  connect(mapStateToProps)(ReduxNewActiveAnnouncementForm);
