import React from 'react';
import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';
import { Control, Errors, Form } from 'react-redux-form';
import { actions } from 'react-redux-form';
import { Message } from 'semantic-ui-react';
import { Messages } from '../../Messages';
import { updateUser, UpdateUserState } from '../../../redux/registration';
import { required } from '../../../utils/common';

class ReduxUpdateUserForm extends React.PureComponent {
  static props = {
    updateUserState: PropTypes.string.isRequired,
    user: PropTypes.object.isRequired,
  }

  componentDidMount() {
    const data = {
      firstName: this.props.user.firstName,
      lastName: this.props.user.lastName,
    };
    this.props.dispatch(actions.change('registration.form.updateUser', data));
  }

  updateUserError = () => {
    switch (this.props.updateUserState) {
      case UpdateUserState.ErrorWrongPassword:
        return Messages.InvalidPassword;
      case UpdateUserState.ErrorUnknown:
        return Messages.UnknownError;
      case UpdateUserState.ErrorNotExists:
        return (
          <Message negative>
            <Message.Header>Twoje konto nie istnieje</Message.Header>
            Prawdopodobnie zostało usunięte. Prosimy o kontakt.
          </Message>
        );
      case UpdateUserState.ErrorNoPermissions:
        return (
          <Message negative>
            <Message.Header>Brak uprawnień</Message.Header>
            Nie masz wystarczających uprawnień do aktualizacji danych.
          </Message>
        );
      default:
        return null;
    }
  }

  handleSubmit = (formData) => {
    const groups = this.props.user.groups;
    const data = {
      firstName: formData.firstName.trim(),
      lastName: formData.lastName.trim(),
      groups: groups,
    };
    this.props.dispatch(updateUser(this.props.user.emailAddress, data));
  }

  render() {
    const inProgress = this.props.updateUserState === UpdateUserState.InProgress;
    const formClassName = (inProgress ? "loading " : "") + "ui form";
    return (
      <Form
        className={ formClassName }
        model="registration.form.updateUser"
        onSubmit={ this.handleSubmit }
      >

        <div className="field">
          <label>Imię</label>
          <Control.text
            model=".firstName"
            validators={{ required }}
          />
          <Errors
            className="errors"
            model=".firstName"
            show="touched"
            messages={{
              required: 'Wymagane',
            }}
          />
        </div>

        <div className="field">
          <label>Nazwisko</label>
          <Control.text
            model=".lastName"
            validators={{ required }}
          />
          <Errors
            className="errors"
            model=".lastName"
            show="touched"
            messages={{
              required: 'Wymagane',
            }}
          />
        </div>

        <button type="submit" className="ui button primary fluid">
          Zapisz
        </button>

        { this.updateUserError() }
      </Form>
    );
  }
}

const mapStateToProps = state => ({
  updateUserState: state.registration.updateUserProcess.state,
  user: state.login.session.user,
});

export const UpdateUserForm = connect(mapStateToProps)(ReduxUpdateUserForm);
