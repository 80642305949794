import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Container, Table, Loader, Message } from 'semantic-ui-react';
import { Segment, Visibility } from 'semantic-ui-react';
import { roundPriceValue } from '../../../utils/price';
import { OrderSummaryListState } from '../../../redux/orders.js';
import { Messages } from "../../Messages";
import { FilterButton, FilterSegment } from "../../Filters";
import "./Orders";

const OrdersBatchTake = 25;

export class OrdersList extends React.PureComponent {
  static propTypes = {
    fetchOrderSummaries: PropTypes.func.isRequired,
    orderSummaryList: PropTypes.object.isRequired,
    customerId: PropTypes.number,
    onOrderSelected: PropTypes.func.isRequired,
  }

  componentDidMount() {
    const summaryList = this.props.orderSummaryList;
    this.props.fetchOrderSummaries(summaryList.take ? summaryList.take : OrdersBatchTake,
                                    this.props.customerId,
                                    summaryList.ordersState);
  }

  orderSelected = (orderId) => () => this.props.onOrderSelected(orderId)

  stateCell(state) {
    switch (state) {
      case 'new': return "Nowe";
      case 'inprogress': return "W realizacji";
      case 'partiallyclosed': return "Częściowo zrealizowane";
      case 'closed': return "Zrealizowane";
      case 'cancelled': return "Anulowane";
      default: return "";
    }
  }

  fetchOrderSummaries = (state = null, take = OrdersBatchTake) => {
    if (this.props.orderSummaryList.ordersState !== state) {
      this.props.fetchOrderSummaries(take, this.props.customerId, state);
    } else {
      this.props.fetchOrderSummaries(take, this.props.customerId, null);
    }
  }

  tryFetchMoreOrderSummaries = () => {
    const orderSummaryList = this.props.orderSummaryList;
    if (orderSummaryList.take === orderSummaryList.orderSummaries.length) {
      this.props.fetchOrderSummaries(orderSummaryList.take + OrdersBatchTake,
                                     orderSummaryList.customerId,
                                     orderSummaryList.ordersState);
    }
  }

  orderHeader(withCustomerInfo) {
    if (!withCustomerInfo) {
      return (
        <Table.Row>
          <Table.HeaderCell>Numer</Table.HeaderCell>
          <Table.HeaderCell>Data</Table.HeaderCell>
          <Table.HeaderCell>Stan</Table.HeaderCell>
          <Table.HeaderCell>Wartość (netto)</Table.HeaderCell>
        </Table.Row>
      );
    } else {
      return (
        <Table.Row>
          <Table.HeaderCell>Numer</Table.HeaderCell>
          <Table.HeaderCell>Klient</Table.HeaderCell>
          <Table.HeaderCell>Data</Table.HeaderCell>
          <Table.HeaderCell>Stan</Table.HeaderCell>
          <Table.HeaderCell>Wartość (netto)</Table.HeaderCell>
        </Table.Row>
      );

    }
  }

  orderRow(order, withCustomerInfo) {
    if (!withCustomerInfo) {
      return (
        <Table.Row positive={ order.state === 'closed' }
                   negative={ order.state === 'cancelled' }
                   key={ order.id }
                   onClick={ this.orderSelected(order.id)  }
                   className="orderTableRow"
                   >
          <Table.Cell>{ order.id }</Table.Cell>
          <Table.Cell>{ moment(order.date).format('D-MM-YYYY') }</Table.Cell>
          <Table.Cell>{ this.stateCell(order.state) }</Table.Cell>
          <Table.Cell>{ roundPriceValue(order.totalNetPriceValue) } PLN</Table.Cell>
        </Table.Row>
      );
    } else {
      return (
        <Table.Row positive={ order.state === 'closed' }
                   negative={ order.state === 'cancelled' }
                   key={ order.id }
                   onClick={ this.orderSelected(order.id)  }
                   className="orderTableRow"
                   >
          <Table.Cell>{ order.id }</Table.Cell>
          <Table.Cell>{ order.customer.company.name }</Table.Cell>
          <Table.Cell>{ moment(order.date).format('D-MM-YYYY') }</Table.Cell>
          <Table.Cell>{ this.stateCell(order.state) }</Table.Cell>
          <Table.Cell>{ roundPriceValue(order.totalNetPriceValue) } PLN</Table.Cell>
        </Table.Row>
      );
    }
  }

  render() {
    const { orderSummaryList, customerId } = this.props;
    const state = orderSummaryList.state;
    const withCustomerInfo = !customerId;

    const loader = state === OrderSummaryListState.Fetching
      ? <Segment basic>
          <Loader active={ true }/>
        </Segment>
      : null;

    const orderRows = orderSummaryList.orderSummaries.map(o => this.orderRow(o, withCustomerInfo));
    const haveOrders = orderSummaryList.orderSummaries.length > 0;
    const isSuccess = state === OrderSummaryListState.Idle || state === OrderSummaryListState.FetchingMore;
    const ordersTable = haveOrders && isSuccess
      ? <Visibility onBottomVisible={ this.tryFetchMoreOrderSummaries } 
                    once={ false }
                    className="ordersList">
          <Table singleLine selectable>
            <Table.Header>
              { this.orderHeader(withCustomerInfo) }
            </Table.Header>
            <Table.Body>
              { orderRows }
            </Table.Body>
          </Table>
        </Visibility>
      : null;

    const noOrdersMsg = !haveOrders && isSuccess
      ? <Message>Brak zamówień</Message>
      : null;

    const noPermissionsMessage = state === OrderSummaryListState.ErrorNoPermissions
      ? Messages.NoPermissions 
      : null;

    const unknownErrorMessage = state === OrderSummaryListState.ErrorUnknown
      ? Messages.UnknownError 
      : null;

    const loaderMore = state === OrderSummaryListState.FetchingMore
      ? <Segment basic textAlign="center"><Loader active /></Segment>
      : null;

    return (
      <Container>
        <FilterSegment>
          <FilterButton onClick={ () => this.fetchOrderSummaries('new') } 
                        active={ this.props.orderSummaryList.ordersState === 'new' }
                        label="Nowe"
                        />
          <FilterButton onClick={ () => this.fetchOrderSummaries('inprogress') } 
                        active={ this.props.orderSummaryList.ordersState === 'inprogress' }
                        label="W realizacji"
                        />
          <FilterButton onClick={ () => this.fetchOrderSummaries('partiallyclosed') } 
                        active={ this.props.orderSummaryList.ordersState === 'partiallyclosed' }
                        label="Częściowo zrealizowane"
                        />
          <FilterButton onClick={ () => this.fetchOrderSummaries('closed') } 
                        active={ this.props.orderSummaryList.ordersState === 'closed' }
                        label="Zrealizowane"
                        />
          <FilterButton onClick={ () => this.fetchOrderSummaries('cancelled') } 
                        active={ this.props.orderSummaryList.ordersState === 'cancelled' }
                        label="Anulowane"
                        />
        </FilterSegment>
        { loader }
        { ordersTable }
        { noOrdersMsg }
        { noPermissionsMessage }
        { unknownErrorMessage }
        { loaderMore }
      </Container>
    );
  }
}
