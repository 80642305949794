import React from 'react';
import PropTypes from 'prop-types';
import { Container, Header, Icon, Loader, Message, Segment } from 'semantic-ui-react';
import { Redirect, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { RegistrationStep } from './RegistrationStep';
import { Messages } from '../../Messages';
import { EmailConfirmationState, confirmEmail } from '../../../redux/registration';
import { ScrollToTopOnMount } from '../../ScrollToTopOnMount';
import { logout } from '../../../redux/login';

class EmailConfirmationPure extends React.PureComponent {
  static props = {
    confirmationState: PropTypes.string.isRequired,
    confirmEmail: PropTypes.func.isRequired,
    logout: PropTypes.func.isRequired,
  }

  componentDidMount() {
    this.props.logout();
    if (this.props.match.params.token) {
      this.props.confirmEmail(this.props.match.params.token);
    }
  }

  render() {
    if (!this.props.match.params.token) {
      return <Redirect to="/"/>;
    }

    const step = <RegistrationStep step="confirm-email" className="mobile hidden"/>;
    const state = this.props.confirmationState;
    const success = state === EmailConfirmationState.ErrorAlreadyConfirmed 
                 || state === EmailConfirmationState.Success;

    const alreadyConfirmed = success ? this.emailAddressConfirmedMessage() : null;
    const illegalToken = state === EmailConfirmationState.ErrorIllegalToken
      ? this.illegalTokenMessage() : null;
    const unknownError = state === EmailConfirmationState.ErrorUnknown
      ? Messages.UnknownError : null;
    const loader = state === EmailConfirmationState.InProgress
      ? <Segment basic><Loader active/></Segment> : null;

    return (
      <Container text textAlign='center'>
        <ScrollToTopOnMount/>
        <Header as='h1' icon textAlign='center'>
          <Icon name='signup' circular/>
          <Header.Content>Zarejestruj</Header.Content>
        </Header>
        { step }
        { alreadyConfirmed }
        { illegalToken }
        { unknownError }
        { loader }
      </Container>
    );
  }

  emailAddressConfirmedMessage() {
    return (
      <Message success>
        <Message.Header>Adres e-mail potwierdzony</Message.Header>
        <Icon name='sign in'/>
        <Link to="/login">Możesz się zalogować.</Link>
      </Message>
    );
  }

  illegalTokenMessage() {
    return (
      <Message negative>
        <Message.Header>Błąd</Message.Header>
        Niepoprawny link aktywacyjny.
      </Message>
    );
  }
}

const mapStateToProps = state => ({
  confirmationState: state.registration.emailConfirmationProcess.state,
});

const mapDispatchToProps = dispatch => ({
  confirmEmail: (token) => dispatch(confirmEmail(token)),
  logout: () => dispatch(logout()),
});

export const EmailConfirmation =
  connect(mapStateToProps, mapDispatchToProps)(EmailConfirmationPure);
