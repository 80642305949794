import moment from 'moment';

export function isAvailable(now, availableSince) {
  return !!availableSince && moment(availableSince).isBefore(now);
}

export function availabilityDateText(now, availableSince) {
  if (!availableSince) {
    return "Niedostępny";
  }
  const since = moment(availableSince);
  return since.isSameOrAfter(now)
    ? `Dostępny od: ${since.format('D-MM')}`
    : "Dostępny";
}
