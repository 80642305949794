import React from 'react';
import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Icon, Message } from 'semantic-ui-react';
import { Control, Errors, Form } from 'react-redux-form';
import { login, LoginState } from '../../../redux/login';
import { isEmailValid, required, trimString } from '../../../utils/common';
import { Messages } from '../../Messages';

class ReduxLoginForm extends React.PureComponent {
  static props = {
    loginState: PropTypes.object.isRequired,
  }

  loginError = () => {
    switch (this.props.loginState) {
      case LoginState.ErrorInvalidCredentials:
        return (
          <Message negative>
            <Message.Header>Błąd!</Message.Header>
            Nieznany użytkownik/hasło.
          </Message>
        );
      case LoginState.ErrorUnknown:
        return Messages.UnknownError;
      case LoginState.ErrorInactiveUser:
        return (
          <Message negative>
            <Message.Header>Potwierdź swój adres e-mail</Message.Header>
            Mail aktywacyjny został do Ciebie wysłany podczas rejestracji.
          </Message>
        );
      default:
        return null;
    }
  }

  handleSubmit = (credentials) => this.props.dispatch(login(credentials))

  render() {
    const formClassName =
      (this.props.loginState === LoginState.InProgress ? "loading " : "") + "ui form";
    return (
      <Form
        model="login.form.credentials"
        onSubmit={ this.handleSubmit }
        className={ formClassName }
      >
        <div className="field">
          <Control
            model=".emailAddress" 
            placeholder="E-mail"
            validators={{ required, validEmail: isEmailValid }}
            validateOn="blur"
            parser={ trimString }
          />
          <Errors
            className="error"
            model=".emailAddress"
            show="touched"
            messages={{
              required: 'Wymagane',
              validEmail: 'Niepoprawny adres email',
            }}
          />
        </div>

        <div className="field">
          <Control 
            type="password" 
            model=".password" 
            placeholder="Hasło"
            validators={{ required }}
            parser={ trimString }
          />
          <Errors
            className="error"
            model=".password"
            show="touched"
            messages={{
              required: 'Wymagane',
            }}
          />
        </div>

        <button className="ui button primary fluid" type="submit">
          Zaloguj
        </button>

        { this.loginError() }

        <Message info>
          <Icon name='unlock'/>
          <Link to="/login/recovery">Nie pamiętasz hasła?</Link>
        </Message>
      </Form>
    );
  }
}

const mapStateToProps = state => ({
  loginState: state.login.process.state,
});

export const LoginForm = connect(mapStateToProps)(ReduxLoginForm);
