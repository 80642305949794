import React from 'react';
import PropTypes from 'prop-types';
import { Button, Modal } from 'semantic-ui-react';

export class CantEditInCurrentStateModal extends React.PureComponent {
  static propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
  }

  render() {
    return (
      <Modal width='8' size='small' open={ this.props.open } onClose={ this.props.onClose }>
        <Modal.Header>Niedozwolone</Modal.Header>
        <Modal.Content>
          Edycja jest możliwa tylko <b>w stanie realizacji</b>.
        </Modal.Content>
        <Modal.Actions>
          <Button color='green' onClick={ this.props.onClose }>OK</Button>
        </Modal.Actions>
      </Modal>
    );
  }
}
