import React from 'react';
import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Container, Header, Icon, Message } from 'semantic-ui-react';
import { PasswordRecoveryInitForm } from './PasswordRecoveryInitForm';
import { PasswordRecoveryFinalizeForm } from './PasswordRecoveryFinalizeForm';
import { PasswordRecoveryInitState } from '../../../redux/passwordRecovery';
import { PasswordRecoveryFinalizeState } from '../../../redux/passwordRecovery';
import { ScrollToTopOnMount } from '../../ScrollToTopOnMount';

class PasswordRecoveryPure extends React.PureComponent {
  static props = {
    recoveryInitState: PropTypes.object.isRequired,
    recoveryFinalizeState: PropTypes.object.isRequired,
  }

  render() {
    return (
      <Container text textAlign='center'>
        <ScrollToTopOnMount/>
        <Header as='h1' icon textAlign='center'>
          <Icon name='unlock' circular/>
          <Header.Content>Nie pamiętasz hasła?</Header.Content>
        </Header>
        { this.renderContent() }
      </Container>
    );
  }

  renderContent() {
    if (this.props.match.params.uuid) {
      if (this.props.recoveryFinalizeState === PasswordRecoveryFinalizeState.Success) {
        return this.renderRecoveryFinalized();
      } else {
        return (
          <div>
            <p>Wprowadź adres e-mail i <b>nowe hasło</b>.</p>
            <PasswordRecoveryFinalizeForm uuid={this.props.match.params.uuid}/>
          </div>
        );
      }
    } else if (this.props.recoveryInitState === PasswordRecoveryInitState.Success) {
      return this.renderRecoveryInitialized();
    } else {
      return (
        <div>
          <p>Wprowadź adres e-mail. Wyślemy Ci link do ustawienia nowego.</p>
          <PasswordRecoveryInitForm/>
        </div>
      );
    }
  }

  renderRecoveryInitialized() {
    return (
      <Message success>
        <Message.Header>Sprawdź skrzynkę</Message.Header>
        <Icon name='mail outline'/>
        Link do zmiany hasła powinien już tam być!
      </Message>
    );
  }

  renderRecoveryFinalized() {
    return (
      <Message success>
        <Message.Header>Hasło zostało zmienione</Message.Header>
        <Icon name='sign in'/>
        <Link to="/login">Możesz się zalogować.</Link>
      </Message>
    );
  }
}

const mapStateToProps = state => ({
  recoveryInitState: state.passwordRecovery.recoveryInitProcess.state,
  recoveryFinalizeState: state.passwordRecovery.recoveryFinalizeProcess.state,
});

export const PasswordRecovery = connect(mapStateToProps)(PasswordRecoveryPure);
