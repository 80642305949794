import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Button, Container, Divider, Grid, Header, Icon, Loader, Message, Segment, Table, Visibility } from 'semantic-ui-react';
import { CustomerListState, fetchCustomers, requestCustomersDelayedFetch } from '../../../../redux/customers.js';
import { FilterButton } from '../../../Filters';
import { Messages } from "../../../Messages";
import { ScrollToTopOnMount } from '../../../ScrollToTopOnMount';
import { SearchField } from "../../../SearchField";
import './Customers.css';
import { EmailModal } from "./EmailModal";

const CustomersBatchTake = 25;

class CustomersPure extends React.PureComponent {
  static propTypes = {
    fetchCustomers: PropTypes.func.isRequired,
    customerList: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    searchCustomers: PropTypes.func.isRequired,
    customersSearchQuery: PropTypes.string.isRequired,
  }

  state = { viewType: 'all' }

  componentDidMount() {
    this.fetchCustomers({});
  }

  fetchCustomers = ({ take, 
                      searchQuery = this.props.customerList.searchQuery, 
                      isActive = this.props.customerList.isActive }) => {
    if (take === undefined) {
      const customerList = this.props.customerList;
      take = customerList.take ? customerList.take : CustomersBatchTake;
    }
    this.props.fetchCustomers({ take, searchQuery, isActive });
  }

  searchCustomers = (searchQuery) => {
    this.props.searchCustomers(searchQuery, this.props.customerList.isActive);
  }

  tryFetchMoreCustomers = () => {
    const customerList = this.props.customerList;
    if (customerList.take === customerList.customers.length) {
      this.fetchCustomers({ 
        take: customerList.take + CustomersBatchTake,
        searchQuery: customerList.searchQuery,
      });
    }
  }

  toggleViewType = (type) => () => {
    const isActive = this.props.customerList.isActive === false ? null : false;
    this.fetchCustomers({ take: CustomersBatchTake, isActive });
  }

  customerSelected = (customer) => () => {
    const redirectUrl = '/admin/customers/' + customer.id;
    this.props.history.push(redirectUrl);
  };

  render() {
    const { customerList } = this.props;
    const state = customerList.state;

    const loader = state === CustomerListState.Fetching
      ? <Segment basic>
          <Loader active={ true }/>
        </Segment>
      : null;

    const customerRows = 
      customerList.customers.map(customer => (
        <Table.Row key={ customer.id }
                   onClick={ this.customerSelected(customer) }
                   negative={ !customer.isActive }
                   className="customerTableRow"
                   >
          <Table.Cell>{ customer.id }.</Table.Cell>
          <Table.Cell>{ customer.emailAddress }</Table.Cell>
          <Table.Cell>{ customer.company.name }</Table.Cell>
          <Table.Cell>{ moment(customer.creationDate).format('D-MM-YYYY') }</Table.Cell>
        </Table.Row>
      ));

    const haveCustomers = customerRows.length > 0;
    const isSuccess = state === CustomerListState.Idle || state === CustomerListState.FetchingMore;
    const customersTable = haveCustomers && isSuccess
      ? <Visibility onBottomVisible={ this.tryFetchMoreCustomers } 
                    once={ false }
                    className="adminCustomersList">
          <Table selectable>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Nr</Table.HeaderCell>
                <Table.HeaderCell>Email</Table.HeaderCell>
                <Table.HeaderCell>Nazwa firmy</Table.HeaderCell>
                <Table.HeaderCell>Data rejestracji</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              { customerRows }
            </Table.Body>
          </Table>
        </Visibility>
      : null;

    const noCustomersMsg = !haveCustomers && isSuccess
      ? <Message>Brak klientów</Message>
      : null;

    const noPermissionsMessage = state === CustomerListState.ErrorNoPermissions
      ? Messages.NoPermissions 
      : null;

    const unknownErrorMessage = state === CustomerListState.ErrorUnknown
      ? Messages.UnknownError 
      : null;

    const loaderMore = state === CustomerListState.FetchingMore
      ? <Segment basic textAlign="center"><Loader active /></Segment>
      : null;

    return (
      <Container>
        <ScrollToTopOnMount/>
        <Header as='h1' icon textAlign='center'>
          <Icon name='list ul' circular/>
          <Header.Content>Lista klientów</Header.Content>
        </Header>
        <Divider hidden/>
        <Grid className="adminCustomersBar" verticalAlign="middle" stackable>
          <Grid.Row>
            <Grid.Column width={ 12 }/>
            <Grid.Column textAlign="right" width={ 4 }>
              <SearchField label="Szukaj klienta"
                           searchValue={ this.props.customersSearchQuery }
                           onSearchValueChanged={ this.searchCustomers }
                           fluid
                           />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={ 12 } textAlign="center" verticalAlign="middle">
              <FilterButton onClick={ this.toggleViewType('inactiveOnly') }
                            active={ customerList.isActive === false }
                            label='Pokaż nieaktywnych'
                            />
            </Grid.Column>
            <Grid.Column width={ 4 }>
              <EmailModal trigger={ <Button fluid>Email do klientów</Button> }/>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        { loader }
        { customersTable }
        { loaderMore }
        { noCustomersMsg }
        { noPermissionsMessage }
        { unknownErrorMessage }
      </Container>
    );

  }
}

const mapStateToProps = state => ({
  customerList: state.customers.customerList,
  customersSearchQuery: state.customers.customersDelayedFetchRequest.searchQuery,
});

const mapDispatchToProps = dispatch => ({
  fetchCustomers: (data) => dispatch(fetchCustomers(data)),
  searchCustomers: (searchQuery, isActive) => dispatch(
    requestCustomersDelayedFetch({ searchQuery, isActive, take: CustomersBatchTake })
  ),
});

export const Customers =
  connect(mapStateToProps, mapDispatchToProps)(CustomersPure);
