import { combineReducers } from "redux";
import { unauthorized } from "./login";

export const PublicTokenState = {
  Idle: 'IDLE',
  Fetching: 'FETCHING',
  ErrorNoPermissions: 'ERROR_NO_PERMISSIONS',
  ErrorUnknown: 'ERROR_UNKNOWN',
};

const initPublicTokenState = {
  token: null,
  state: PublicTokenState.Idle,
};

function publicTokenReducer(state = initPublicTokenState, action) {
  switch (action.type) {
    case 'FETCHING_PUBLIC_TOKEN_STARTED': {
      const stateUpdate = { state: PublicTokenState.Fetching };
      return Object.assign({}, state, stateUpdate);
    }
    case 'FETCHING_PUBLIC_TOKEN_FINISHED': {
      const stateUpdate = {
        state: action.state,
        token: action.token,
      };
      return Object.assign({}, state, stateUpdate);
    }
    default: {
      return state;
    }
  }
}

export function fetchPublicToken() {
  return (dispatch, getState, { PublicApi }) => {
    const publicToken = getState().public.publicToken;
    if (publicToken.state === PublicTokenState.Fetching || !!publicToken.token)
      return;

    const session = getState().login.session;
    if (!session.customer || !session.customer.isActive) {
      if (!session.token) {
        dispatch(unauthorized());
      }

      return Promise.resolve(dispatch(fetchingPublicTokenFinished(PublicTokenState.ErrorNoPermissions)));
    }

    dispatch(fetchingPublicTokenStarted());
    return PublicApi.publicToken(session.customer.id, session.token).then(
      data =>
        dispatch(fetchingPublicTokenFinished(PublicTokenState.Idle, data.token)),
      error => {
        switch (error) {
          case PublicApi.PublicTokenError.Forbidden:
            dispatch(fetchingPublicTokenFinished(PublicTokenState.ErrorNoPermissions));
            break;
          case PublicApi.PublicTokenError.Unauthorized:
            dispatch(fetchingPublicTokenFinished(PublicTokenState.ErrorNoPermissions));
            dispatch(unauthorized());
            break;
          default:
            dispatch(fetchingPublicTokenFinished(PublicTokenState.ErrorUnknown));
        }
      }
    );
  };
}

function fetchingPublicTokenStarted() {
  return {
    type: 'FETCHING_PUBLIC_TOKEN_STARTED',
    state: PublicTokenState.Fetching,
    token: null,
  };
}

function fetchingPublicTokenFinished(state, token = null) {
  return {
    type: 'FETCHING_PUBLIC_TOKEN_FINISHED',
    state: state,
    token: token,
  };
}

export function publicReducer() {
  return combineReducers({
    publicToken: publicTokenReducer,
  });
}
