import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { PrivateRoute } from '../PrivateRoute';
import { AccountSettings } from './AccountSettings';

export class AccountSettingsRouter extends React.PureComponent {
  defaultRedirect = () => <Redirect to='/'/>;
  render() {
    return (
      <Switch>
        <PrivateRoute exact path='/account/settings' component={ AccountSettings }/>
        <Route path='/' render={ this.defaultRedirect }/>
      </Switch>
    );
  }
}
