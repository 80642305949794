import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Header, Segment, Table } from 'semantic-ui-react';
import { SimpleText } from '../../SimpleText';
import { orderedProductsNetPriceValue, roundPriceValue, grossPrice } from '../../../utils/price';
import './PrintableOrder.css';

export class PrintableOrder extends React.Component {
  static propTypes = {
    order: PropTypes.object.isRequired,
  }

  render() {
    const order = this.props.order;
    const orderDate = moment(order.date).format('DD-MM-YYYY');
    const totalNetValue = orderedProductsNetPriceValue(order.orderedProducts);
    const customerInfoRow = order && order.customerInfo
      ? <Table.Row>
          <Table.Cell width={ 2 }><b>Uwagi</b></Table.Cell>
          <Table.Cell>
            <SimpleText text={ order.customerInfo }/>
          </Table.Cell>
        </Table.Row>
      : null;

    const items = order.orderedProducts.map(item => {
      const withDelivery = item.postDeliveryNetPriceValue !== undefined;
      const deliveryPrice = item.postDeliveryNetPriceValue || 0;
      const totalPrice = item.totalNetPriceValue + deliveryPrice;
      return (
        <Table.Row key={ item.productId }>
          <Table.Cell>{ item.index }</Table.Cell>
          <Table.Cell>{ item.name }</Table.Cell>
          <Table.Cell>{ item.count }</Table.Cell>
          <Table.Cell>{ withDelivery ? "Tak" : "Nie" }</Table.Cell>
          <Table.Cell>{ roundPriceValue(totalPrice) } PLN</Table.Cell>
        </Table.Row>
      );
    });

    return (
      <Segment.Group className="printableOrderSegmentGroup">
        <Segment basic>
          <Header as='h1'>
            <Header.Content>Zamówienie nr { order.id }</Header.Content>
            <Header.Subheader>z dnia { orderDate }</Header.Subheader>
          </Header>
        </Segment>
        <Segment clearing basic vertical>
          <Segment floated="left" basic compact className="printableOrderAddress">
            <b>{ order.customer.company.name }</b>
            <br/>
            NIP { order.customer.company.nip }
            <br/>
            { order.customer.phoneNumber }
            <br/>
            {`${order.customer.firstName} ${order.customer.lastName}`}
            <br/>
            { order.customer.additionalInfo }
          </Segment>
          <Segment floated="right" basic compact className="printableOrderAddress">
            <b>Firma Handlowa Furnitex s.c.</b>
            <br/>NIP 6652643916
            <br/>+48 605 545 922
            <br/>ul. Przemysłowa 85
            <br/>62-510 Konin
          </Segment>
        </Segment>
        <Segment basic>
          <Table columns={ 2 } verticalAlign="middle" basic="very">
            <Table.Body>
              <Table.Row>
                <Table.Cell width={ 2 }><b>Wartość (netto)</b></Table.Cell>
                <Table.Cell>{ roundPriceValue(totalNetValue) } PLN</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell width={ 2 }><b>Wartość (brutto)</b></Table.Cell>
                <Table.Cell>{ roundPriceValue(grossPrice(totalNetValue)) } PLN</Table.Cell>
              </Table.Row>
              { customerInfoRow }
            </Table.Body>
          </Table>
        </Segment>
        <Segment basic>
          <Header as='h2'>
            <Header.Content>Pozycje zamówienia</Header.Content>
          </Header>
          <Table basic="very">
            <Table.Header>
              <Table.Row>
                <Table.Cell><b>Indeks</b></Table.Cell>
                <Table.Cell><b>Nazwa</b></Table.Cell>
                <Table.Cell><b>Sztuk</b></Table.Cell>
                <Table.Cell><b>Dostawa 24h</b></Table.Cell>
                <Table.Cell><b>Wartość (netto)</b></Table.Cell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              { items }
            </Table.Body>
          </Table>
        </Segment>
      </Segment.Group>
    );
  }
}
