import React from 'react';
import PropTypes from 'prop-types';

export class SimpleText extends React.PureComponent {
  static propTypes = {
    text: PropTypes.string.isRequired,
  }

  render() {
    const result = 
      this.props.text.split('\n').map((item, key) => <span key={ key }>{ item }<br/></span>);
    return <span>{ result }</span>;
  }
}
