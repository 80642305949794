import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { Registration } from './Registration';
import { EmailConfirmation } from './EmailConfirmation';
import { CustomerRegistration } from './CustomerRegistration';
import { PrivateRoute } from '../PrivateRoute';

export class RegistrationRouter extends React.PureComponent {
  defaultRedirect = () => <Redirect to='/'/>;
  render() {
    return (
      <Switch>
        <Route exact path='/registration' component={ Registration }/>
        <Route exact path='/registration/email-confirmation/:token' component={ EmailConfirmation }/>
        <PrivateRoute exact path='/registration/customer' component={ CustomerRegistration }/>
        <Route path='/' render={ this.defaultRedirect }/>
      </Switch>
    );
  }
}
