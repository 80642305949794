import React from 'react';
import moment from 'moment';
import { Label } from 'semantic-ui-react';
import { addVatRoundToInteger, roundPriceValue } from '../../../utils/price';
import { isAvailable, availabilityDateText } from '../../../utils/product';
import './ProductLabels.css';

export const PriceLabel = ({ price, attached, retail, size, basic }) => {
  if (price === null) {
    return null;
  }

  const promo = price.promoNetPrice !== undefined;
  const netPrice = retail ? addVatRoundToInteger(price.netPrice) : roundPriceValue(price.netPrice);
  return (
    <Label size={ size } 
           className={ promo ? "priceLabel promo" : "priceLabel" }
           attached={ attached } 
           basic={ basic }>
      { promo 
        ? <span><s>{ netPrice }</s> <big>{ retail ? addVatRoundToInteger(price.promoNetPrice) : roundPriceValue(price.promoNetPrice) }</big></span>
        : <big>{ netPrice }</big> } PLN
    </Label>
  );
};

export const AvailabilityLabel = ({ availableSince, attached, size }) => {
  const now = moment.now();
  const availabilityInfo = availabilityDateText(now, availableSince);
  const isUnavailable = !isAvailable(now, availableSince);
  return isUnavailable
    ? <Label color="red" attached={ attached } size={ size }>
        { availabilityInfo }
      </Label>
    : <Label color="green" attached={ attached } size={ size }>
        { availabilityInfo }
      </Label>;
};

export const NewLabel = ({ product, attached, size, basic }) => {
  const label = product.flags.indexOf('new') !== -1
    ? <Label size={ size } 
             color={ basic ? undefined : "red" } 
             basic={ basic } 
             className="newLabel" 
             attached={ attached }>
        Nowość!
      </Label> 
    : null;
  return label;
};
