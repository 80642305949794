function withAuthHeader(headers, token) {
  return token
    ? Object.assign({}, headers, { 'Authorization': 'Bearer ' + token })
    : headers;
}

function get(endpoint, token) {
  return fetch(endpoint, {
    headers: withAuthHeader({
      'Accept': 'application/json'
    }, token),
  }).then(parseJSON)
    .then(checkStatus);
}

function send(method, endpoint, data, token) {
  return fetch(endpoint, {
    method: method,
    body: JSON.stringify(data),
    headers: withAuthHeader({
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    }, token),
  }).then(parseJSON).then(checkStatus);
}

function postFile(endpoint, parts, file, token) {
  let data = new FormData();
  data.append('file', file);
  for (let k in parts) {
    data.append(k, parts[k]);
  }
  return fetch(endpoint, {
    method: 'post',
    body: data,
    headers: withAuthHeader({}, token),
  }).then(parseJSON).then(checkStatus);
}

function post(endpoint, data, token) {
  return send('post', endpoint, data, token);
}

function put(endpoint, data, token) {
  return send('put', endpoint, data, token);
}

function del(endpoint, token) {
  return send('delete', endpoint, undefined, token);
}

function parseJSON(response) {
  return response.json().then(data => ({
    status: response.status,
    statusText: response.statusText,
    data: data
  })).then(data => data, () => ({
    status: response.status,
    statusText: response.statusText,
  }));
}

function url(endpoint, path, params) {
  const url = new URL(`${endpoint}/${path}`);
  if (params)
    Object.keys(params).forEach(k => {
      if (params[k] !== undefined && params[k] !== null) 
        url.searchParams.append(k, params[k]);
    });
  return url;
}

function checkStatus(response) {
  if (response.status >= 200 && response.status < 300) {
    return response.data;
  } else {
    const msg =
      response.data && response.data.message ? response.data.message : "[nomsg]";
    const error = new Error(`HTTP error ${response.statusText}: ${msg}`);
    error.data = response.data;
    error.status = response.status;
    error.statusText = response.statusText;
    throw error;
  }
}

export {
  get,
  post,
  put,
  del,
  url,
  postFile,
};
