import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Container, Divider, Segment, Header, Grid, Loader, Message } from 'semantic-ui-react';
import { Icon, Search } from 'semantic-ui-react';
import { AvailabilityLabel, NewLabel } from './ProductLabels';
import { Messages } from '../../Messages';
import { ProductInBasketEdit } from '../Orders/ProductInBasketEdit';
import { PhotoGallery } from '../../PhotoGallery';
import { SimpleText } from '../../SimpleText';
import { productPrice, productTotalNetPriceValue, roundPriceValue } from '../../../utils/price';
import { productPostDeliveryNetPriceValue } from '../../../utils/price';
import { updateProductInBasket } from '../../../redux/orders';
import { fetchProduct, ProductDetailsState, ProductListState } from '../../../redux/store';
import { requestProductsDelayedFetch } from '../../../redux/store';
import { ScrollToTopOnMount } from '../../ScrollToTopOnMount';
import { ProductsSearchBar } from './ProductsSearchBar';
import { PriceText } from '../../PriceText';
import './Product.css';
import { tryResizeImage } from '../../../utils/common';
import Config from '../../../config';

// If same as in product grid, saves a HTTP request after "show all" click.
const ProductsBatchTake = 24;

class ProductPure extends React.PureComponent {
  static propTypes = {
    fetchProduct: PropTypes.func.isRequired,
    productDetails: PropTypes.object.isRequired,
    updateProductInBasket: PropTypes.func.isRequired,
    customerId: PropTypes.number,
    isAdmin: PropTypes.bool.isRequired,
    newOrder: PropTypes.object.isRequired,
    productsSearchQuery: PropTypes.string.isRequired,
    searchProducts: PropTypes.func.isRequired,
    productList: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired
  }

  searchTake = 5

  componentDidMount() {
    this.props.fetchProduct(this.props.match.params.id, 
                            this.props.isAdmin, 
                            this.props.customerId);
  }

  componentDidUpdate(prevProps) {
    if (this.props.match.params.id !== prevProps.match.params.id ||
        this.props.customerId !== prevProps.customerId) {
      this.props.fetchProduct(this.props.match.params.id, this.props.isAdmin, this.props.customerId);
    }
  }

  handleSearch = (e, data) =>
    this.props.searchProducts(data.value, this.props.isAdmin, this.props.customerId);

  searchResults = () => {
    const isIdle = this.props.productList.state === ProductListState.Idle 
                && this.props.productList.searchQuery === this.props.productsSearchQuery;
    const count = isIdle ? this.props.productList.products.length : 0;
    const results = isIdle
      ? this.props.productList.products.slice(0, this.searchTake).map(p => {
          return { 
            title: p.index,
            description: p.name,
            image: tryResizeImage(p.images[0], Config.imgWidth.thumbnail), 
            id: p.id,
            key: p.id
          };
        })
      : [];
    
    return count > this.searchTake ? results.concat({ title: "Pokaż wszystkie" }) : results;
  }

  showProduct = (e, data) => {
    const id = data.result.id;
    if (!!id) {
      this.props.history.push("/products/" + id);
    } else {
      this.props.history.push("/products");
    }
  }

  goBackOrShowOffer = () => {
    const { history } = this.props;
    if (history.length > 0)
      history.goBack();
    else
      history.push("/products");
  }

  renderSearch = () => (
    <Search placeholder="czego szukasz?" 
            size="big"
            className="productSearch"
            showNoResults={ false }
            value={ this.props.productsSearchQuery }
            onSearchChange={ this.handleSearch }
            loading={ this.props.productList.state === ProductListState.Fetching }
            results={ this.searchResults() }
            onResultSelect={ this.showProduct }
            fluid
            />
  );

  appendClass(condition, className) {
    return condition ? className : "";
  }

  renderDescriptionSegment() {
    const product = this.props.productDetails.product;
    return (
      <Message className="productSegment">
        <Segment className="productFlags subflags" basic>
          <AvailabilityLabel availableSince={ product.availableSince }/>
          <NewLabel product={ product }/>
        </Segment>
        <Header as='h3'>Opis produktu</Header>
        <SimpleText text={ product.description } />
      </Message>
    );
  }

  renderDescriptionBottomSegment() {
    const product = this.props.productDetails.product;
    return (
      <Segment>
        <Header as='h3'>Opis produktu</Header>
        <SimpleText text={ product.description } />
      </Segment>
    );
  }

  renderPriceDescriptionSegment() {
    const product = this.props.productDetails.product;
    const isAdmin = this.props.isAdmin;
    const isCustomer = !!this.props.customerId;
    const price = productPrice(product);
    return (
      <Message className="productSegment">
        <Segment className="productFlags subflags" basic>
          <AvailabilityLabel availableSince={ product.availableSince }/>
          <NewLabel product={ product }/>
        </Segment>
        <Segment className="productFlags" basic>
          <PriceText price={ price } retail={ !isAdmin && !isCustomer }/>
        </Segment>
        <Divider/>
        <Header as='h4'>Opis produktu</Header>
        <SimpleText text={ product.description } />
      </Message>
    );
  }

  renderOrderSegment() {
    const product = this.props.productDetails.product;
    const productInBasket = this.props.newOrder.productsInBasket[product.id]
      ? this.props.newOrder.productsInBasket[product.id] 
      : { product, count: 0, withPostDelivery: false };
    const { count, withPostDelivery } = productInBasket;
    const netPrice = productTotalNetPriceValue(product, count, false);
    const deliveryNetPrice = withPostDelivery ? productPostDeliveryNetPriceValue(product, count) : 0;
    const price = productPrice(product);
    return (
      <Message className="productSegment">
        <Segment className="productFlags subflags" basic>
          <AvailabilityLabel availableSince={ product.availableSince }/>
          <NewLabel product={ product }/>
        </Segment>
        <Segment className="productFlags" basic>
          <PriceText price={ price }/>
        </Segment>
        <Divider/>
        <Header as='h4'>Koszyk</Header>
        <ProductInBasketEdit updateProductInBasket={ this.props.updateProductInBasket } 
                              productInBasket={ productInBasket }
                              iconPosition="left"
                              buttons="bottom"
                              icon="shop"
                              basicButtons
                              />
        <Divider/>
        <p className={ this.appendClass(count === 0, "hidden") }>
          Cena za { count } szt.: <b>{ roundPriceValue(netPrice) } PLN</b>
        </p>
        <p className={ this.appendClass(count === 0, "none") }>
          Cena za dostawę: <b>{ roundPriceValue(deliveryNetPrice) } PLN</b>
        </p>
        <p className={ this.appendClass(count !== 0, "none") }>
          Brak produktu w koszyku.
        </p>
        <p className={ this.appendClass(count === 0, "hidden") }>
          Łącznie: <b>{ roundPriceValue(netPrice + deliveryNetPrice) }</b> PLN
        </p>
      </Message>
    );
  }

  toGalleryImages = (images) =>
    images.map(i => ({ 
      normal: tryResizeImage(i, Config.imgWidth.normal),
      fullscreen: tryResizeImage(i, Config.imgWidth.fullscreen),
    }));

  renderProductView() {
    const product = this.props.productDetails.product;
    const isCustomer = !!this.props.customerId;
    const isPriceDefined = product.price !== undefined;
    return (
      <Segment basic>
        <Segment basic className="productHeader">
          <Header as='h1'>
            <a onClick={ this.goBackOrShowOffer }>
              <Icon name='chevron left' className='back'/>
            </a>
            <Header.Content>
              { product.index }
              <Header.Subheader>{ product.name }</Header.Subheader>
            </Header.Content>
          </Header>
          <Divider/>
        </Segment>
        <Grid divided='vertically' stackable>
          <Grid.Row columns={ 2 }>
            <Grid.Column>
              <PhotoGallery images={ this.toGalleryImages(product.images) }/>
            </Grid.Column>
            <Grid.Column>
              { isCustomer 
                  ? this.renderOrderSegment() 
                  : isPriceDefined 
                  ? this.renderPriceDescriptionSegment() 
                  : this.renderDescriptionSegment()
              }
            </Grid.Column>
          </Grid.Row>
        </Grid>
        { isCustomer ? this.renderDescriptionBottomSegment() : null }
      </Segment>
    );
  }

  render() {
    const productId = this.props.match.params.id;
    const state = this.props.productDetails.state;

    const loader = state === ProductDetailsState.Fetching 
      ? <Segment basic>
          <Loader active/>
        </Segment> 
      : null;

    const productView = this.props.productDetails.product && state === ProductDetailsState.Idle
      ? this.renderProductView() : null;

    const unknownErrorMsg = 
      state === ProductDetailsState.ErrorUnknown ? Messages.UnknownError : null;

    const unauthorizedErrorMsg = state === ProductDetailsState.ErrorNoPermissions
      ? Messages.NoPermissions
      : null;

    const notExistsView = state === ProductDetailsState.ErrorNotExists 
      ? <Message negative>
          <Message.Header>Błąd</Message.Header>
          Produkt nr { productId } nie istnieje.
        </Message>
      : null;

    return (
      <div>
        <ScrollToTopOnMount/>
        <ProductsSearchBar renderSearch={ this.renderSearch } />
        { !productView ? <Divider hidden/> : null }
        <Container>
          { loader }
          { unknownErrorMsg }
          { unauthorizedErrorMsg }
          { productView }
          { notExistsView }
        </Container>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const session = state.login.session;
  return {
    productDetails: state.store.productDetails,
    customerId: session.customer && session.customer.isActive ? session.customer.id : null,
    isAdmin: !!session.user && session.user.isAdmin(),
    newOrder: state.orders.newOrder,
    productsSearchQuery: state.store.productsDelayedFetchRequest.searchQuery,
    productList: state.store.productList,
  };
};

const mapDispatchToProps = dispatch => ({
  fetchProduct: (pid, admin, customerId) => dispatch(fetchProduct(pid, { admin, customerId })),
  updateProductInBasket: (data) => dispatch(updateProductInBasket(data)),
  searchProducts: (searchQuery, admin, customerId) => 
    dispatch(requestProductsDelayedFetch({
      take: ProductsBatchTake,
      searchQuery,
      productsFor: { admin, customerId },
      flags: ['instock']
    })),
});

export const Product =
  connect(mapStateToProps, mapDispatchToProps)(ProductPure);
