import { PropTypes } from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';
import { Visibility } from 'semantic-ui-react';
import { AccountSettingsRouter } from './AccountSettings/AccountSettingsRouter';
import { AdminRouter } from './Admin/AdminRouter';
import { ApiRouter } from './Api/ApiRouter';
import { ContactRouter } from './Contact/ContactRouter';
import { CookieWarning } from './CookieWarning';
import './Dashboard.css';
import { FileDocumentsRouter } from './FileDocuments/FileDocumentsRouter';
import { Footer } from './Footer';
import { LoginRouter } from './Login/LoginRouter';
import { Logout } from './Login/Logout';
import { OrdersRouter } from './Orders/OrdersRouter';
import { ProductsRouter } from './Products/ProductsRouter';
import { RegistrationRouter } from './Registration/RegistrationRouter';
import { TopBar } from './TopBar';

class DashboardPure extends React.PureComponent {
  static propTypes = {
    location: PropTypes.object.isRequired,
    isAdmin: PropTypes.bool.isRequired,
  }

  state = { viewport: {} }

  onVisibilityUpdate = (e, { calculations }) => {
    this.setState({ viewport: calculations });
  }

  defaultRedirect = () => {
    const root = this.props.isAdmin ? '/admin' : '/products';
    return <Redirect to={ root }/>;
  }

  render() {
    return (
      <div>
        <Visibility id="site-content" onUpdate={ this.onVisibilityUpdate }>
          <TopBar viewport={ this.state.viewport } 
                  history={ this.props.history } 
                  location={ this.props.location }
                  />

          <Switch>
            <Route path='/products' component={ ProductsRouter }/>
            <Route path='/orders' component={ OrdersRouter }/>
            <Route path='/new-order' component={ OrdersRouter }/>
            <Route path='/registration' component={ RegistrationRouter}/>
            <Route path='/account/settings' component={ AccountSettingsRouter }/>
            <Route path='/file-documents' component={ FileDocumentsRouter }/>
            <Route path='/login' component={ LoginRouter }/>
            <Route path='/logout' component={ Logout }/>
            <Route path='/admin' component={ AdminRouter }/>
            <Route path='/contact' component={ ContactRouter }/>
            <Route path='/public-api-info' component={ ApiRouter }/>
            <Route path='/' render={ this.defaultRedirect }/>
          </Switch>
        </Visibility>
        <CookieWarning/>
        <Footer/>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  isAdmin: !!state.login.session.user && state.login.session.user.isAdmin(),
});

export const Dashboard = connect(mapStateToProps)(DashboardPure);
