const storage = window.localStorage;

function key(what) {
  return 'cookie.' + what;
}

function isCookieAccepted() {
  return storage.getItem(key('isAccepted')) === 'true';
}

function setCookieAccepted(isAccepted) {
  storage.setItem(key('isAccepted'), isAccepted ? 'true' : 'false');
}

export const CookieStorage = {
  isCookieAccepted,
  setCookieAccepted 
};
