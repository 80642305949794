import React from 'react';
import PropTypes from 'prop-types';
import { Container, Segment } from 'semantic-ui-react';
import './ProductsSearchBar.css';

export class ProductsSearchBar extends React.Component {
  static propTypes = {
    renderSearch: PropTypes.func.isRequired,
  }

  render() {
    return (
      <Container fluid className="productsSearchBar">
        <Container>
          <Segment basic>
            { this.props.renderSearch() }
          </Segment>
        </Container>
      </Container>
    );
  }
}
