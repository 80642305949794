import React from 'react';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';
import { connect } from 'react-redux';
import { Container, Grid, Header, Icon, Loader, Segment, Message } from 'semantic-ui-react';
import { Button, Image } from 'semantic-ui-react';
import { Messages } from "../../../Messages";
import { ScrollToTopOnMount } from '../../../ScrollToTopOnMount';
import { fetchProduct, ProductDetailsState } from '../../../../redux/store';
import { fetchCategories, CategoryListState } from '../../../../redux/store';
import { resetSaveProductProcess, resetSaveProductForSaleProcess } from '../../../../redux/store';
import { SaveProductState, SaveProductForSaleState } from '../../../../redux/store';
import { resetDeleteProductPhotoProcess } from '../../../../redux/store';
import { deleteProductPhoto, DeleteProductPhotoState } from '../../../../redux/store';
import { resetUploadProductPhotoProcess } from '../../../../redux/store';
import { uploadProductPhoto, UploadProductPhotoState } from '../../../../redux/store';
import { ProductForSaleEditForm } from './ProductForSaleEditForm';
import { ProductEditForm } from './ProductEditForm';
import './ProductEdit.css';
import '../../Dropzone.css';

const MAX_PHOTOS_COUNT = 8;

class ProductEditPure extends React.PureComponent {
  static propTypes = {
    fetchProduct: PropTypes.func.isRequired,
    fetchCategories: PropTypes.func.isRequired,
    productDetails: PropTypes.object.isRequired,
    categoryList: PropTypes.object.isRequired,
    resetSaveProductProcess: PropTypes.func.isRequired,
    saveProductProcess: PropTypes.object.isRequired,
    resetSaveProductForSaleProcess: PropTypes.func.isRequired,
    saveProductForSaleProcess: PropTypes.object.isRequired,
    resetUploadProductPhotoProcess: PropTypes.func.isRequired,
    uploadProductPhoto: PropTypes.func.isRequired,
    uploadProductPhotoProcess: PropTypes.object.isRequired,
    resetDeleteProductPhotoProcess: PropTypes.func.isRequired,
    deleteProductPhoto: PropTypes.func.isRequired,
    deleteProductPhotoProcess: PropTypes.object.isRequired,
  }

  componentDidMount() {
    this.props.fetchProduct(this.props.match.params.id);
    this.props.fetchCategories();
    this.props.resetSaveProductProcess();
    this.props.resetSaveProductForSaleProcess();
    this.props.resetDeleteProductPhotoProcess();
    this.props.resetUploadProductPhotoProcess();
  }

  renderSaveProductSuccessMessage = () => {
    const state = this.props.saveProductProcess.state;
    return (
      state === SaveProductState.Success ? <Message success>Zapisano</Message> : null
    );
  };

  renderSaveProductForSaleSuccessMessage = () => {
    const state = this.props.saveProductForSaleProcess.state;
    return (
      state === SaveProductForSaleState.Success ? <Message success>Zapisano</Message> : null
    );
  }

  renderUploadPhotoErrorMessage = () => {
    const { uploadProductPhotoProcess } = this.props;
    const isError = [
      UploadProductPhotoState.ErrorNoPermissions, 
      UploadProductPhotoState.ErrorNotExists, 
      UploadProductPhotoState.ErrorUnknown
    ].indexOf(uploadProductPhotoProcess.state) !== -1;
    return isError
      ? <Message negative className="uploadError">
          <Message.Header>Ups!</Message.Header>
          Nie udało się zapisać zdjęcia. Spróbuj ponownie.
        </Message>
      : null;
  }

  renderDeletePhotoErrorMessage = () => {
    const { deleteProductPhotoProcess } = this.props;
    const isError = [
      DeleteProductPhotoState.ErrorNoPermissions, 
      DeleteProductPhotoState.ErrorNotExists, 
      DeleteProductPhotoState.ErrorUnknown
    ].indexOf(deleteProductPhotoProcess.state) !== -1;
    return isError
      ? <Message negative className="uploadError">
          <Message.Header>Ups!</Message.Header>
          Nie udało się usunąć zdjęcia. Spróbuj ponownie.
        </Message>
      : null;
  }

  handleFiles = (files) => {
    const { productDetails } = this.props;
    if (files.length > 0 && !!productDetails.product) {
      this.props.uploadProductPhoto(productDetails.product, files[0]);
    }
  }

  deleteProductPhoto = (product, image) => () => {
    this.props.deleteProductPhoto(product, image);
  }

  render() {
    const { categoryList, productDetails } = this.props;
    const inProgress = categoryList.state === CategoryListState.Fetching 
                    || productDetails.state === ProductDetailsState.Fetching;
    const productId = this.props.match.params.id;
    const productState = productDetails.state;
    const product = productDetails.product;
    const categoriesState = categoryList.state;
    const categories = categoryList.categories;

    const productInfo = product && productState === ProductDetailsState.Idle
      ? product.index
      : "Produkt " + productId;

    const productSubInfo = product && productState === ProductDetailsState.Idle
      ? product.name
      : "";

    const loaderView = inProgress
      ? <Segment basic>
          <Loader active={ true }/>
        </Segment>
      : null;

    const notExistsView = !inProgress && productState === ProductDetailsState.ErrorNotExists
      ? <Message negative>
          <Message.Header>Błąd</Message.Header>
          Produkt nie istnieje.
        </Message>
      : null;

    const isReady = product && productState === ProductDetailsState.Idle 
                 && categories && categoriesState === CategoryListState.Idle;

    const deleteInProgress = 
      this.props.deleteProductPhotoProcess.state === DeleteProductPhotoState.InProgress;

    const uploadInProgress =
      this.props.uploadProductPhotoProcess.state === UploadProductPhotoState.InProgress;

    const updateForm = isReady
      ? <Grid stackable>
          <Grid.Row columns={ 2 }>
            <Grid.Column>
              <Header textAlign="center" as='h3'>Szczegóły produktu</Header>
              <ProductEditForm product={ product } categories={ categories }/>
              { this.renderSaveProductSuccessMessage() }
            </Grid.Column>
            { product
              ? <Grid.Column>
                  <Header textAlign="center" as='h3'>Cena i wysyłka</Header>
                  <ProductForSaleEditForm productId={ product.id }
                                          price={ product.price } 
                                          postPackageDelivery={ product.postPackageDelivery }
                                          /> 
                  { this.renderSaveProductForSaleSuccessMessage() }
                </Grid.Column>
              : null
            }
          </Grid.Row>
          <Grid.Row columns={ 1 }>
            <Grid.Column>
              <Header as='h3'>Zdjęcia</Header>
              <Grid stackable>
                <Grid.Row>
                    { product.images.slice(0, MAX_PHOTOS_COUNT).map(i => {
                        return (
                          <Grid.Column key={ i } 
                                       width={ 2 } 
                                       verticalAlign="bottom" 
                                       textAlign="center">
                            <Image src={ i } size='small'/>
                            <Button className="deleteProductPhoto" 
                                    compact 
                                    size="mini" 
                                    color="red"
                                    icon="trash"
                                    disabled={ deleteInProgress }
                                    onClick={ this.deleteProductPhoto(product, i) }>
                            </Button>
                          </Grid.Column>
                        );
                      })
                    }
                  { product.images.length < MAX_PHOTOS_COUNT
                    ? <Grid.Column width={ 2 }>
                        <Dropzone className="dropzone" 
                                  onDrop={ this.handleFiles }
                                  accept="image/*"
                                  disabled={ uploadInProgress }
                                  >
                          <Segment className="dropzone" textAlign="center" secondary size='large'>
                            { uploadInProgress
                              ? <Loader active/>
                              : <span>Kliknij lub upuść plik tutaj.</span>
                            }
                          </Segment>
                        </Dropzone>
                      </Grid.Column>
                    : null
                  }
                </Grid.Row>
              </Grid>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      : null;

    const unknownErrorMessageView = !inProgress && 
                                    (productState === ProductDetailsState.ErrorUnknown ||
                                     categoriesState === CategoryListState.ErrorUnknown)
      ? Messages.UnknownError 
      : null;

    const noPermissionsMessage = !inProgress && productState === ProductDetailsState.ErrorNoPermissions
      ? Messages.NoPermissions 
      : null;

    return (
      <Container>
        <ScrollToTopOnMount/>
        <Header as='h1' icon textAlign='center'>
          <Icon name='pencil' circular/>
          <Header.Content>{ productInfo }</Header.Content>
          <Header.Subheader>{ productSubInfo }</Header.Subheader>
        </Header>
        { notExistsView }
        { unknownErrorMessageView }
        { noPermissionsMessage }
        { loaderView }
        { updateForm }
        { this.renderUploadPhotoErrorMessage() }
        { this.renderDeletePhotoErrorMessage() }
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  productDetails: state.store.productDetails,
  categoryList: state.store.categoryList,
  saveProductProcess: state.store.saveProductProcess,
  saveProductForSaleProcess: state.store.saveProductForSaleProcess,
  uploadProductPhotoProcess: state.store.uploadProductPhotoProcess,
  deleteProductPhotoProcess: state.store.deleteProductPhotoProcess,
});

const mapDispatchToProps = dispatch => ({
  fetchProduct: (pid) => dispatch(fetchProduct(pid, { admin: true })),
  fetchCategories: () => dispatch(fetchCategories()),
  uploadProductPhoto: (product, file) => dispatch(uploadProductPhoto(product, file)),
  deleteProductPhoto: (product, url) => dispatch(deleteProductPhoto(product, url)),
  resetSaveProductProcess: () => dispatch(resetSaveProductProcess()),
  resetUploadProductPhotoProcess: () => dispatch(resetUploadProductPhotoProcess()),
  resetDeleteProductPhotoProcess: () => dispatch(resetDeleteProductPhotoProcess()),
  resetSaveProductForSaleProcess: () => dispatch(resetSaveProductForSaleProcess()),
});

export const ProductEdit =
  connect(mapStateToProps, mapDispatchToProps)(ProductEditPure);
