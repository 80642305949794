import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { fetchPublicToken, PublicTokenState } from '../../../redux/public';
import { Button, Container, Divider, Header, Icon, Segment } from 'semantic-ui-react';
import { Messages } from '../../Messages';
import { ScrollToTopOnMount } from '../../ScrollToTopOnMount';
import './ApiInfo.css';
import Config from '../../../config';

class ApiInfoPure extends React.PureComponent {
  static propTypes = {
    fetchPublicToken: PropTypes.func.isRequired,
    publicToken: PropTypes.object.isRequired,
  }

  publicTokenError = () => {
    switch (this.props.publicToken.state) {
      case PublicTokenState.ErrorNoPermissions:
        return Messages.NoPermissions;
      case PublicTokenState.ErrorUnknown:
        return Messages.UnknownError;
      default:
        return null;
    }
  }

  componentDidMount() {
    this.props.fetchPublicToken();
  }

  render() {
    const token = this.props.publicToken.token ? this.props.publicToken.token : '<token>';
    const swaggerLink = `${Config.urlBase}/api/public/v1/swagger`;
    const xmlLink = `${Config.urlBase}/api/public/v1/feed/xml?apiKey=${token}`;
    const sellingoLink = `${Config.urlBase}/api/public/v1/feed/csv?format=sellingo&apiKey=${token}`;
    const xmlCurl = `curl ${Config.urlBase}/api/public/v1/feed/xml -H 'Authorization: Bearer ${token}'`;
    const jsonCurl = `curl ${Config.urlBase}/api/public/v1/products -H 'Authorization: Bearer ${token}'`;
    const fetchingToken = this.props.publicToken.state === PublicTokenState.FETCHING;
    const errorMessage = this.publicTokenError();
    let xmlButtonClassname = "ui button teal fluid";
    if (fetchingToken) xmlButtonClassname += " loading";

    return (
      <Container>
        <ScrollToTopOnMount/>
        <Header as='h1' icon textAlign='center'>
          <Icon name='plug' circular/>
          <Header.Content>Integracja</Header.Content>
        </Header>
        <Divider hidden/>
        { !!errorMessage
          ? <Container>{ errorMessage }</Container>
          : <Container text>
              <p>
                Naszą ofertę udostępniamy w dwóch formatach: pliku XML Feed oraz JSON (poprzez REST API).
                Dokumentację API (Swagger) znajdziesz <a href={ swaggerLink }>tutaj</a>.
              </p>

              <Header>XML Feed</Header>
              <p>
                W chwili obecnej wspieramy tylko jeden format pliku XML, którego opis znajdziesz w <a href={ swaggerLink }>dokumentacji</a>. Planujemy dodać kolejne, dedykowane dla popularnych platform.
              </p>
              <p>
                Plik XML Feed możesz pobrać klikając poniżej:
              </p>

              <p>
                <a className={ xmlButtonClassname } href={ fetchingToken ? "" : xmlLink } download="furnitex.xml">
                  <Icon name='download'/>
                  Pobierz XML Feed
                </a>
              </p>

              <Header>CSV Feed  dla Sellingo</Header>
              <p>
                Plik CSV z naszą ofertą dla platformy Sellingo możesz pobrać klikając poniżej. Instrukcję importu produktów znajdziesz <a href='https://sellingo.pl/pomoc/jak-zaimportowac-produkty-z-pliku-do-sklepu-internetowego-sellingo-jak-uzyc-importera-plikow'>w dokumentacji Sellingo</a>.
              </p>

              <p>
                <a className={ xmlButtonClassname } href={ fetchingToken ? "" : sellingoLink } download="furnitex-sellingo.csv">
                  <Icon name='download'/>
                  Pobierz CSV Feed
                </a>
              </p>

              <Header>API Key</Header>
              <p>
                Do korzystania z API wymagany jest klucz widoczny poniżej. Jego ważność jest nieograniczona.
              </p>

              <Segment loading={ fetchingToken }>
                <code>{ this.props.publicToken.token }</code>
              </Segment>

              <Header>Przykłady</Header>
              
              <p>
                Link do Twojego XML Feed jest następujący:
              </p>
              <Segment loading={ fetchingToken }>
                <code>{xmlLink}</code>
              </Segment>

              <p>
                Zapytanie programem cURL o XML Feed:
              </p>
              <Segment loading={ fetchingToken }>
                <code>{xmlCurl}</code>
              </Segment>

              <p>
                Pobranie listy produktów w formacie JSON:
              </p>
              <Segment loading={ fetchingToken }>
                <code>{jsonCurl}</code>
              </Segment>

              <p>
                Link do CSV Feed (Sellingo) jest następujący:
              </p>
              <Segment loading={ fetchingToken }>
                <code>{sellingoLink}</code>
              </Segment>

              <p>
                Więcej informacji znajdziesz w <a href={ swaggerLink }>dokumentacji</a>.
              </p>
            </Container>
        }
      </Container>
    );
  }
}

const mapStateToProps = state => {
  return {
    publicToken: state.public.publicToken,
  };
};

const mapDispatchToProps = dispatch => ({
  fetchPublicToken: () => dispatch(fetchPublicToken()),
});

export const ApiInfo =
  connect(mapStateToProps, mapDispatchToProps)(ApiInfoPure);
