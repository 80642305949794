import { CookieStorage } from '../storage/CookieStorage';

export function initializeCookieRedux(store) {
  store.subscribe(() => CookieStorage.setCookieAccepted(store.getState().cookie.isAccepted));
}

const initState = {
  isAccepted: CookieStorage.isCookieAccepted(),
};

export function cookieReducer(state = initState, action) {
  switch (action.type) {
    case 'ACCEPT_COOKIE': {
      const stateUpdate = { isAccepted: true };
      return Object.assign({}, state, stateUpdate);
    }
    default: {
      return state;
    }
  }
}

export function acceptCookie() {
  return {
    type: 'ACCEPT_COOKIE'
  };
}
