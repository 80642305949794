import React from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { Container, Icon, Header, Loader, Segment } from 'semantic-ui-react';
import { ProductEditForm } from './ProductEditForm';
import { ScrollToTopOnMount } from '../../../ScrollToTopOnMount';
import { fetchCategories, CategoryListState } from '../../../../redux/store';
import { resetSaveProductProcess, SaveProductState } from '../../../../redux/store';

class ProductAddPure extends React.PureComponent {
  static propTypes = {
    fetchCategories: PropTypes.func.isRequired,
    categoryList: PropTypes.object.isRequired,
    resetSaveProductProcess: PropTypes.func.isRequired,
    saveProductProcess: PropTypes.object.isRequired,
  }

  componentDidMount() {
    this.props.resetSaveProductProcess();
    this.props.fetchCategories();
  }

  state = { submitted: false };

  onSubmit = () => {
    this.setState({ submitted: true });
  }

  render() {
    const { categoryList, saveProductProcess } = this.props;
    const { state } = categoryList;
    const { product } = saveProductProcess;
    const loaderView = state === CategoryListState.Fetching 
      ? <Segment basic>
          <Loader active={ true }/>
        </Segment>
      : null;

    const editForm = state === CategoryListState.Idle && categoryList.categories
      ? <ProductEditForm categories={ categoryList.categories } onSubmit={ this.onSubmit }/>
      : null;

    const redirect = 
      this.props.saveProductProcess.state === SaveProductState.Success && this.state.submitted;

    return (
      <Container text>
        <ScrollToTopOnMount/>
        <Header as='h1' icon textAlign='center'>
          <Icon name='add' circular/>
          <Header.Content>Nowy produkt</Header.Content>
        </Header>
        { loaderView }
        { editForm }
        { redirect ? <Redirect to={ `/admin/products/${product.id}` }/> : null }
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  categoryList: state.store.categoryList,
  saveProductProcess: state.store.saveProductProcess,
});

const mapDispatchToProps = dispatch => ({
  fetchCategories: () => dispatch(fetchCategories()),
  resetSaveProductProcess: () => dispatch(resetSaveProductProcess()),
});

export const ProductAdd = connect(mapStateToProps, mapDispatchToProps)(ProductAddPure);
