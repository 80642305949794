import React from 'react';
import PropTypes from 'prop-types';
import { Button, Icon, Segment } from 'semantic-ui-react';
import './Filters.css';

export class FilterButton extends React.PureComponent {
  static propTypes = {
    active: PropTypes.bool,
    onClick: PropTypes.func,
    label: PropTypes.string.isRequired,
  }
  render() {
    return (
      <Button className="filter" 
              size="small"
              active={ this.props.active }
              onClick={ this.props.onClick }
              compact
              >
        { this.props.label }
        { this.props.active ? <Icon className='filter' name='close'/> : null }
      </Button>
    );
  }
}

export const FilterSegment = (props) => (
  <Segment basic className="filters" textAlign="center">
    { props.children }
  </Segment>
);
