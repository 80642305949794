import React from 'react';
import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';
import { Control, Errors, Form } from 'react-redux-form';
import { Messages } from '../../Messages';
import { isEmailValid, required, trimString } from '../../../utils/common';
import { PasswordRecoveryInitState } from '../../../redux/passwordRecovery';
import { initPasswordRecovery } from '../../../redux/passwordRecovery';

class ReduxPasswordRecoveryInitForm extends React.PureComponent {
  static props = {
    recoveryState: PropTypes.object.isRequired,
  }

  recoveryError = () => {
    switch (this.props.recoveryState) {
      case PasswordRecoveryInitState.ErrorUnknown:
        return Messages.UnknownError;
      default:
        return null;
    }
  }

  handleSubmit = (data) =>
    this.props.dispatch(initPasswordRecovery(data.emailAddress))

  render() {
    const inProgress = this.props.recoveryState === PasswordRecoveryInitState.InProgress;
    const formClassName = (inProgress ? "loading " : "") + "ui form";
    return (
      <Form
        className={ formClassName }
        model="passwordRecovery.form.recoveryInitData"
        onSubmit={ (data) => this.handleSubmit(data) }
      >
        <div className="field">
          <Control 
            model=".emailAddress" 
            placeholder="E-mail"
            validators={{ required, validEmail: isEmailValid }}
            validateOn="blur"
            parser={ trimString }
          />
          <Errors
            className="errors"
            model=".emailAddress"
            show="touched"
            messages={{
              required: 'Wymagane',
              validEmail: 'Niepoprawny adres email',
            }}
          />
        </div>

        <button className="ui button primary fluid" type="submit">
          Odzyskaj
        </button>

        { this.recoveryError() }

      </Form>
    );
  }
}

const mapStateToProps = state => ({
  recoveryState: state.passwordRecovery.recoveryInitProcess.state,
});

export const PasswordRecoveryInitForm =
  connect(mapStateToProps)(ReduxPasswordRecoveryInitForm);
