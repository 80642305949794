import React from 'react';
import { Message } from 'semantic-ui-react';

const InvalidPassword = (
  <Message negative>
    <Message.Header>Niepoprawne hasło</Message.Header>
    Wymagania: minimum 8 znaków, w tym 2 cyfry.
  </Message>
);

const UnknownError = (
  <Message negative>
    <Message.Header>Ups!</Message.Header>
    Nieznany błąd. Sprawdź połączenie z Internetem i spróbuj ponownie.
  </Message>
);

const NoPermissions = (
  <Message negative>
    <Message.Header>Brak uprawnień</Message.Header>
    Nie posiadasz wystarczających uprawnień do tego zasobu.
  </Message>
);

export const Messages = {
  InvalidPassword,
  UnknownError,
  NoPermissions
};
