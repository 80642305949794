import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { isAvailable } from '../../../utils/product';
import { Button, Checkbox, Form, Input } from 'semantic-ui-react';
import './ProductInBasketEdit.css';

export class ProductInBasketEdit extends React.PureComponent {
  static propTypes = {
    updateProductInBasket: PropTypes.func.isRequired,
    productInBasket: PropTypes.shape({
      product: PropTypes.object.isRequired,
      count: PropTypes.number.isRequired,
      withPostDelivery: PropTypes.bool.isRequired,
    }),
    inverted: PropTypes.bool,
    icon: PropTypes.string,
    iconPosition: PropTypes.string,
    buttons: PropTypes.string,
    basicButtons: PropTypes.bool,
  }

  state = {}
  constructor(props) {
    super(props);
    this.state = {
      hasFocus: false,
    };
  }

  onCountChange = (event) => {
    this.changeCount(Number(event.target.value));
  }

  changeCount = (count) => {
    const validatedCount = Number.isNaN(count) || count < 0 ? 0 : count;
    const { product, withPostDelivery } = this.props.productInBasket;
    this.props.updateProductInBasket({ product, count: validatedCount, withPostDelivery });
  }

  onBlur = () => {
    this.setState({ hasFocus: false });
  }

  onFocus = () => {
    this.setState({ hasFocus: true });
  }

  onDeliveryChange = (event, data) => {
    const { product, count } = this.props.productInBasket;
    this.props.updateProductInBasket({ product, count, withPostDelivery: data.checked });
  }

  addOne = () => {
    this.changeCount(this.props.productInBasket.count + 1);
  }

  delOne = () => {
    this.changeCount(this.props.productInBasket.count - 1);
  }

  presentedCount = (count) => (
    this.state.hasFocus
    ? count !== 0 ? count : ""
    : count !== 0 ? count + " szt." : ""
  );

  render() {
    const { product, count, withPostDelivery } = this.props.productInBasket;
    const presentedCount = this.presentedCount(count);
    const buttons = this.props.buttons;
    const deliveryAvailable = 
      product.postPackageDelivery && isAvailable(moment.now(), product.availableSince);

    const delButton = !!this.props.buttons
     ? <Button compact 
               basic={ this.props.basicButtons }
               negative={ !this.props.basicButtons }
               className="delete"
               attached={ buttons === "side" ? "left" : undefined } 
               icon="minus" 
               onClick={ this.delOne } />
     : null;

    const addButton = !!this.props.buttons
     ? <Button compact 
               basic={ this.props.basicButtons }
               positive={ !this.props.basicButtons }
               className="add"
               attached={ buttons === "side" ? "right" : undefined }
               icon="plus" 
               onClick={ this.addOne } />
     : null;

    return (
      <Form inverted={ this.props.inverted } className="productInBasketEdit">
        <Form.Field>
          { buttons === "side" ? delButton : null }
          <Input value={ presentedCount }
                 iconPosition={ this.props.iconPosition }
                 icon={ this.props.icon }
                 min="0"
                 placeholder="0 szt."
                 onChange={ this.onCountChange }
                 onFocus={ this.onFocus }
                 onBlur={ this.onBlur }
                 />
          { buttons === "bottom" ? delButton : null }
          { addButton }
        </Form.Field>
        <Form.Field>
          <Checkbox 
            checked={ withPostDelivery }
            label="dostawa 24h"
            onChange={ this.onDeliveryChange }
            className={ deliveryAvailable ? "" : "noPostPackageDelivery" }
            disabled={ !deliveryAvailable }
            />
        </Form.Field>
      </Form>
    );
  }
}
