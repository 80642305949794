import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Container, Divider, Header, Icon } from 'semantic-ui-react';
import { ScrollToTopOnMount } from '../../ScrollToTopOnMount';
import { fetchOrderSummaries } from '../../../redux/orders.js';
import { OrdersList } from './OrdersList';
import "./Orders";

export class OrdersPure extends React.PureComponent {
  static propTypes = {
    fetchOrderSummaries: PropTypes.func.isRequired,
    orderSummaryList: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    customerId: PropTypes.number,
  }

  orderSelected = (orderId) => {
    const redirectUrl = this.props.location.pathname + '/' + orderId;
    this.props.history.push(redirectUrl);
  }

  render() {
    return (
      <Container>
        <ScrollToTopOnMount/>
        <Header as='h1' icon textAlign='center'>
          <Icon name='list ul' circular/>
          <Header.Content>Lista zamówień</Header.Content>
        </Header>
        <Divider hidden/>
        <OrdersList fetchOrderSummaries={ this.props.fetchOrderSummaries }
                    orderSummaryList={ this.props.orderSummaryList }
                    history={ this.props.history }
                    customerId={ this.props.customerId }
                    onOrderSelected={ this.orderSelected }
                    />
      </Container>
    );
  }
}

const mapStateToProps = state => {
  const session = state.login.session;
  return {
    orderSummaryList: state.orders.orderSummaryList,
    customerId: session.customer ? session.customer.id : null,
    requireCustomerId: true,
  };
};

const mapDispatchToProps = dispatch => ({
  fetchOrderSummaries: (take, customerId, ordersState) => 
    dispatch(fetchOrderSummaries({ take, customerId, ordersState })),
});

export const Orders =
  connect(mapStateToProps, mapDispatchToProps)(OrdersPure);
