import { combineForms } from 'react-redux-form';
import { combineReducers } from 'redux';
import { SessionStorage } from '../storage/SessionStorage';
import { UpdateCustomerState } from './customers';
import { UpdateUserState } from './registration';

export function initializeLoginRedux(store) {
  store.subscribe(() => SessionStorage.store(store.getState().login.session));
}

const initCredentials = {
  emailAddress: '',
  password: ''
};

export const LoginState = {
  Idle: 'IDLE',
  InProgress: 'IN_PROGRESS',
  Success: 'SUCCESS',
  ErrorInvalidCredentials: 'ERROR_INVALID_CREDENTIALS',
  ErrorInactiveUser: 'ERROR_INACTIVE_USER',
  ErrorUnknown: 'ERROR_UNKNOWN'
};

const initProcessState = {
  state: LoginState.Idle,
};

function processReducer(state = initProcessState, action) {
  switch (action.type) {
    case 'LOGIN_STARTED': {
      const stateUpdate = { state: LoginState.InProgress };
      return Object.assign({}, state, stateUpdate);
    }
    case 'LOGIN_FINISHED': {
      const stateUpdate = { state: action.state };
      return Object.assign({}, state, stateUpdate);
    }
    case 'LOGGED_OUT':
    case 'UNAUTHORIZED': {
      const stateUpdate = { state: LoginState.Idle };
      return Object.assign({}, state, stateUpdate);
    }
    default: {
      return state;
    }
  }
}

const initSessionState =
  Object.assign({}, SessionStorage.load(), { isLoginRequired: false });

function sessionReducer(state = initSessionState, action) {
  switch (action.type) {
    case 'LOGIN_STARTED': {
      const stateUpdate = { user: null, token: null, customer: null };
      return Object.assign({}, state, stateUpdate);
    }
    case 'LOGIN_FINISHED': {
      if (action.state === LoginState.Success) {
        const stateUpdate = {
          user: action.user,
          token: action.token,
          customer: action.customer,
          isLoginRequired: false
        };
        return Object.assign({}, state, stateUpdate);
      } else {
        const stateUpdate = { user: null, token: null, customer: null };
        return Object.assign({}, state, stateUpdate);
      }
    }
    case 'LOGGED_OUT': {
      const stateUpdate = { user: null, token: null, customer: null, isLoginRequired: false };
      return Object.assign({}, state, stateUpdate);
    }
    case 'CUSTOMER_REGISTRATION_FINISHED': {
      const stateUpdate = { customer: action.customer };
      return Object.assign({}, state, stateUpdate);
    }
    case 'UPDATE_CUSTOMER_FINISHED': {
      if (action.state === UpdateCustomerState.Success &&
          !!state.customer &&
          action.customer.id === state.customer.id) {
        const stateUpdate = { customer: action.customer };
        return Object.assign({}, state, stateUpdate);
      }
      return state;
    }
    case 'UPDATE_USER_FINISHED': {
      if (action.state === UpdateUserState.Success &&
          action.user.emailAddress === state.user.emailAddress) {
        const stateUpdate = { user: action.user };
        return Object.assign({}, state, stateUpdate);
      }
      return state;
    }
    case 'UNAUTHORIZED': {
      const stateUpdate = {
        user: null,
        token: null,
        customer: null,
        isLoginRequired: true
      };
      return Object.assign({}, state, stateUpdate);
    }
    default: {
      return state;
    }
  }
}

function loginStarted() {
  return {
    type: 'LOGIN_STARTED'
  };
}

function loginFinished(state, { user, token, customer } = {}) {
  return {
    type: 'LOGIN_FINISHED',
    state: state,
    user: user,
    token: token,
    customer: customer
  };
}

export function login(credentials) {
  return (dispatch, getState, { AuthApi, WholesaleApi }) => {
    dispatch(loginStarted());
    return AuthApi.login(credentials).then(
      loginResponse => WholesaleApi.sessionCustomer(loginResponse.token)
        .then(
          customerResponse => { return { loginResponse, customerResponse }; },
          customerError => { return { loginResponse, customerError }; }
        )
      ).then(
        response => {
          const customerError = response.customerError;
          if (customerError && customerError !== WholesaleApi.SessionCustomerError.NotExists) {
            dispatch(loginFinished(LoginState.ErrorUnknown));
          } else {
            dispatch(
              loginFinished(LoginState.Success, {
                user: response.loginResponse.user,
                token: response.loginResponse.token,
                customer: response.customerResponse
              })
            );
          }
        },
        error => {
          switch (error) {
            case AuthApi.LoginError.InvalidCredentials:
              dispatch(loginFinished(LoginState.ErrorInvalidCredentials));
              break;
            case AuthApi.LoginError.InactiveUser:
              dispatch(loginFinished(LoginState.ErrorInactiveUser));
              break;
            default:
              dispatch(loginFinished(LoginState.ErrorUnknown));
          }
        }
      );
  };
}

export function logout() {
  return {
    type: 'LOGGED_OUT',
  };
}

export function unauthorized() {
  return {
    type: 'UNAUTHORIZED'
  };
}

export function loginReducer() {
  return combineReducers({
    form: combineForms({
      credentials: initCredentials
    }, 'login.form'),
    process: processReducer,
    session: sessionReducer
  });
}
