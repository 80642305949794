import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import * as R from 'ramda';
import { isAvailable, availabilityDateText } from '../../../utils/product';
import { Link } from 'react-router-dom';
import { Button, Card, Grid, Dimmer, Icon, Header, Label, Image } from 'semantic-ui-react';
import { Segment } from 'semantic-ui-react';
import { FullscreenPhotoShow } from '../../FullscreenPhotoShow';
import { PriceLabel, AvailabilityLabel, NewLabel } from './ProductLabels';
import { ProductInBasketEdit } from '../Orders/ProductInBasketEdit';
import { productPrice, productTotalNetPriceValue, roundPriceValue } from '../../../utils/price';
import { productPostDeliveryNetPriceValue } from '../../../utils/price';
import { PriceText } from '../../PriceText';
import { tryResizeImage } from '../../../utils/common';
import Config from '../../../config';
import './ProductCardsGrid.css';

export class ProductCardsGrid extends React.PureComponent {
  static propTypes = {
    products: PropTypes.arrayOf(PropTypes.object).isRequired,
    updateProductInBasket: PropTypes.func.isRequired,
    newOrder: PropTypes.object.isRequired,
    isCustomer: PropTypes.bool.isRequired,
    isAdmin: PropTypes.bool.isRequired,
    openProductPage: PropTypes.func.isRequired,
  }

  render() {
    const { products, newOrder, updateProductInBasket, isCustomer, isAdmin } = this.props;

    const productCards = products.map(product => {
      const productInBasket = newOrder.productsInBasket[product.id] ?
        newOrder.productsInBasket[product.id] : { product, count: 0, withPostDelivery: false };
      return (
        <ProductCard productInBasket={ productInBasket }
                     updateProductInBasket={ updateProductInBasket }
                     isCustomer={ isCustomer }
                     isAdmin={ isAdmin }
                     key={ `${product.id}` }
                     openProductPage={ this.props.openProductPage }
                     />
      );
    });

    return (
      <Card.Group itemsPerRow={4} doubling>
        { productCards }
      </Card.Group>
    );
  }
}

class ProductCard extends React.Component {
  static propTypes = {
    updateProductInBasket: PropTypes.func.isRequired,
    productInBasket: PropTypes.shape({
      product: PropTypes.object.isRequired,
      count: PropTypes.number.isRequired,
      withPostDelivery: PropTypes.bool.isRequired,
    }),
    isCustomer: PropTypes.bool.isRequired,
    isAdmin: PropTypes.bool.isRequired,
    openProductPage: PropTypes.func.isRequired,
  }

  state = { active: false, showPhotos: false }

  maybeShowProductInBasketEdit = () => {
    const price = this.props.productInBasket.product.price;
    const { isCustomer } = this.props;
    this.setState({ active: isCustomer && price !== undefined });
  }

  hideProductInBasketEdit = () => 
    this.setState({ active: false });

  showPhotos = () => this.setState({ showPhotos: true });

  hidePhotos = () => this.setState({ showPhotos: false });

  shouldComponentUpdate(nextProps, nextState) {
    if (this.state.active !== nextState.active ||
        this.state.showPhotos !== nextState.showPhotos ||
        this.state.photoIndex !== nextState.photoIndex ||
        this.props.productInBasket.count !== nextProps.productInBasket.count ||
        this.props.productInBasket.withPostDelivery !== nextProps.productInBasket.withPostDelivery) {
      return true;
    }
    // NOTE: Not all real update conditions here, but the one above is enough for now.
    return false;
  }

  openProductPage = () => {
    const { product } = this.props.productInBasket;
    this.props.openProductPage(product.id);
  }

  imagesToFullscreenSize = (images) =>
    images.map(i => tryResizeImage(i, Config.imgWidth.fullscreen));

  countOrAvailabilityLabel = (now, count, netPrice, withPostDelivery, availableSince) => {
    const available = () => isAvailable(now, availableSince);
    const countInfoVisible = () => count > 0;
    const baseText = () => 
      count + " szt." + (withPostDelivery ? " + 24h" : "") + " = " + netPrice + " PLN";

    const renderAddedAvailable = () => 
      <Label attached="top" className="topInfoLabel">
        <Icon name="shop"/>
        { baseText() }
      </Label>;

    const renderAddedUnavailable = () =>
      <Label attached="top" className="topInfoLabel">
        <Icon name="shop"/>
        { baseText() + " — "}
        <span className="unavailable">niedostępny</span>
      </Label>;

    const renderAvailable = () => null;

    const renderUnavailable = () => 
      <Label attached="top left" className="topInfoLabel unavailable">
        { availabilityDateText(now, availableSince) }
      </Label>;

    return R.ifElse(
      countInfoVisible, 
      R.ifElse(available, renderAddedAvailable, renderAddedUnavailable),
      R.ifElse(available, renderAvailable, renderUnavailable),
    )();
  }

  render() {
    const { active } = this.state;
    const { productInBasket, updateProductInBasket, isAdmin, isCustomer } = this.props;
    const { product, count, withPostDelivery } = productInBasket;
    const price = productPrice(product);
    const netPrice = roundPriceValue(productTotalNetPriceValue(product, count, withPostDelivery));
    const deliveryPrice = withPostDelivery 
      ? roundPriceValue(productPostDeliveryNetPriceValue(product, count)) : 0;
    const infoLabel = this.countOrAvailabilityLabel(
      moment.now(), 
      count, 
      netPrice, 
      withPostDelivery, 
      product.availableSince
    );

    const photos = this.state.showPhotos 
      ? <FullscreenPhotoShow images={ this.imagesToFullscreenSize(product.images) } 
                             onCloseRequest={ this.hidePhotos }
                             />
      : null;

    const card =
      <Dimmer.Dimmable
        blurring
        dimmed={ active }
        onMouseEnter={ this.maybeShowProductInBasketEdit }
        onMouseLeave={ this.hideProductInBasketEdit }>
        <Dimmer active={ active }>
          <Segment basic className="thumbnailSegment">
            <AvailabilityLabel availableSince={ product.availableSince } attached="top"/>
            <Header inverted as="h4">
              <PriceText price={ price }/>
            </Header>
            <ProductInBasketEdit
              productInBasket={ productInBasket }
              updateProductInBasket={ updateProductInBasket }
              buttons="side"
              icon="shop"
              iconPosition="left"
              inverted/>
              <div className="totalPrice">cena łącznie: <b>{ netPrice } PLN</b></div>
              <div className="totalPrice">w tym dostawa: <b>{ deliveryPrice } PLN</b></div>
            <Segment basic className="buttonsSegment">
              <Grid stackable>
                <Grid.Row>
                  <Grid.Column width={ 8 } className="leftColumn">
                    <Button fluid inverted compact onClick={ this.openProductPage }>
                      Szczegóły
                    </Button>
                  </Grid.Column>
                  <Grid.Column width={ 8 } className="rightColumn">
                    <Button fluid 
                            inverted 
                            compact 
                            disabled={ product.images.length === 0 }
                            onClick={ this.showPhotos }
                            >
                      Zdjęcia
                    </Button>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Segment>
          </Segment>
        </Dimmer>
        <Segment basic className="thumbnailSegment">
          { infoLabel }
          <div className="productThumbnail">
            <Image ui={ false } 
                   alt={ product.index } 
                   src={ tryResizeImage(product.images[0], Config.imgWidth.thumbnail) } 
                   onClick={ this.openProductPage }/>
          </div>
          <NewLabel product={ product } attached="bottom left" basic/>
          <PriceLabel attached="bottom right" price={ price } retail={ !isAdmin && !isCustomer }/>
        </Segment>
      </Dimmer.Dimmable>;

    return (
      <Card className="productCard">
        { photos }
        { card }
        <Card.Content>
          <Card.Header>
            <Link to={ "/products/" + product.id }>
              { product.index }
            </Link>
          </Card.Header>
          <Card.Meta>{ product.name }</Card.Meta>
        </Card.Content>
      </Card>
    );
  }
}
