import React from 'react';
import { connect } from 'react-redux';
import { Warning } from '../Warning';
import { acceptCookie } from '../../redux/cookie';

function CookieWarningPure({ onDismiss, isCookieAccepted }) {
  return (
    isCookieAccepted
    ? null
    : <Warning 
        headerText='Uwaga' 
        contentText={ 'Ten serwis wykorzystuje pliki cookies. ' +
                      'Korzystanie z witryny oznacza zgodę na ich zapis lub odczyt ' +
                      'zgodnie z ustawieniami przeglądarki.' }
        onDismiss={ onDismiss }
        />
  );
}

const mapStateToProps = state => ({
  isCookieAccepted: state.cookie.isAccepted
});

const mapDispatchToProps = dispatch => ({
  onDismiss: () => dispatch(acceptCookie()),
});

export const CookieWarning = connect(mapStateToProps, mapDispatchToProps)(CookieWarningPure);
