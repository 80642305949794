import Config from '../config';
import { get, post, put, url, del, postFile } from './ApiClient';

function storeUrl(path, params) {
  return url(Config.endpoints.store, path, params);
}

function categories() {
  return get(storeUrl('categories'));
}

const DeleteCategoryError = {
  Unauthorized: 'UNAUTHORIZED',
  Forbidden: 'FORBIDDEN',
  ContainsProducts: 'CONTAINS_PRODUCTS',
  NotExists: 'NOT_EXISTS',
  Unknown: 'UNKNOWN',
};

function deleteCategory(tag, token) {
  return del(storeUrl(`categories/${tag}`), token).then(
    response => response,
    error => {
      switch (error.statusText) {
        case 'Not Found':
          return Promise.reject(DeleteCategoryError.NotExists);

        case 'Bad Request':
          return Promise.reject(DeleteCategoryError.ContainsProducts);

        case 'Unauthorized':
          return Promise.reject(DeleteCategoryError.Unauthorized);

        case 'Forbidden':
          return Promise.reject(DeleteCategoryError.Forbidden);

        default:
          return Promise.reject(DeleteCategoryError.Unknown);
      }
    }
  );
}

const UpsertCategoryError = {
  Unauthorized: 'UNAUTHORIZED',
  Forbidden: 'FORBIDDEN',
  Unknown: 'UNKNOWN',
};

function upsertCategory(category, token) {
  const { name, tag } = category;
  return put(storeUrl(`categories/${tag}`), { name }, token).then(
    response => response,
    error => {
      switch (error.statusText) {
        case 'Unauthorized':
          return Promise.reject(UpsertCategoryError.Unauthorized);

        case 'Forbidden':
          return Promise.reject(UpsertCategoryError.Forbidden);

        default:
          return Promise.reject(UpsertCategoryError.Unknown);
      }
    }
  );
}

function products({ sortBy, categoryTag, searchQuery, flags, sortOrder, lastIndex, lastId, take }) {
  let params = {
    sortBy,
    categoryTag,
    searchQuery,
    flags,
    lastIndex,
    lastId,
    take,
    sortOrder
  };
  return get(storeUrl("products", params));
}

const ProductDetailsError = {
  NotExists: 'NOT_EXISTS',
  Unkown: 'UNKNOWN',
};

function productDetails(id) {
  return get(storeUrl(`products/${id}`)).then(
    response => response,
    error => {
      switch (error.statusText) {
        case 'Not Found':
          return Promise.reject(ProductDetailsError.NotExists);
        default:
          return Promise.reject(ProductDetailsError.Unknown);
        }
      }
  );
}

const CreateProductError = {
  Unauthorized: 'UNAUTHORIZED',
  Forbidden: 'FORBIDDEN',
  Unknown: 'UNKNOWN',
  IndexGroupFull: 'INDEX_GROUP_FULL',
};

function createProduct(data, token) {
  return post(storeUrl('products'), data, token).then(
    response => response,
    error => {
      switch (error.statusText) {
        case 'Unauthorized':
          return Promise.reject(CreateProductError.Unauthorized);
        case 'Forbidden':
          return Promise.reject(CreateProductError.Forbidden);
        case 'Conflict':
          return Promise.reject(CreateProductError.IndexGroupFull);
        default:
          return Promise.reject(CreateProductError.Unknown);
      }
    }
  );
}

const UpdateProductError = {
  NotExists: 'NOT_EXISTS',
  Unauthorized: 'UNAUTHORIZED',
  Forbidden: 'FORBIDDEN',
  Unknown: 'UNKNOWN',
};

function updateProduct(id, data, token) {
  return put(storeUrl(`products/${id}`), data, token).then(
    response => response,
    error => {
      switch (error.statusText) {
        case 'Not Found':
          return Promise.reject(UpdateProductError.NotExists);
        case 'Unauthorized':
          return Promise.reject(UpdateProductError.Unauthorized);
        case 'Forbidden':
          return Promise.reject(UpdateProductError.Forbidden);
        default:
          return Promise.reject(UpdateProductError.Unknown);
      }
    }
  );
}

const ProductPhotoUploadUrlError = {
  NotExists: 'NOT_EXISTS',
  Unauthorized: 'UNAUTHORIZED',
  Forbidden: 'FORBIDDEN',
  Unknown: 'UNKNOWN',
};

function requestProductPhotoUploadUrl(productId, token) {
  return post(storeUrl(`products/${productId}/image-upload-url-request`), null, token).then(
    response => response,
    error => {
      switch (error.statusText) {
        case 'Not Found':
          return Promise.reject(ProductPhotoUploadUrlError.NotExists);
        case 'Unauthorized':
          return Promise.reject(ProductPhotoUploadUrlError.Unauthorized);
        case 'Forbidden':
          return Promise.reject(ProductPhotoUploadUrlError.Forbidden);
        default:
          return Promise.reject(ProductPhotoUploadUrlError.Unknown);
      }
    }
  );
}

function uploadPhoto({ url, signature }, file) {
  return postFile(url, signature, file);
}

const StoreApi = {
  categories,
  products,
  ProductDetailsError,
  productDetails,
  CreateProductError,
  createProduct,
  UpdateProductError,
  updateProduct,
  ProductPhotoUploadUrlError,
  requestProductPhotoUploadUrl,
  uploadPhoto,
  deleteCategory,
  DeleteCategoryError,
  upsertCategory,
  UpsertCategoryError,
};

export default StoreApi;