import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { Messages } from "../../Messages"; 

import { Button, Container, Dimmer, Divider, Grid, Header, Icon, Loader, Modal, Placeholder, Segment } from 'semantic-ui-react';
import { fetchFileDocumentDownloadUrl, FileDocumentDownloadUrlState } from '../../../redux/fileDocuments';

export class FileDocumentModal extends React.PureComponent {
  static propTypes = {
    isOpen: PropTypes.bool.isRequired,
    close: PropTypes.func.isRequired,
    fileDocument: PropTypes.object,
    deleteFileDocument: PropTypes.func,
    fileDocumentDownloadUrl: PropTypes.object.isRequired,
    fetchFileDocumentDownloadUrl: PropTypes.func.isRequired,
  }

  componentDidUpdate(prevProps) {
    if (!!this.props.fileDocument && this.props.fileDocument !== prevProps.fileDocument) {
      fetchFileDocumentDownloadUrl(this.props.fileDocument.uuid);
    }
  }

  maybeFetchFileDocumentDownloadUrlError() {
    switch (this.props.fileDocumentDownloadUrl.state) {
      case FileDocumentDownloadUrlState.ErrorNoPermissions: {
        return Messages.NoPermissions;
      }
      case FileDocumentDownloadUrlState.ErrorUnknown: {
        return Messages.UnknownError;
      }
      default: {
        return null;
      }
    }
  }

  render() {
    if (!this.props.isOpen)
      return null;

    const deleteButton = this.props.deleteFileDocument
      ? <Button negative onClick={ this.props.deleteFileDocument }>
          <Icon name='trash'/>
          Usuń
        </Button>
      : null;

    const fileDocumentDownloadUrlState = this.props.fileDocumentDownloadUrl.state;

    const downloadUrl = this.props.fileDocumentDownloadUrl.url;

    const canDownload = fileDocumentDownloadUrlState === FileDocumentDownloadUrlState.Idle && !!downloadUrl;

    const isLoading = fileDocumentDownloadUrlState === FileDocumentDownloadUrlState.Fetching;

    const loader = isLoading
      ? <Segment basic>
          <Dimmer active inverted>
            <Loader active/>
          </Dimmer>
        </Segment>
      : null;

    const content = canDownload
      ? <Container>
          Nazwa dokumentu:<br/><b>{ this.props.fileDocument.name }</b><br/><br/>
          Data utworzenia:<br/><b>{ moment(this.props.fileDocument.created).format('DD-MM-YYYY, HH:mm') }</b>
        </Container>
      : null;

    const buttons = canDownload
      ? <>
          <Divider hidden/>
          <Grid>
            <Grid.Column width={8} textAlign="left">
              { canDownload 
                  ? <a className="ui button positive" href={ downloadUrl }>
                      <Icon name='download'/>
                      Pobierz
                    </a>
                  : null
              }
            </Grid.Column>
            <Grid.Column width={8} textAlign="right">
              { deleteButton }
            </Grid.Column>
          </Grid>
        </>
      : null;

    return (
      <Modal closeIcon
             size='tiny' 
             open={ this.props.isOpen }
             onClose={ this.props.close }
             >
        <Header icon>
          <Icon name='file outline'/>
          { this.props.fileDocument.fileName }
        </Header>
        <Modal.Content>
          { content }
          { this.maybeFetchFileDocumentDownloadUrlError() }
          { loader }
          { buttons }
        </Modal.Content>
      </Modal>
    );
  }
}