import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Container, Icon, Header, Loader, Message, Segment } from 'semantic-ui-react';
import { Button, Divider, Table } from 'semantic-ui-react';
import { Messages } from '../../../Messages';
import { SimpleText } from '../../../SimpleText';
import { roundPriceValue, grossPrice } from '../../../../utils/price';
import { fetchOrder, OrderDetailsState, initializeOrderToUpdate } from '../../../../redux/orders';
import { changeOrderToUpdateState, updateOrderedProduct } from '../../../../redux/orders';
import { resetUpdateOrderProcess, updateOrder, UpdateOrderState } from '../../../../redux/orders';
import { ScrollToTopOnMount } from '../../../ScrollToTopOnMount';
import { CantEditInCurrentStateModal } from './CantEditInCurrentStateModal';
import { OrderedProductEditModal } from './OrderedProductEditModal';
import { OrderedProductsList } from './OrderedProductsList';
import { PrintableOrder } from '../../Orders/PrintableOrder';
import { PrintTrigger } from '../../PrintTrigger';
import './Orders.css';

class OrderPure extends React.PureComponent {
  static propTypes = {
    orderDetails: PropTypes.object.isRequired,
    fetchOrder: PropTypes.func.isRequired,
    orderToUpdate: PropTypes.object.isRequired,
    initializeOrderToUpdate: PropTypes.func.isRequired,
    changeOrderToUpdateState: PropTypes.func.isRequired,
    updateOrderedProduct: PropTypes.func.isRequired,
    updateOrder: PropTypes.func.isRequired,
    updateOrderProcess: PropTypes.object.isRequired,
    resetUpdateOrderProcess: PropTypes.func.isRequired,
  }

  state = { 
    isProductEditModalOpen: false, 
    editedProduct: null,
    isCantEditInCurrentStateModalOpen: false,
  }

  componentDidMount() {
    this.props.resetUpdateOrderProcess();
    this.props.fetchOrder(this.props.match.params.id).then(result => { 
      if (result && result.order) { 
        this.props.initializeOrderToUpdate(result.order);
      }
    });
  }

  resetChanges = () =>
    this.props.initializeOrderToUpdate(this.props.orderDetails.order);

  order = () =>
    this.props.orderDetails.state === OrderDetailsState.Idle 
      && this.props.orderDetails.order 
      && this.props.orderDetails.order.id === this.props.orderToUpdate.id
    ? this.props.orderDetails.order
    : null;

  orderState = () => this.props.orderToUpdate.state;

  showProductEditModal = (product) => this.setState({ 
    isProductEditModalOpen: true,
    editedProduct: product,
  });

  closeProductEditModal = () => this.setState({ isProductEditModalOpen: false });

  renderProductEditModal = () => {
    if (!this.state.isProductEditModalOpen) {
      return null;
    }
    return (
      <OrderedProductEditModal 
        product={ this.state.editedProduct }
        onClose={ this.closeProductEditModal }
        edit={ this.props.updateOrderedProduct }
      />
    );
  }

  showCantEditInCurrentStateModal = () => 
    this.setState({ isCantEditInCurrentStateModalOpen: true });

  closeCantEditInCurrentStateModal = () => 
    this.setState({ isCantEditInCurrentStateModalOpen: false });

  renderCantEditInCurrentStateModal = () => (
    <CantEditInCurrentStateModal
      open={ this.state.isCantEditInCurrentStateModalOpen } 
      onClose={ this.closeCantEditInCurrentStateModal }
    />
  );

  closeOrder = () => 
    this.props.changeOrderToUpdateState('closed');

  renderSavingLoader = () => (
    <Segment basic>
      <Loader active/>
    </Segment>
  );

  renderSuccessMessage = () => (
    <Message success>Zapisano</Message>
  );

  renderUnknownError() {
    return (
      <Message negative>
        <Message.Header>Ups!</Message.Header>
        Wystąpił błąd podczas aktualizacji zamówienia. Spróbuj ponownie.
      </Message>
    );
  }

  renderNoPermissions() {
    return (
      <Message negative>
        <Message.Header>Brak uprawnień</Message.Header>
        Nie posiadasz wystarczających uprawnień do aktualizacji zamówienia.
      </Message>
    );
  }

  renderNotExists() {
    return (
      <Message negative>
        <Message.Header>Nie odnaleziono</Message.Header>
        Zamówienie nie istnieje. Prawdopodobnie zostało usunięte.
      </Message>
    );
  }

  renderUpdateOrderProcessState = () => {
    const state = this.props.updateOrderProcess.state;
    if (state === UpdateOrderState.Idle) {
      return null;
    }
    return (
      <Segment basic textAlign="center">
        { state === UpdateOrderState.InProgress ? this.renderSavingLoader() : null }
        { state === UpdateOrderState.Success ? this.renderSuccessMessage() : null }
        { state === UpdateOrderState.ErrorNoPermissions ? this.renderNoPermissions() : null }
        { state === UpdateOrderState.ErrorNotExists ? this.renderNotExists() : null }
        { state === UpdateOrderState.ErrorUnknown ? this.renderUnknownError() : null }
      </Segment>
    );
  };

  changeOrderToUpdateState = (to) => () => this.props.changeOrderToUpdateState(to);

  render() {
    const orderId = this.props.match.params.id;
    const state = this.props.orderDetails.state;
    const orderToUpdate = this.props.orderToUpdate;
    const orderedProducts = R.values(orderToUpdate.orderedProducts);
    const order = this.order();
    const totalProductsCount = R.reduce((acc, p) => acc + p.count, 0, orderedProducts);

    const printableOrder = order && state === OrderDetailsState.Idle
      ? <PrintableOrder order={ order } ref={ el => (this.printRef = el) }/>
      : null;

    const printTrigger = order && state === OrderDetailsState.Idle
      ? <PrintTrigger content={ () => this.printRef }/>
      : null;

    const loaderView = state === OrderDetailsState.Fetching 
      ? <Segment basic>
          <Loader active={ true }/>
        </Segment>
      : null;

    const customerInfoRow = order && order.customerInfo
      ? <Table.Row>
          <Table.Cell width={ 2 }><b>Uwagi</b></Table.Cell>
          <Table.Cell>
            <SimpleText text={ order.customerInfo }/>
          </Table.Cell>
        </Table.Row>
      : null;

    const customerAdditionalInfoRow = order && order.customer.additionalInfo
      ? <Table.Row>
          <Table.Cell width={ 2 }><b>Informacja</b></Table.Cell>
          <Table.Cell>
            <SimpleText text={ order.customer.additionalInfo }/>
          </Table.Cell>
        </Table.Row>
      : null;

    const totalNetValue = order ? orderToUpdate.totalNetPriceValue : null;

    const orderDetailsView = order
      ? <div>
          <Segment basic>
            <Table basic='very'>
              <Table.Body>
                <Table.Row>
                  <Table.Cell width={ 2 }><b>Klient</b></Table.Cell>
                  <Table.Cell>
                    <Link to={ `/admin/customers/${order.customer.id}` } className="customerLink">
                      { order.customer.company.name }
                    </Link>
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell width={ 2 }><b>NIP</b></Table.Cell>
                  <Table.Cell>{ order.customer.company.nip }</Table.Cell>
                </Table.Row>
                { customerAdditionalInfoRow }
                <Table.Row>
                  <Table.Cell width={ 2 }><b>Imię</b></Table.Cell>
                  <Table.Cell>{ order.customer.firstName }</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell width={ 2 }><b>Nazwisko</b></Table.Cell>
                  <Table.Cell>{ order.customer.lastName }</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell width={ 2 }><b>Email</b></Table.Cell>
                  <Table.Cell>{ order.customer.emailAddress }</Table.Cell>
                </Table.Row>
                { customerInfoRow }
                <Table.Row>
                  <Table.Cell width={ 2 }><b>Wartość (netto)</b></Table.Cell>
                  <Table.Cell>{ roundPriceValue(totalNetValue) } PLN</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell width={ 2 }><b>Wartość (brutto)</b></Table.Cell>
                  <Table.Cell>{ roundPriceValue(grossPrice(totalNetValue)) } PLN</Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>
          </Segment>
          <Divider/>
          <Segment basic>
            <OrderedProductsList 
              orderState={ orderToUpdate.state }
              products={ orderedProducts }
              editProduct={ this.showProductEditModal }
              onCantEditInCurrentState={ this.showCantEditInCurrentStateModal }
              />
          </Segment>
          <Divider/>
        </div>
      : null;

    const orderDateSubheaderView = order
      ? <Header.Subheader>{ moment(order.date).format('D-MM-YYYY') }</Header.Subheader>
      : null;

    const stateEditView = order
      ? <Segment basic textAlign='center' className="orderStateEdit">
          <Button active={ orderToUpdate.state === 'new' }
                  disabled={ orderToUpdate.state !== 'new' }>
            <Icon name='shop'/>
            Nowe
          </Button>
          <Button active={ orderToUpdate.state === 'inprogress' }
                  onClick={ this.changeOrderToUpdateState('inprogress') }>
            <Icon name='truck'/>
            W realizacji
          </Button>
          <Button active={ orderToUpdate.state === 'partiallyclosed' }
                  disabled={ totalProductsCount === 0 }
                  onClick={ this.changeOrderToUpdateState('partiallyclosed') }>
            <Icon name='level up'/>
            Częściowo zrealizowane
          </Button>
          <Button active={ orderToUpdate.state === 'closed' }
                  disabled={ totalProductsCount === 0 }
                  onClick={ this.closeOrder }>
            <Icon name='checkmark'/>
            Zrealizowane
          </Button>
          <Button active={ orderToUpdate.state === 'cancelled' }
                  onClick={ this.changeOrderToUpdateState('cancelled') }>
            <Icon name='delete'/>
            Anulowane
          </Button>
        </Segment>
      : null;

    const buttonsDisabled = !orderToUpdate.isDirty || 
      this.props.updateOrderProcess.state === UpdateOrderState.InProgress;

    const actionsView = order
      ? <div>
          <Segment basic textAlign='center'>
            <Button color='red' 
                    disabled={ buttonsDisabled }
                    onClick={ this.resetChanges }>
              <Icon name='repeat'/>
              Resetuj
            </Button>
            <Button color='green'
                    disabled={ buttonsDisabled }
                    onClick={ this.props.updateOrder }>
              <Icon name='save'/>
              Zapisz
            </Button>
            { this.renderUpdateOrderProcessState() }
          </Segment>
        </div>
      : null;

    const notExistsView = state === OrderDetailsState.ErrorNotExists 
                       || state === OrderDetailsState.ErrorNoPermissions
      ? <Message negative>
          <Message.Header>Błąd</Message.Header>
          Zamówienie nr { orderId } nie zostało znalezione.
        </Message>
      : null;

    const unknownErrorView = state === OrderDetailsState.ErrorUnknown
      ? Messages.UnknownError
      : null;

    return (
      <Container>
        { this.renderProductEditModal() }
        { this.renderCantEditInCurrentStateModal() }
        <ScrollToTopOnMount/>
        <Header as='h1' icon textAlign='center'>
          <Icon name='file text' circular/>
          <Header.Content>Zamówienie nr { orderId }</Header.Content>
          { orderDateSubheaderView }
        </Header>
        { printableOrder }
        { printTrigger }
        { loaderView }
        { stateEditView }
        { orderDetailsView }
        { actionsView }
        { notExistsView }
        { unknownErrorView }
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  orderDetails: state.orders.orderDetails,
  customerDetails: state.customers.customerDetails,
  orderToUpdate: state.orders.orderToUpdate,
  updateOrderProcess: state.orders.updateOrderProcess,
});

const mapDispatchToProps = dispatch => ({
  fetchOrder: (id) => dispatch(fetchOrder(id)),
  initializeOrderToUpdate: (order) => dispatch(initializeOrderToUpdate(order)),
  changeOrderToUpdateState: (state) => dispatch(changeOrderToUpdateState(state)),
  updateOrderedProduct: (data) => dispatch(updateOrderedProduct(data)),
  updateOrder: () => dispatch(updateOrder()),
  resetUpdateOrderProcess: () => dispatch(resetUpdateOrderProcess()),
});

export const Order =
  connect(mapStateToProps, mapDispatchToProps)(OrderPure);
