import React from 'react';
import PropTypes from 'prop-types';
import { Button, Container, Icon } from 'semantic-ui-react';
import { NewOrderPlainButton } from '../Orders/NewOrderPlainButton';
import { roundPriceValue } from '../../../utils/price';
import { ClearBasketConfirmationModal } from './ClearBasketConfirmationModal';
import './OrderBar.css';

export class OrderBar extends React.PureComponent {
  static propTypes = {
    history: PropTypes.object.isRequired,
    totalNetPriceValue: PropTypes.number.isRequired,
    totalCount: PropTypes.number.isRequired,
    clearBasket: PropTypes.func.isRequired,
    compact: PropTypes.bool
  }

  productText(count) {
    if (count === 1)
      return 'produkt';
    if (count > 1 && count <= 4)
      return 'produkty';
    return 'produktów';
  }

  renderClearButton = () => (
    this.props.compact 
      ? null
      : <Button size='small' compact disabled={ this.props.totalCount === 0 }>
          <Icon name='cancel'/>
          czyść
        </Button>
  );

  renderInfoText = (totalCount, visibleNetPrice) => {
    if (this.props.compact) {
      return (
        <span className="orderInfoText">
          <Icon name='shop'/>
          <b>{ totalCount }</b> { this.productText(totalCount) }, <b>{ visibleNetPrice }</b> PLN

        </span>
      );
    }
    return (
      <span className="orderInfoText">
        <Icon name='shop'/>
        <b>{ totalCount }</b> { this.productText(totalCount) }, łącznie <b>{ visibleNetPrice }</b> PLN
      </span>
    );
  }

  render() {
    const { totalCount, totalNetPriceValue } = this.props;
    const visibleNetPrice = roundPriceValue(totalNetPriceValue);
    return (
      <Container fluid className="orderBar" >
        <Container>
          <div className="orderBarSegment">
            { this.renderInfoText(totalCount, visibleNetPrice) }
            <ClearBasketConfirmationModal trigger={ this.renderClearButton() } 
                                          clearBasket={ this.props.clearBasket }
                                          />
            <NewOrderPlainButton history={ this.props.history }
                                 disabled={ totalCount === 0 }
                                 size='small'
                                 compact
                                 />
          </div>
        </Container>
      </Container>
    );
  }
}
