import { combineForms } from 'react-redux-form';
import { combineReducers } from 'redux';
import { unauthorized } from './login';

const initUser = {
  emailAddress: '',
  password: '',
  firstName: '',
  lastName: '',
};

const initUpdateUser = {
  password: '',
  firstName: '',
  lastName: '',
  groups: [],
};

export const RegistrationState = {
  Idle: 'IDLE',
  InProgress: 'IN_PROGRESS',
  Success: 'SUCCESS',
  ErrorEmailTaken: 'ERROR_EMAIL_TAKEN',
  ErrorWrongPassword: 'ERROR_WRONG_PASSWORD',
  ErrorUnknown: 'ERROR_UNKNOWN'
};

const initRegistrationProcessState = {
  state: RegistrationState.Idle
};

function registrationProcessReducer(state = initRegistrationProcessState, action) {
  switch (action.type) {
    case 'REGISTRATION_STARTED': {
      const stateUpdate = { state: RegistrationState.InProgress };
      return Object.assign({}, state, stateUpdate);
    }
    case 'REGISTRATION_FINISHED': {
      const stateUpdate = { state: action.state };
      return Object.assign({}, state, stateUpdate);
    }
    default: {
      return state;
    }
  }
}

function registrationStarted() {
  return {
    type: 'REGISTRATION_STARTED'
  };
}

function registrationFinished(state) {
  return {
    type: 'REGISTRATION_FINISHED',
    state: state
  };
}

export function register(user) {
  return (dispatch, getState, { AuthApi }) => {
    dispatch(registrationStarted());
    return AuthApi.register(user).then(
      response => dispatch(registrationFinished(RegistrationState.Success)),
      error => {
        switch (error) {
          case AuthApi.RegisterError.WrongPassword:
            dispatch(registrationFinished(RegistrationState.ErrorWrongPassword));
            break;
          case AuthApi.RegisterError.EmailTaken:
            dispatch(registrationFinished(RegistrationState.ErrorEmailTaken));
            break;
          default:
            dispatch(registrationFinished(RegistrationState.ErrorUnknown));
        }
      }
    );
  };
}

export const EmailConfirmationState = {
  Idle: 'IDLE',
  InProgress: 'IN_PROGRESS',
  Success: 'SUCCESS',
  ErrorAlreadyConfirmed: 'ERROR_ALREADY_CONFIRMED',
  ErrorIllegalToken: 'ERROR_ILLEGAL_TOKEN',
  ErrorUnknown: 'ERROR_UNKNOWN'
};

const initEmailConfirmationProcessState = {
  state: EmailConfirmationState.Idle
};

function emailConfirmationProcessReducer(state = initEmailConfirmationProcessState, action) {
  switch (action.type) {
    case 'EMAIL_CONFIRMATION_STARTED': {
      const stateUpdate = { state: EmailConfirmationState.InProgress };
      return Object.assign({}, state, stateUpdate);
    }
    case 'EMAIL_CONFIRMATION_FINISHED': {
      const stateUpdate = { state: action.state };
      return Object.assign({}, state, stateUpdate);
    }
    default: {
      return state;
    }
  }
}

function emailConfirmationStarted() {
  return {
    type: 'EMAIL_CONFIRMATION_STARTED'
  };
}

function emailConfirmationFinished(state) {
  return {
    type: 'EMAIL_CONFIRMATION_FINISHED',
    state: state
  };
}

export function confirmEmail(token) {
  return (dispatch, getState, { AuthApi }) => {
    dispatch(emailConfirmationStarted());
    return AuthApi.confirmEmailAddress(token).then(
      response => dispatch(emailConfirmationFinished(EmailConfirmationState.Success)),
      error => {
        switch (error) {
          case AuthApi.ConfirmEmailAddressError.AlreadyConfirmed:
            dispatch(emailConfirmationFinished(EmailConfirmationState.ErrorAlreadyConfirmed));
            break;
          case AuthApi.ConfirmEmailAddressError.IllegalToken:
            dispatch(emailConfirmationFinished(EmailConfirmationState.ErrorIllegalToken));
            break;
          default:
            dispatch(emailConfirmationFinished(EmailConfirmationState.ErrorUnknown));
        }
      }
    );
  };
}

export const UpdateUserState = {
  Idle: 'IDLE',
  InProgress: 'IN_PROGRESS',
  Success: 'SUCCESS',
  ErrorWrongPassword: 'ERROR_WRONG_PASSWORD',
  ErrorNoPermissions: 'ERROR_NO_PERMISSIONS',
  ErrorNotExists: 'ERROR_NOT_EXISTS',
  ErrorUnknown: 'ERROR_UNKNOWN'
};

const initUpdateUserProcessState = {
  state: UpdateUserState.Idle,
  user: null,
};

function updateUserProcessReducer(state = initUpdateUserProcessState, action) {
  switch (action.type) {
    case 'UPDATE_USER_STARTED': {
      const stateUpdate = { state: UpdateUserState.InProgress };
      return Object.assign({}, state, stateUpdate);
    }
    case 'UPDATE_USER_FINISHED': {
      const stateUpdate = { state: action.state, user: action.user };
      return Object.assign({}, state, stateUpdate);
    }
    default:
      return state;
  }
}

function updateUserStarted() {
  return {
    type: 'UPDATE_USER_STARTED',
  };
}

function updateUserFinished(state, user) {
  return {
    type: 'UPDATE_USER_FINISHED',
    state: state,
    user: user
  };
}

export function updateUser(emailAddress, data) {
  return (dispatch, getState, { AuthApi }) => {
    dispatch(updateUserStarted());
    const token = getState().login.session.token;
    return AuthApi.updateUser(emailAddress, data, token).then(
      response => {
        const user = Object.assign(Object.create(AuthApi.User), { emailAddress }, data);
        dispatch(updateUserFinished(UpdateUserState.Success, user));
      },
      error => {
        switch (error) {
          case AuthApi.UpdateUserError.Unauthorized:
            dispatch(unauthorized());
            dispatch(updateUserFinished(UpdateUserState.ErrorNoPermissions));
            break;
          case AuthApi.UpdateUserError.Forbidden:
            dispatch(updateUserFinished(UpdateUserState.ErrorNoPermissions));
            break;
          case AuthApi.UpdateUserError.NotExists:
            dispatch(updateUserFinished(UpdateUserState.ErrorNotExists));
            break;
          case AuthApi.UpdateUserError.WrongPassword:
            dispatch(updateUserFinished(UpdateUserState.ErrorWrongPassword));
            break;
          default:
            dispatch(updateUserFinished(UpdateUserState.ErrorUnknown));
        }
      }
    );
  };
}

export function registrationReducer() {
  return combineReducers({
    form: combineForms({
      user: initUser,
      updateUser: initUpdateUser,
    }, 'registration.form'),
    registrationProcess: registrationProcessReducer,
    emailConfirmationProcess: emailConfirmationProcessReducer,
    updateUserProcess: updateUserProcessReducer,
  });
}

