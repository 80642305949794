import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { Container, Header, Icon } from 'semantic-ui-react';
import { LoginForm } from './LoginForm';
import { LoginState } from '../../../redux/login';
import { ScrollToTopOnMount } from '../../ScrollToTopOnMount';

class LoginPure extends React.PureComponent {
  static propTypes = {
    loginState: PropTypes.string.isRequired,
    isSessionValid: PropTypes.bool.isRequired,
  }

  loginCompleted = () =>
    this.props.loginState === LoginState.Success

  redirectUrl = () =>
    this.props.location.state && this.props.location.state.from ?
      this.props.location.state.from : '/';

  render() {
    if (this.props.isSessionValid) {
      return <Redirect to={ this.redirectUrl() }/>;
    } else {
      return (
        <Container text textAlign='center'>
          <ScrollToTopOnMount/>
          <Header as='h1' icon textAlign='center'>
            <Icon name='sign in' circular/>
            <Header.Content>Logowanie</Header.Content>
          </Header>
          <LoginForm/>
        </Container>
      );
    }
  }
}

const mapStateToProps = state => ({
  isSessionValid: !!state.login.session.user && !state.login.session.isLoginRequired,
  loginState: state.login.process.state,
});

export const Login = connect(mapStateToProps)(LoginPure);
