import React from 'react';
import { Redirect } from 'react-router-dom';
import { logout } from '../../../redux/login';
import { connect } from 'react-redux';

class LogoutPure extends React.PureComponent {
  componentDidMount() {
    this.props.logout();
  }

  render() {
    return <Redirect to='/'/>;
  }
}

const mapDispatchToProps = dispatch => ({
  logout: () => dispatch(logout())
});

export const Logout = connect(undefined, mapDispatchToProps)(LogoutPure);
