import React from 'react';
import PropTypes from 'prop-types';
import { Container, Icon, Message } from 'semantic-ui-react';
import './Warning.css';

export class Warning extends React.Component {
  static propTypes = {
    headerText: PropTypes.string.isRequired,
    contentText: PropTypes.string.isRequired,
    onDismiss: PropTypes.func.isRequired,
  }

  render() {
    return (
      <Container className="bottomWarning">
        <Message className="bottomWarning" 
                 onDismiss={ this.props.onDismiss } 
                 icon
                 >
          <Icon name='warning'/>
          <Message.Content>
            <Message.Header>{ this.props.headerText }</Message.Header>
            { this.props.contentText }
          </Message.Content>
        </Message>
      </Container>
    );
  }
}
