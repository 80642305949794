import Config from '../config';
import { del, get, post, postFile, url } from './ApiClient';

function contentUrl(path, params) {
  return url(Config.endpoints.content, path, params);
}

const ActiveAnnouncementError = {
  Unauthorized: 'UNAUTHORIZED',
  Forbidden: 'FORBIDDEN',
  Unknown: 'UNKNOWN',
};

function activeAnnouncement(token) {
  return get(contentUrl('announcements/active'), token).then(
    response => response,
    error => {
      switch (error.statusText) {
        case 'Unauthorized':
          return Promise.reject(ActiveAnnouncementError.Unauthorized);
        case 'Forbidden':
          return Promise.reject(ActiveAnnouncementError.Forbidden);
        default:
          return Promise.reject(ActiveAnnouncementError.Unknown);
      }
    }
  );
}

const NewActiveAnnouncementError = {
  Unauthorized: 'UNAUTHORIZED',
  Forbidden: 'FORBIDDEN',
  Unknown: 'UNKNOWN',
};

function newActiveAnnouncement(announcement, token) {
  return post(contentUrl('announcements/active'), announcement, token).then(
    response => response,
    error => {
      switch (error.statusText) {
        case 'Unauthorized':
          return Promise.reject(NewActiveAnnouncementError.Unauthorized);
        case 'Forbidden':
          return Promise.reject(NewActiveAnnouncementError.Forbidden);
        default:
          return Promise.reject(NewActiveAnnouncementError.Unknown);
      }
    }
  );
}

const DeleteActiveAnnouncementError = {
  Unauthorized: 'UNAUTHORIZED',
  Forbidden: 'FORBIDDEN',
  Unknown: 'UNKNOWN',
};

function deleteActiveAnnouncement(id, token) {
  return del(contentUrl('announcements/active/' + id), token).then(
    response => response,
    error => {
      switch (error.statusText) {
        case 'Unauthorized':
          return Promise.reject(DeleteActiveAnnouncementError.Unauthorized);
        case 'Forbidden':
          return Promise.reject(DeleteActiveAnnouncementError.Forbidden);
        default:
          return Promise.reject(DeleteActiveAnnouncementError.Unknown);
      }
    }
  );
}

const FileDocumentsError = {
  Unauthorized: 'UNAUTHORIZED',
  Forbidden: 'FORBIDDEN',
  Unknown: 'UNKNOWN',
};

function fileDocuments(token) {
  return get(contentUrl('file-documents'), token).then(
    response => response,
    error => {
      switch (error.statusText) {
        case 'Unauthorized':
          return Promise.reject(FileDocumentsError.Unauthorized);
        case 'Forbidden':
          return Promise.reject(FileDocumentsError.Forbidden);
        default:
          return Promise.reject(FileDocumentsError.Unknown);
      }
    }
  );
}

const UploadFileDocumentError = {
  Unauthorized: 'UNAUTHORIZED',
  Forbidden: 'FORBIDDEN',
  Unknown: 'UNKNOWN'
};

function uploadFileDocument(documentName, file, token) {
  return postFile(contentUrl('file-documents/' + encodeURIComponent(documentName)), {}, file, token).then(
    response => response,
    error => {
      switch (error.statusText) {
        case 'Unauthorized':
          return Promise.reject(UploadFileDocumentError.Unauthorized);
        case 'Forbidden':
          return Promise.reject(UploadFileDocumentError.Forbidden);
        default:
          return Promise.reject(UploadFileDocumentError.Unknown);
      }
    }
  );
}

const DeleteFileDocumentError = {
  Unauthorized: 'UNAUTHORIZED',
  Forbidden: 'FORBIDDEN',
  Unknown: 'UNKNOWN'
};

function deleteFileDocument(uuid, token) {
  return del(contentUrl('file-documents/' + uuid), token).then(
    response => response,
    error => {
      switch (error.statusText) {
        case 'Unauthorized':
          return Promise.reject(DeleteFileDocumentError.Unauthorized);
        case 'Forbidden':
          return Promise.reject(DeleteFileDocumentError.Forbidden);
        default:
          return Promise.reject(DeleteFileDocumentError.Unknown);
      }
    }
  );
}

const FetchFileDocumentDownloadUrlError = {
  Unauthorized: 'UNAUTHORIZED',
  Forbidden: 'FORBIDDEN',
  Unknown: 'UNKNOWN'
};

function fetchFileDocumentDownloadUrl(uuid, token) {
  return get(contentUrl('file-documents/' + uuid), token).then(
    response => response,
    error => {
      switch (error.statusText) {
        case 'Unauthorized':
          return Promise.reject(FetchFileDocumentDownloadUrlError.Unauthorized);
        case 'Forbidden':
          return Promise.reject(FetchFileDocumentDownloadUrlError.Forbidden);
        default:
          return Promise.reject(FetchFileDocumentDownloadUrlError.Unknown);
      }
    }
  );
}

const ContentApi = {
  activeAnnouncement,
  ActiveAnnouncementError,
  newActiveAnnouncement,
  NewActiveAnnouncementError,
  deleteActiveAnnouncement,
  DeleteActiveAnnouncementError,
  fileDocuments,
  FileDocumentsError,
  uploadFileDocument,
  UploadFileDocumentError,
  deleteFileDocument,
  DeleteFileDocumentError,
  fetchFileDocumentDownloadUrl,
  FetchFileDocumentDownloadUrlError,
};

export default ContentApi;