import React from 'react';
import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';
import { Container, Header, Icon, Message, Segment, Loader } from 'semantic-ui-react';
import { ScrollToTopOnMount } from '../../../ScrollToTopOnMount';
import { Messages } from "../../../Messages";
import { fetchCustomer, CustomerDetailsState  } from '../../../../redux/customers.js';
import { resetUpdateCustomerProcess } from '../../../../redux/customers.js';
import { UpdateCustomerState } from '../../../../redux/customers.js';
import { CustomerEditForm } from './CustomerEditForm';

class CustomerEditPure extends React.PureComponent {
  static props = {
    match: PropTypes.object.isRequired,
    customerDetails: PropTypes.object.isRequired,
    fetchCustomer: PropTypes.func.isRequired,
    updateCustomerProcess: PropTypes.object.isRequired,
    resetUpdateCustomerProcess: PropTypes.func.isRequired,
  }

  componentDidMount() {
    this.props.resetUpdateCustomerProcess();
    if (this.props.match.params.id) {
      this.props.fetchCustomer(this.props.match.params.id);
    }
  }

  renderSuccessMessage = () => {
    const state = this.props.updateCustomerProcess.state;
    return (
      state === UpdateCustomerState.Success ? <Message success>Zapisano</Message> : null
    );
  };

  render() {
    const customerId = this.props.match.params.id;
    const state = this.props.customerDetails.state;
    const customer = this.props.customerDetails.customer;

    const customerInfo = customer && state === CustomerDetailsState.Idle
      ? customer.company.name
      : "Klient nr " + customerId;

    const loaderView = state === CustomerDetailsState.Fetching 
      ? <Segment basic>
          <Loader active={ true }/>
        </Segment>
      : null;

    const notExistsView = state === CustomerDetailsState.ErrorNotExists 
      ? <Message negative>
          <Message.Header>Błąd</Message.Header>
          Klient nie istnieje.
        </Message>
      : null;

    const updateForm = customer && state === CustomerDetailsState.Idle
      ? <CustomerEditForm customer={ customer }/>
      : null;

    const noPermissionsMessage = state === CustomerDetailsState.ErrorNoPermissions
      ? Messages.NoPermissions 
      : null;

    const unknownErrorMessageView = state === CustomerDetailsState.ErrorUnknown
      ? Messages.UnknownError 
      : null;

    return (
      <Container text>
        <ScrollToTopOnMount/>
        <Header as='h1' icon textAlign='center'>
          <Icon name='address book' circular/>
          <Header.Content>{ customerInfo }</Header.Content>
        </Header>
        { notExistsView }
        { unknownErrorMessageView }
        { noPermissionsMessage }
        { loaderView }
        { updateForm }
        { this.renderSuccessMessage() }
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  customerDetails: state.customers.customerDetails,
  updateCustomerProcess: state.customers.updateProcess,
});

const mapDispatchToProps = dispatch => ({
  fetchCustomer: (id) => dispatch(fetchCustomer(id)),
  resetUpdateCustomerProcess: () => dispatch(resetUpdateCustomerProcess()),
});

export const CustomerEdit =
  connect(mapStateToProps, mapDispatchToProps)(CustomerEditPure);
