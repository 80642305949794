import React from 'react';
import moment from 'moment';
import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';
import { Control, Errors, Form } from 'react-redux-form';
import { actions } from 'react-redux-form';
import { Message } from 'semantic-ui-react';
import { ReduxDatePicker } from '../../../ReduxDatePicker';
import { ReduxItemPicker } from '../../../ItemPicker';
import { Messages } from '../../../Messages';
import { required } from '../../../../utils/common';
import { SaveProductState, saveProduct } from '../../../../redux/store';

class ReduxProductEditForm extends React.PureComponent {
  static props = {
    saveProductProcess: PropTypes.object.isRequired,
    product: PropTypes.object,
    categories: PropTypes.arrayOf(PropTypes.object).isRequired,
    onSubmit: PropTypes.func,
  }

  componentDidMount() {
    const { product } = this.props;
    const data = {
      name: product ? product.name : "",
      index: product ? product.index : "",
      description: product ? product.description : "",
      isNew: product ? product.flags.indexOf('new') !== -1 : true,
      inStock: product ? product.flags.indexOf('instock') !== -1 : true,
      availableSince: product ? product.availableSince : moment.now(),
      categoryTag: product ? product.categoryTag : "",
    };
    this.props.dispatch(actions.change('store.form.saveProduct', data));
  }

  componentDidUpdate() {
    // Product images are not controlled by this form, 
    // so make sure they are always up to date.
    if (!!this.props.product) {
      this.props.dispatch(actions.change('store.form.saveProduct.images', this.props.product.images));
    }
  }

  isProductNew = (product) => !!product.id;

  saveProductError = () => {
    const process = this.props.saveProductProcess;
    switch (process.state) {
      case SaveProductState.ErrorUnknown:
        return Messages.UnknownError;
      case SaveProductState.ErrorNotExists:
        return (
          <Message negative>
            <Message.Header>Product nie istnieje.</Message.Header>
          </Message>
        );
      case SaveProductState.ErrorNoPermissions:
        return (
          <Message negative>
            <Message.Header>Brak uprawnień</Message.Header>
            Nie masz wystarczających uprawnień do aktualizacji produktu.
          </Message>
        );
      case SaveProductState.ErrorIndexGroupFull:
        return (
          <Message negative>
            <Message.Header>Konflikt</Message.Header>
            Zbyt wiele produktów w grupie indeksu. Zmień indeks.
          </Message>
        );
      default:
        return null;
    }
  }

  productFlags(isNew, inStock) {
    const result = [];
    if (isNew) result.push('new');
    if (inStock) result.push('instock');
    return result;
  }

  handleSubmit = (data) => {
    const id = this.props.product ? this.props.product.id : undefined;
    const productData = {
      name: data.name.trim(),
      index: data.index.trim(),
      description: data.description.trim(),
      flags: this.productFlags(data.isNew, data.inStock),
      availableSince: data.availableSince,
      categoryTag: data.categoryTag,
      images: this.props.product ? this.props.product.images : [],
    };
    this.props.dispatch(saveProduct({ id, ...productData }));
    if (this.props.onSubmit) {
      this.props.onSubmit({ id, ...productData });
    }
  }

  render() {
    const inProgress = this.props.saveProductProcess.state === SaveProductState.InProgress;
    const formClassName = (inProgress ? "loading " : "") + "ui form";
    return (
      <Form
        className={ formClassName }
        model="store.form.saveProduct"
        onSubmit={ this.handleSubmit }
      >

        <div className="field">
          <label>Indeks</label>
          <Control 
            model=".index"
            disabled={ !!this.props.product }
            validators={{ required }}
          />
          <Errors
            className="errors"
            model=".index"
            show="touched"
            messages={{
              required: 'Wymagane',
            }}
          />
        </div>

        <div className="field">
          <label>Nazwa</label>
          <Control 
            model=".name"
            validators={{ required }}
          />
          <Errors
            className="errors"
            model=".name"
            show="touched"
            messages={{
              required: 'Wymagane',
            }}
          />
        </div>

        <div className="field">
          <label>Dostępny od</label>
          <Control model='.availableSince' component={ ReduxDatePicker }/>
        </div>

        <div className="field">
          <label>Kategoria</label>
          <Control model='.categoryTag' 
                   items={ this.props.categories }
                   component={ ReduxItemPicker }/>
        </div>

        <div className="field">
          <label>Opis</label>
          <Control.textarea
            model=".description"
            validators={{ required }}
          />
          <Errors
            className="errors"
            model=".description"
            show="touched"
            messages={{
              required: 'Wymagane',
            }}
          />
        </div>

        <div className="field">
          <label>Nowość</label>
          <Control.checkbox model=".isNew"/>
        </div>

        <div className="field">
          <label>W sprzedaży</label>
          <Control.checkbox model=".inStock"/>
        </div>

        <button type="submit" className="ui button primary fluid">
          Zapisz
        </button>

        { this.saveProductError() }
      </Form>
    );
  }
}

const mapStateToProps = state => ({
  saveProductProcess: state.store.saveProductProcess,
});

export const ProductEditForm = connect(mapStateToProps)(ReduxProductEditForm);
