import React from 'react';
import PropTypes from 'prop-types';
import { FilterButton, FilterSegment } from '../../Filters';

export class FlagFilter extends React.PureComponent {
  static propTypes = {
    activeFlags: PropTypes.arrayOf(PropTypes.string).isRequired,
    onFlagToggled: PropTypes.func.isRequired,
    showSaleFlags: PropTypes.bool.isRequired,
  }

  onFlagToggled = (flag) => () => this.props.onFlagToggled(flag);

  render() {
    const sortAlphaFlagActive = this.props.activeFlags.indexOf("sort-alphabetically") !== -1;
    const sortAlphaFlag =
      <FilterButton key="alpha"
                    active={ sortAlphaFlagActive }
                    onClick={ this.onFlagToggled("sort-alphabetically") }
                    label="Alfabetycznie"
                    />;

    const availableFlagActive = this.props.activeFlags.indexOf("available") !== -1;
    const availableFlag = this.props.showSaleFlags
      ? <FilterButton key="available"
                      active={ availableFlagActive }
                      onClick={ this.onFlagToggled("available") }
                      label="Dostępne"
                      />
      : null;

    const newFlagActive = this.props.activeFlags.indexOf("new") !== -1;
    const newFlag =
      <FilterButton key="new"
                    active={ newFlagActive }
                    onClick={ this.onFlagToggled("new") }
                    label="Nowości"
                    />;

    const promoFlagActive = this.props.activeFlags.indexOf("promo") !== -1;
    const promoFlag = this.props.showSaleFlags
      ? <FilterButton key="promo"
                      active={ promoFlagActive }
                      onClick={ this.onFlagToggled("promo") }
                      label="Promocje"
                      />
      : null;

    return (
      <FilterSegment>
        { sortAlphaFlag }
        { availableFlag }
        { newFlag }
        { promoFlag }
      </FilterSegment>
    );
  }
}
