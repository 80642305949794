import React from 'react';
import { connect } from 'react-redux';
import { Container, Divider, Header, Icon } from 'semantic-ui-react';
import { fetchActiveAnnouncement, newActiveAnnouncement } from '../../../../redux/announcements';
import { ScrollToTopOnMount } from '../../../ScrollToTopOnMount';
import { NewActiveAnnouncementForm } from './NewActiveAnnouncementForm';

class ActiveAnnouncementPure extends React.PureComponent {

  render() {
    return (
      <Container textAlign='center'>
        <ScrollToTopOnMount/>
        <Header as='h1' icon textAlign='center'>
          <Icon name='bullhorn' circular/>
          <Header.Content>Ogłoszenie</Header.Content>
        </Header>
        <Divider hidden/>
        <NewActiveAnnouncementForm/>
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  activeAnnouncement: state.announcements.activeAnnouncement,
  newActiveAnnouncement: state.announcements.newActiveAnnouncement,
});

const mapDispatchToProps = dispatch => ({
  fetchActiveAnnouncement: () => dispatch(fetchActiveAnnouncement()),
  newActiveAnnouncement: (announcement) => dispatch(newActiveAnnouncement(announcement)),
});

export const ActiveAnnouncement = connect(mapStateToProps, mapDispatchToProps)(ActiveAnnouncementPure);
