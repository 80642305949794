import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Container, Header, Icon, Loader, Segment, Message } from 'semantic-ui-react';
import { OrderProductsList } from './OrderProductsList';
import { OrderState } from './OrderState';
import { OrderTotalPriceMessage } from './OrderTotalPriceMessage';
import { PrintableOrder } from './PrintableOrder';
import { PrintTrigger } from '../PrintTrigger';
import { Messages } from '../../Messages';
import { SimpleText } from '../../SimpleText';
import { orderedProductsNetPriceValue } from '../../../utils/price';
import { fetchOrder, OrderDetailsState } from '../../../redux/orders';
import { ScrollToTopOnMount } from '../../ScrollToTopOnMount';
import moment from 'moment';

class OrderPure extends React.PureComponent {
  static propTypes = {
    orderDetails: PropTypes.object.isRequired,
    fetchOrder: PropTypes.func.isRequired,
    isCustomer: PropTypes.bool.isRequired,
    history: PropTypes.object.isRequired,
  }

  componentDidMount() {
    if (!this.props.isCustomer) {
      this.props.history.push('/');
    } else if (this.props.match.params.id) {
      this.props.fetchOrder(this.props.match.params.id);
    }
  }

  render() {
    const orderId = this.props.match.params.id;
    const { order, state } = this.props.orderDetails;

    const loaderView = state === OrderDetailsState.Fetching 
      ? <Segment basic>
          <Loader active={ true }/>
        </Segment>
      : null;

    const customerInfoView = order && state === OrderDetailsState.Idle && order.customerInfo
      ? <Message>
          <Message.Header>Uwagi</Message.Header>
          <SimpleText text={ order.customerInfo }/>
        </Message>
      : null;

    const orderDetailsView = order && state === OrderDetailsState.Idle 
      ? <div>
          <Container textAlign='center' fluid>
            <OrderState state={ order.state } className="mobile hidden"/>
          </Container>
          <OrderTotalPriceMessage 
            totalNetPriceValue={ orderedProductsNetPriceValue(order.orderedProducts) }
            />
          <Segment basic>
            <OrderProductsList 
              orderedProducts={ order.orderedProducts } 
              orderClosed={ order.state === 'closed' || order.state === 'cancelled' }
              />
          </Segment>
          { customerInfoView }
        </div>
      : null;

    const orderDateSubheaderView = order && state === OrderDetailsState.Idle
      ? <Header.Subheader>{ moment(order.date).format('D-MM-YYYY') }</Header.Subheader>
      : null;

    const notExistsView = state === OrderDetailsState.ErrorNotExists 
                       || state === OrderDetailsState.ErrorNoPermissions
      ? <Message negative>
          <Message.Header>Błąd</Message.Header>
          Zamówienie nr { orderId } nie zostało znalezione.
        </Message>
      : null;

    const unknownErrorView = state === OrderDetailsState.ErrorUnknown
      ? Messages.UnknownError
      : null;

    const printableOrder = order && state === OrderDetailsState.Idle
      ? <PrintableOrder order={ order } ref={ el => (this.printRef = el) }/>
      : null;

    const printTrigger = order && state === OrderDetailsState.Idle
      ? <PrintTrigger content={ () => this.printRef }/>
      : null;

    return (
      <Container>
        <ScrollToTopOnMount/>
        <Header as='h1' icon textAlign='center'>
          <Icon name='file text' circular/>
          <Header.Content>Zamówienie nr { orderId }</Header.Content>
          { orderDateSubheaderView }
        </Header>
        { printableOrder }
        { printTrigger }
        { loaderView }
        { orderDetailsView }
        { notExistsView }
        { unknownErrorView }
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  orderDetails: state.orders.orderDetails,
  isCustomer: !!state.login.session.customer,
});

const mapDispatchToProps = dispatch => ({
  fetchOrder: (id) => dispatch(fetchOrder(id)),
});

export const Order =
  connect(mapStateToProps, mapDispatchToProps)(OrderPure);
