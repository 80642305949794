import React from 'react';
import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';
import { Control, Errors, Form } from 'react-redux-form';
import { Message } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { Messages } from '../../Messages';
import { required, isEmailValid, isPasswordValid, trimString } from '../../../utils/common';
import { PasswordRecoveryFinalizeState } from '../../../redux/passwordRecovery';
import { finalizePasswordRecovery } from '../../../redux/passwordRecovery';

class ReduxPasswordRecoveryFinalizeForm extends React.PureComponent {
  static props = {
    recoveryState: PropTypes.object.isRequired,
    uuid: PropTypes.string.isRequired,
  }

  recoveryError = () => {
    switch (this.props.recoveryState) {
      case PasswordRecoveryFinalizeState.ErrorWrongPassword:
        return Messages.InvalidPassword;
      case PasswordRecoveryFinalizeState.ErrorUnknownUuid:
        return (
          <Message negative>
            <Message.Header>Link jest już nieaktywny</Message.Header>
            <Link to="/login/recovery">Zacznij proces od nowa.</Link>
          </Message>
        );
      case PasswordRecoveryFinalizeState.ErrorUnknown:
        return Messages.UnknownError;
      default:
        return null;
    }
  }

  handleSubmit = (data) => {
    const recoveryData = Object.assign({ uuid: this.props.uuid }, data);
    this.props.dispatch(finalizePasswordRecovery(recoveryData));
  }

  render() {
    const inProgress = this.props.recoveryState === PasswordRecoveryFinalizeState.InProgress;
    const formClassName = (inProgress ? "loading " : "") + "ui form";
    return (
      <Form
        model="passwordRecovery.form.recoveryFinalizeData"
        onSubmit={ this.handleSubmit }
        className={ formClassName }
      >
        <div className="field">
          <Control 
            model=".emailAddress" 
            placeholder="E-mail"
            validators={{ required, validEmail: isEmailValid }}
            validateOn="blur"
            parser={ trimString }
          />
          <Errors
            className="errors"
            model=".emailAddress"
            show="touched"
            messages={{
              required: 'Wymagane',
              validEmail: 'Niepoprawny adres email'
            }}
          />
        </div>
        <div className="field">
          <Control model=".password"
            type="password"
            validators={{ required, isPasswordValid }}
            placeholder="Nowe hasło"
            parser={ trimString }
          />
          <Errors
            className="errors"
            model=".password"
            show="touched"
            messages={{
              required: 'Wymagane',
              isPasswordValid: 'Wymagane minimum 8 znaków, w tym minimum 2 cyfry'
            }}
          />
        </div>

        <button type="submit" className="ui button primary fluid">
          Zmień hasło
        </button>

        { this.recoveryError() }
      </Form>
    );
  }
}

const mapStateToProps = state => ({
  recoveryState: state.passwordRecovery.recoveryFinalizeProcess.state,
});

export const PasswordRecoveryFinalizeForm =
  connect(mapStateToProps)(ReduxPasswordRecoveryFinalizeForm);
