import React from 'react';
import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';
import { Control, Errors, Form } from 'react-redux-form';
import { actions } from 'react-redux-form';
import { Message } from 'semantic-ui-react';
import { Messages } from '../../../Messages';
import { required, isPriceValid, isDiscountValid, isCountValid } from '../../../../utils/common';
import { SaveProductForSaleState, saveProductForSale } from '../../../../redux/store';

class ReduxProductForSaleEditForm extends React.PureComponent {
  static props = {
    saveProductForSaleProcess: PropTypes.object.isRequired,
    productId: PropTypes.number.isRequired,
    price: PropTypes.object,
    postPackageDelivery: PropTypes.object
  }

  componentDidMount() {
    const { price } = this.props;
    const delivery = this.props.postPackageDelivery;
    const data = {
      netPrice: price ? "" + price.netPrice : "",
      discountPercent: price ? "" + price.discountPercent : "",
      packageNetPrice: delivery ? "" + delivery.packageNetPrice : "",
      packageCapacity: delivery ? "" + delivery.packageCapacity : "",
    };
    this.props.dispatch(actions.change('store.form.saveProductForSale', data));
  }

  savePriceError = () => {
    const process = this.props.saveProductForSaleProcess;
    switch (process.state) {
      case SaveProductForSaleState.ErrorUnknown:
        return Messages.UnknownError;
      case SaveProductForSaleState.ErrorNoPermissions:
        return (
          <Message nesgative>
            <Message.Header>Brak uprawnień</Message.Header>
            Nie masz wystarczających uprawnień do aktualizacji oferty.
          </Message>
        );
      default:
        return null;
    }
  }

  handleSubmit = (data) => {
    const id = this.props.productId;
    const price = {
      netPrice: Number(data.netPrice),
      discountPercent: Number(data.discountPercent),
    };
    const haveDeliveryDetails = data.packageCapacity !== "" 
                             && Number(data.packageCapacity) > 0 
                             && data.packageNetPrice !== "";
    const postPackageDelivery = haveDeliveryDetails
      ? { packageNetPrice: Number(data.packageNetPrice), 
          packageCapacity: Number(data.packageCapacity) }
      : undefined;
    this.props.dispatch(saveProductForSale(id, { price, postPackageDelivery }));
  }

  render() {
    const inProgress = 
      this.props.saveProductForSaleProcess.state === SaveProductForSaleState.InProgress;
    const formClassName = (inProgress ? "loading " : "") + "ui form";
    return (
      <Form
        className={ formClassName }
        model="store.form.saveProductForSale"
        onSubmit={ this.handleSubmit }
      >

        <div className="field">
          <label>Cena netto PLN</label>
          <Control 
            model=".netPrice"
            validators={{ required, isPriceValid }}
          />
          <Errors
            className="errors"
            model=".netPrice"
            show="touched"
            messages={{
              required: 'Wymagane',
              isPriceValid: 'Niepoprawna wartość',
            }}
          />
        </div>

        <div className="field">
          <label>Zniżka %</label>
          <Control 
            model=".discountPercent"
            validators={{ isDiscountValid }}
          />
          <Errors
            className="errors"
            model=".discountPercent"
            show="touched"
            messages={{
              isDiscountValid: 'Niepoprawna wartość',
            }}
          />
        </div>

        <div className="field">
          <label>Pojemność paczki</label>
          <Control 
            model='.packageCapacity'
            validators={{ isCountValid }}
          />
          <Errors
            className="errors"
            model=".packageCapacity"
            show="touched"
            messages={{
              isCountValid: 'Niepoprawna wartość',
            }}
          />
        </div>

        <div className="field">
          <label>Cena netto paczki PLN</label>
          <Control 
            model='.packageNetPrice'
            validators={{ isPriceValid }}
          />
          <Errors
            className="errors"
            model=".packageNetPrice"
            show="touched"
            messages={{
              isPriceValid: 'Niepoprawna wartość',
            }}
          />
        </div>

        <button type="submit" className="ui button primary fluid">
          Zapisz
        </button>

        { this.savePriceError() }
      </Form>
    );
  }
}

const mapStateToProps = state => ({
  saveProductForSaleProcess: state.store.saveProductForSaleProcess,
});

export const ProductForSaleEditForm = connect(mapStateToProps)(ReduxProductForSaleEditForm);
