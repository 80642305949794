import React from 'react';
import PropTypes from 'prop-types';
import Lightbox from 'react-image-lightbox';

export class FullscreenPhotoShow extends React.PureComponent {
  static propTypes = {
    images: PropTypes.arrayOf(PropTypes.string).isRequired,
    imageIndex: PropTypes.number,
    onCloseRequest: PropTypes.func,
  }

  constructor(props) {
    super(props);
    this.state = { 
      imgIndex: props.imageIndex !== undefined ? props.imageIndex : 0
    };
  }

  nextPhoto = () => {
    const count = this.props.images.length;
    this.setState({ imgIndex: (this.state.imgIndex + 1) % count });
  }

  prevPhoto = () => {
    const count = this.props.images.length;
    this.setState({ imgIndex: (this.state.imgIndex + count - 1) % count });
  }

  hidePhotos = () => {
    const handler = this.props.onCloseRequest;
    if (handler !== undefined) {
      handler();
    }
  }

  render() {
    const images = this.props.images;
    const count = images.length;
    const imgIndex = this.state.imgIndex;
    return (
      <Lightbox mainSrc={ images[imgIndex] } 
                nextSrc={ images[(imgIndex + 1) % count] }
                prevSrc={ images[(imgIndex + count - 1) % count] }
                onMoveNextRequest={ this.nextPhoto }
                onMovePrevRequest={ this.prevPhoto }
                onCloseRequest={ this.hidePhotos }/> 
    );
  }
}
