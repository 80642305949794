import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { Contact } from './Contact';

export class ContactRouter extends React.PureComponent {
  defaultRedirect = () => <Redirect to='/'/>;
  render() {
    return (
      <Switch>
        <Route exact path='/contact' component={ Contact }/>
        <Route path='/' render={ this.defaultRedirect }/>
      </Switch>
    );
  }
}
