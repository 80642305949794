import Config from '../config';

import { get, post, url } from './ApiClient';

function publicUrl(path, params) {
  return url(Config.endpoints.public, path, params);
}

const PublicTokenError = {
  Unauthorized: 'UNAUTHORIZED',
  Forbidden: 'FORBIDDEN',
  Unknown: 'UNKNOWN',
};

function publicToken(customerId, token) {
  return post(publicUrl(`internal/tokens/new?customerId=${customerId}`), null, token).then(
    response => response,
    error => {
      switch (error.statusText) {
        case 'Unauthorized':
          return Promise.reject(PublicTokenError.Unauthorized);
        case 'Forbidden':
          return Promise.reject(PublicTokenError.Forbidden);
        default:
          return Promise.reject(PublicTokenError.Unknown);
      }
    }
  );
}

const XmlFeedError = {
  Unauthorized: 'UNAUTHORIZED',
  Forbidden: 'FORBIDDEN',
  Unknown: 'UNKNOWN',
};

function xmlFeed(publicToken) {
  return get(publicUrl("v1/feed/xml"), publicToken).then(
    response => response,
    error => {
      switch (error.statusText) {
        case 'Unauthorized':
          return Promise.reject(XmlFeedError.Unauthorized);
        case 'Forbidden':
          return Promise.reject(XmlFeedError.Forbidden);
        default:
          return Promise.reject(XmlFeedError.Unknown);
      }
    }
  );
}

const PublicApi = {
  PublicTokenError,
  publicToken,
  XmlFeedError,
  xmlFeed,
};

export default PublicApi;