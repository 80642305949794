import { combineForms } from 'react-redux-form';
import { combineReducers } from "redux";
import { unauthorized } from "./login";

const initNewAnnouncement = {
  text: ''
};

export const ActiveAnnouncementState = {
  Idle: 'IDLE',
  Fetching: 'FETCHING',
  ErrorNoPermissions: 'ERROR_NO_PERMISSIONS',
  ErrorUnknown: 'ERROR_UNKNOWN',
};

const initActiveAnnouncementState = {
  announcement: {},
  state: ActiveAnnouncementState.Idle,
};

function activeAnnouncementReducer(state = initActiveAnnouncementState, action) {
  switch (action.type) {
    case 'FETCHING_ACTIVE_ANNOUNCEMENT_STARTED': {
      const stateUpdate = { state: ActiveAnnouncementState.Fetching };
      return Object.assign({}, state, stateUpdate);
    }
    case 'FETCHING_ACTIVE_ANNOUNCEMENT_FINISHED': {
      const stateUpdate = {
        state: action.state,
        announcement: action.announcement
      };
      return Object.assign({}, state, stateUpdate);
    }
    default: {
      return state;
    }
  }
}

export function fetchActiveAnnouncement() {
  return (dispatch, getState, { ContentApi }) => {
    const activeAnnouncement = getState().announcements.activeAnnouncement;
    if (activeAnnouncement.state === ActiveAnnouncementState.Fetching)
      return;

    dispatch(fetchingActiveAnnouncementStarted());
    const session = getState().login.session;
    return ContentApi.activeAnnouncement(session.token).then(
      data =>
        dispatch(fetchingActiveAnnouncementFinished(ActiveAnnouncementState.Idle, data.announcement)),
      error => {
        switch (error) {
          case ContentApi.ActiveAnnouncementError.Forbidden:
            dispatch(fetchingActiveAnnouncementFinished(ActiveAnnouncementState.ErrorNoPermissions));
            break;
          case ContentApi.ActiveAnnouncementError.Unauthorized:
            dispatch(fetchingActiveAnnouncementFinished(ActiveAnnouncementState.ErrorNoPermissions));
            dispatch(unauthorized());
            break;
          default:
            dispatch(fetchingActiveAnnouncementFinished(ActiveAnnouncementState.ErrorUnknown));
        }
      }
    );
  };
}

function fetchingActiveAnnouncementStarted() {
  return {
    type: 'FETCHING_ACTIVE_ANNOUNCEMENT_STARTED'
  };
}

function fetchingActiveAnnouncementFinished(state, announcement = {}) {
  return {
    type: 'FETCHING_ACTIVE_ANNOUNCEMENT_FINISHED',
    announcement: announcement,
    state: state,
  };
}

export const NewActiveAnnouncementState = {
  Idle: 'IDLE',
  Creating: 'CREATING',
  Created: 'CREATED',
  ErrorNoPermissions: 'ERROR_NO_PERMISSIONS',
  ErrorUnknown: 'ERROR_UNKNOWN',
};

const initNewActiveAnnouncementState = {
  state: NewActiveAnnouncementState.Idle,
};

function newActiveAnnouncementReducer(state = initNewActiveAnnouncementState, action) {
  switch (action.type) {
    case 'NEW_ACTIVE_ANNOUNCEMENT_STARTED': {
      const stateUpdate = { state: NewActiveAnnouncementState.Creating };
      return Object.assign({}, state, stateUpdate);
    }
    case 'NEW_ACTIVE_ANNOUNCEMENT_FINISHED': {
      const stateUpdate = { state: action.state };
      return Object.assign({}, state, stateUpdate);
    }
    default: {
      return state;
    }
  }
}

export function newActiveAnnouncement(announcement) {
  return (dispatch, getState, { ContentApi }) => {
    dispatch(newActiveAnnouncementStarted());
    const session = getState().login.session;
    return ContentApi.newActiveAnnouncement(announcement, session.token).then(
      announcement => {
        dispatch(newActiveAnnouncementFinished(NewActiveAnnouncementState.Created));
        dispatch(fetchingActiveAnnouncementFinished(ActiveAnnouncementState.Idle, announcement));
      },
      error => {
        switch (error) {
          case ContentApi.ActiveAnnouncementError.Forbidden:
            dispatch(newActiveAnnouncementFinished(NewActiveAnnouncementState.ErrorNoPermissions));
            break;
          case ContentApi.ActiveAnnouncementError.Unauthorized:
            dispatch(newActiveAnnouncementFinished(NewActiveAnnouncementState.ErrorNoPermissions));
            dispatch(unauthorized());
            break;
          default:
            dispatch(newActiveAnnouncementFinished(NewActiveAnnouncementState.ErrorUnknown));
        }
      }
    );
  };
}

function newActiveAnnouncementStarted() {
  return {
    type: 'NEW_ACTIVE_ANNOUNCEMENT_STARTED'
  };
}

function newActiveAnnouncementFinished(state, announcement = {}) {
  return {
    type: 'NEW_ACTIVE_ANNOUNCEMENT_FINISHED',
    announcement: announcement,
    state: state,
  };
}

export const DeleteActiveAnnouncementState = {
  Idle: 'IDLE',
  InProgress: 'IN_PROGRESS',
  ErrorNoPermissions: 'ERROR_NO_PERMISSIONS',
  ErrorUnknown: 'ERROR_UNKNOWN',
};

const initDeleteActiveAnnouncementState = {
  state: DeleteActiveAnnouncementState.Idle,
};

function deleteActiveAnnouncementReducer(state = initDeleteActiveAnnouncementState, action) {
  switch (action.type) {
    case 'DELETE_ACTIVE_ANNOUNCEMENT_STARTED': {
      const stateUpdate = { state: DeleteActiveAnnouncementState.InProgress };
      return Object.assign({}, state, stateUpdate);
    }
    case 'DELETE_ACTIVE_ANNOUNCEMENT_FINISHED': {
      const stateUpdate = { state: action.state };
      return Object.assign({}, state, stateUpdate);
    }
    default: {
      return state;
    }
  }
}

export function deleteActiveAnnouncement(id) {
  return (dispatch, getState, { ContentApi }) => {
    dispatch(deleteActiveAnnouncementStarted());
    const session = getState().login.session;
    return ContentApi.deleteActiveAnnouncement(id, session.token).then(
      response => {
        dispatch(deleteActiveAnnouncementFinished(DeleteActiveAnnouncementState.Idle));
        dispatch(fetchingActiveAnnouncementFinished(ActiveAnnouncementState.Idle, {}));
      },
      error => {
        switch (error) {
          case ContentApi.ActiveAnnouncementError.Forbidden:
            dispatch(deleteActiveAnnouncementFinished(DeleteActiveAnnouncementState.ErrorNoPermissions));
            break;
          case ContentApi.ActiveAnnouncementError.Unauthorized:
            dispatch(deleteActiveAnnouncementFinished(DeleteActiveAnnouncementState.ErrorNoPermissions));
            dispatch(unauthorized());
            break;
          default:
            dispatch(deleteActiveAnnouncementFinished(NewActiveAnnouncementState.ErrorUnknown));
        }
      }
    );
  };
}

function deleteActiveAnnouncementStarted() {
  return {
    type: 'DELETE_ACTIVE_ANNOUNCEMENT_STARTED'
  };
}

function deleteActiveAnnouncementFinished(state) {
  return {
    type: 'DELETE_ACTIVE_ANNOUNCEMENT_FINISHED'
  };
}

export function announcementsReducer() {
  return combineReducers({
    form: combineForms({
      announcement: initNewAnnouncement 
    }, 'announcements.form'),
    activeAnnouncement: activeAnnouncementReducer,
    newActiveAnnouncement: newActiveAnnouncementReducer,
    deleteActiveAnnouncement: deleteActiveAnnouncementReducer,
  });
}
