import React from 'react';
import PropTypes from 'prop-types';
import { Message } from 'semantic-ui-react';
import { grossPrice, roundPriceValue } from '../../../utils/price';

export class OrderTotalPriceMessage extends React.PureComponent {
  static propTypes = {
    totalNetPriceValue: PropTypes.number.isRequired,
  }

  render() {
    const totalNetPriceValue = roundPriceValue(this.props.totalNetPriceValue);
    const totalGrossPriceValue = roundPriceValue(grossPrice(this.props.totalNetPriceValue));
    return (
      <Message>
        Całkowita wartość zamówienia: <b>{ totalNetPriceValue } PLN netto &rarr; { totalGrossPriceValue } PLN brutto</b>
      </Message>
    );
  }
}
