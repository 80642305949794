import PropTypes from 'prop-types';
import React from 'react';
import { Container } from 'semantic-ui-react';
import './ActiveAnnouncementBar.css';

export class ActiveAnnouncementBar extends React.PureComponent {
  static propTypes = {
    text: PropTypes.string.isRequired,
  }

  render() {
    return (
      <Container fluid className="activeAnnouncementBar">
        <Container className="cssmarquee">
          <span>
            { this.props.text }
          </span>
        </Container>
      </Container>
    );
  }
}
