import PropTypes from 'prop-types';
import React from 'react';
import Dropzone from 'react-dropzone';
import { actions, Control, Errors, Form } from 'react-redux-form';
import { Divider, Modal, Segment } from 'semantic-ui-react';
import { required } from '../../../../utils/common';
import '../../Dropzone.css';

export class NewFileDocumentModal extends React.PureComponent {
  static propTypes = {
    trigger: PropTypes.object.isRequired,
    uploadFileDocument: PropTypes.func.isRequired,
  }

  state = {
    isOpen: false,
    file: null
  };

  open = () => {
    this.setState({ isOpen: true });
  }

  close = () => {
    this.setState({ isOpen: false });
  }

  uploadFileError = () => null;

  handleSubmit = (form) => {
    this.props.uploadFileDocument(form.name, this.state.file);
    this.setState({ file: null });
    this.formDispatch(actions.change('fileDocuments.form.newFileDocument.name', ''));
    this.close();
  }

  handleFiles = (files) => {
    this.setState({ file: files[0] });
  }

  attachDispatch = (dispatch) => {
    this.formDispatch = dispatch;
  }

  render() {
    const formClassName = "ui form";

    const fileText = this.state.file !== null
      ? this.state.file.name
      : "Kliknij lub upuść plik tutaj.";

    return (
      <Modal closeIcon
             width='8' 
             size='small' 
             open={ this.state.isOpen }
             onOpen={ this.open }
             onClose={ this.close }
             trigger={ this.props.trigger }
             >
        <Modal.Header>Nowy Dokument</Modal.Header>
        <Modal.Content>
          <Form
            model="fileDocuments.form.newFileDocument"
            onSubmit={ this.handleSubmit }
            className={ formClassName }
            getDispatch= { (dispatch) => this.attachDispatch(dispatch) }
          >
            <div className="field">
              <Control.text
                model=".name" 
                placeholder="Nazwa dokumentu"
                validators={{ required }}
              />
              <Errors
                className="error"
                model=".name"
                show="touched"
                messages={{
                  required: 'Wymagane',
                }}
              />
            </div>

            <Dropzone className="dropzone" onDrop={ this.handleFiles }>
              <Segment className="dropzone" textAlign="center" secondary size='large'>
                <span>{ fileText }</span>
              </Segment>
            </Dropzone>

            <Divider/>

            <button className="ui button primary positive" type="submit" disabled={ this.state.file === null } >
              Zapisz
            </button>

            { this.uploadFileError() }
          </Form>
        </Modal.Content>
      </Modal>
    );
  }
}