import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Button, Container, Divider, Header, Icon, Loader, Message, Segment } from 'semantic-ui-react';
import { DeletedFileDocumentState, deleteFileDocument, fetchFileDocumentDownloadUrl, fetchFileDocuments, FileDocumentDownloadUrlState, FileDocumentListState, UploadedFileDocumentState, uploadFileDocument } from '../../../redux/fileDocuments';
import { ScrollToTopOnMount } from '../../ScrollToTopOnMount';
import { FileDocumentsTable } from './FileDocumentsTable';
import { NewFileDocumentModal } from '../Admin/FileDocuments/NewFileDocumentModal';

class FileDocumentsPure extends React.PureComponent {
  static propTypes = {
    isAdmin: PropTypes.bool.isRequired,
    fetchFileDocuments: PropTypes.func.isRequired,
    fileDocumentList: PropTypes.object.isRequired,
    uploadFileDocument: PropTypes.func.isRequired,
    uploadedFileDocument: PropTypes.object.isRequired,
    deleteFileDocument: PropTypes.func.isRequired,
    deletedFileDocument: PropTypes.object.isRequired,
    fileDocumentDownloadUrl: PropTypes.object.isRequired,
    fetchFileDocumentDownloadUrl: PropTypes.func.isRequired,
  }

  componentDidMount() {
    this.props.fetchFileDocuments();
  }

  maybeFileDocumentDeleteErrorMsg() {
    switch (this.props.deletedFileDocument.state) {
      case DeletedFileDocumentState.ErrorNoPermissions: {
        return (
          <Message negative>
            <Message.Header>Brak uprawnień</Message.Header>
            Nie można usunąć pliku. Nie posiadasz wystarczających uprawnień.
          </Message>
        );
      }
      case DeletedFileDocumentState.ErrorUnknown: {
        return (
          <Message negative>
            <Message.Header>Ups!</Message.Header>
            Nie można usunąć pliku. Nieznany błąd. Sprawdź połączenie z Internetem i spróbuj ponownie.
          </Message>
        );
      }
      default: {
        return null;
      }
    }
  }

  maybeFileDocumentUploadErrorMsg() {
    switch (this.props.uploadedFileDocument.state) {
      case UploadedFileDocumentState.ErrorNoPermissions: {
        return (
          <Message negative>
            <Message.Header>Brak uprawnień</Message.Header>
            Nie można wysłać pliku. Nie posiadasz wystarczających uprawnień.
          </Message>
        );
      }
      case UploadedFileDocumentState.ErrorUnknown: {
        return (
          <Message negative>
            <Message.Header>Ups!</Message.Header>
            Nie można wysłać pliku. Nieznany błąd. Sprawdź połączenie z Internetem i spróbuj ponownie.
          </Message>
        );
      }
      default: {
        return null;
      }
    }
  }

  maybeFetchFileDocumentsError() {
    switch (this.props.fileDocumentList.state) {
      case FileDocumentListState.ErrorNoPermissions: {
        return (
          <Message negative>
            <Message.Header>Brak uprawnień</Message.Header>
            Nie posiadasz wystarczających uprawnień do pobrania listy dokumentów.
          </Message>
        );
      }
      case FileDocumentListState.ErrorUnknown: {
        return (
          <Message negative>
            <Message.Header>Ups!</Message.Header>
            Nie można pobrać listy dokumentów. Nieznany błąd. Sprawdź połączenie z Internetem i spróbuj ponownie.
          </Message>
        );
      }
      default: {
        return null;
      }
    }
  }

  render() {
    const { deletedFileDocument, fileDocumentList, uploadedFileDocument } = this.props;
    const fileDocumentListState = fileDocumentList.state;
    const uploadedFileDocumentState = uploadedFileDocument.state;
    const deletedFileDocumentState = deletedFileDocument.state;

    const isLoading = fileDocumentListState === FileDocumentListState.Fetching
      || uploadedFileDocumentState === UploadedFileDocumentState.Uploading
      || deletedFileDocumentState === DeletedFileDocumentState.Deleting;

    const loader = isLoading ? <Segment basic><Loader active /></Segment> : null;

    const newFileTrigger = 
      <Button disabled={ isLoading }>
        <Icon name="add"/>
        Nowy
      </Button>;

    const isSuccess = fileDocumentListState === FileDocumentListState.Idle;

    const documentsTable = isLoading || !isSuccess
      ? null
      : <FileDocumentsTable fileDocuments={ fileDocumentList.fileDocuments }
                            fileDocumentDownloadUrl={ this.props.fileDocumentDownloadUrl }
                            fetchFileDocumentDownloadUrl={ this.props.fetchFileDocumentDownloadUrl }
                            deleteFileDocument={ this.props.isAdmin ? this.props.deleteFileDocument : null }
                            />;

    return (
      <Container>
        <ScrollToTopOnMount/>
        <Header as='h1' icon textAlign='center'>
          <Icon name='file' circular/>
          <Header.Content>Dokumenty</Header.Content>
        </Header>
        <Divider hidden/>
        { this.props.isAdmin
            ? <NewFileDocumentModal trigger={ newFileTrigger }
                                    uploadFileDocument={ this.props.uploadFileDocument }
                                    />
            : null
        }
        { loader }
        { this.maybeFileDocumentDeleteErrorMsg() }
        { this.maybeFileDocumentUploadErrorMsg() }
        { this.maybeFetchFileDocumentsError() }
        { documentsTable }
      </Container>
    );
  }
}

const mapStateToProps = state => {
  const session = state.login.session;
  return {
    fileDocumentList: state.fileDocuments.fileDocumentList,
    uploadedFileDocument: state.fileDocuments.uploadedFileDocument,
    deletedFileDocument: state.fileDocuments.deletedFileDocument,
    fileDocumentDownloadUrl: state.fileDocuments.fileDocumentDownloadUrl,
    isAdmin: !!session.user && session.user.isAdmin(),
  };
};

const mapDispatchToProps = dispatch => ({
  fetchFileDocuments: () => dispatch(fetchFileDocuments()),
  uploadFileDocument: (name, file) => dispatch(uploadFileDocument(name, file)),
  deleteFileDocument: (uuid) => dispatch(deleteFileDocument(uuid)),
  fetchFileDocumentDownloadUrl: (uuid) => dispatch(fetchFileDocumentDownloadUrl(uuid)),
});

export const FileDocuments =
  connect(mapStateToProps, mapDispatchToProps)(FileDocumentsPure);