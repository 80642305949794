import React from 'react';
import PropTypes from 'prop-types';
import { Segment } from 'semantic-ui-react';
import { AppMedia, MediaStyles } from '../../AppMedia';
import { ItemPicker } from '../../ItemPicker';
import { FilterButton, FilterSegment } from '../../Filters';

export class CategoryFilter extends React.PureComponent {
  static propTypes = {
    categories: PropTypes.array.isRequired,
    activeCategoryTag: PropTypes.string,
    onCategoryToggled: PropTypes.func.isRequired,
  }

  onCategoryToggled = (tag) => () => this.props.onCategoryToggled(tag);

  categories = () => [{ tag: null, name: 'Wszystkie' }].concat(this.props.categories)

  renderAsDropdown = () => {
    return (
      <ItemPicker selectedTag = { this.props.activeCategoryTag }
                  items = { this.categories() }
                  onResultSelect = { this.props.onCategoryToggled }
                  placeholder = "Filtruj kategorie"
                  fluid
                  />
    );
  }

  renderAsButtons = () => {
    const categories = this.props.categories.map(category => {
      const activeCategory = category.tag === this.props.activeCategoryTag;
      return (
        <FilterButton key={ category.tag } 
                      active={ activeCategory }
                      onClick={ this.onCategoryToggled(category.tag) }
                      label={ category.name }
                      />
      );
    });
    return (
      <FilterSegment>
        { categories }
      </FilterSegment>
    );
  }

  render() {
    return (
      <>
        <style>{MediaStyles}</style>
        <AppMedia.MediaContextProvider>
          <Segment.Group className='opaqueGroup'>
            <Segment as={ AppMedia.Media }
                     lessThan="tablet"
                     className="opaqueSegment"
                     basic>
              { this.renderAsDropdown() }
            </Segment>
            <Segment as={ AppMedia.Media }
                     greaterThanOrEqual="tablet"
                     className="opaqueSegment"
                     className='filters'
                     basic>
              { this.renderAsButtons() }
            </Segment>
          </Segment.Group>
        </AppMedia.MediaContextProvider>
      </>
    );
  }
}
