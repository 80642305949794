import React from 'react';
import { addVatRoundToInteger, roundPriceValue } from '../utils/price';
import './PriceText.css';

function basicPriceText({ netPrice, promoNetPrice }, retail) {
  return promoNetPrice !== undefined
    ? <span className="priceText promo">
        <s>{ retail ? addVatRoundToInteger(netPrice) : roundPriceValue(netPrice) }</s> { retail ? addVatRoundToInteger(promoNetPrice) : roundPriceValue(promoNetPrice) } PLN
      </span>
    : <span className="priceText">{ retail ? addVatRoundToInteger(netPrice) : roundPriceValue(netPrice) } PLN</span>;
}

function priceText({ netPrice, promoNetPrice }, retail) {
  return promoNetPrice !== undefined
    ? <span className="priceText promo">
        <s>{ retail ? addVatRoundToInteger(netPrice) : roundPriceValue(netPrice) }</s> <big>{ retail ? addVatRoundToInteger(promoNetPrice) : roundPriceValue(promoNetPrice) }</big> PLN
      </span>
    : <span className="priceText"><big>{ retail ? addVatRoundToInteger(netPrice) : roundPriceValue(netPrice) }</big> PLN</span>;
}

export const PriceText = ({ price, basic, retail }) => {
  if (price === null) {
    return null;
  }
  return basic ? basicPriceText(price, retail) : priceText(price, retail);
};
