import { connect } from 'react-redux';
import { OrdersPure } from '../../Orders/Orders';
import { fetchOrderSummaries } from '../../../../redux/orders.js';

const mapStateToProps = state => {
  return {
    orderSummaryList: state.orders.orderSummaryList,
  };
};

const mapDispatchToProps = dispatch => ({
  fetchOrderSummaries: (take, customerId, ordersState) => 
    dispatch(fetchOrderSummaries({ take, customerId, ordersState })),
});

export const Orders =
  connect(mapStateToProps, mapDispatchToProps)(OrdersPure);
