import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Container, Divider } from 'semantic-ui-react';
import { Icon, Grid, Header, Loader, Message, Segment, Table } from 'semantic-ui-react';
import { Visibility } from 'semantic-ui-react';
import { ScrollToTopOnMount } from '../../../ScrollToTopOnMount';
import { ItemPicker } from '../../../ItemPicker';
import { productPrice } from '../../../../utils/price';
import { availabilityDateText } from '../../../../utils/product';
import { fetchProducts, ProductListState } from '../../../../redux/store.js';
import { fetchCategories, CategoryListState } from '../../../../redux/store.js';
import { requestProductsDelayedFetch } from '../../../../redux/store.js';
import { Messages } from "../../../Messages";
import { SearchField } from "../../../SearchField";
import { PriceText } from '../../../PriceText';
import { FilterButton } from '../../../Filters';
import './Products.css';

const ProductsBatchTake = 25;
const AllCategoriesTag = "__all__";

class ProductsPure extends React.PureComponent {
  static propTypes = {
    fetchProducts: PropTypes.func.isRequired,
    productList: PropTypes.object.isRequired,
    fetchCategories: PropTypes.func.isRequired,
    categoryList: PropTypes.object.isRequired,
    searchProducts: PropTypes.func.isRequired,
    productsSearchQuery: PropTypes.string.isRequired,
    history: PropTypes.object.isRequired,
  }

  componentDidMount() {
    this.fetchProducts();
    this.props.fetchCategories();
  }

  fetchProducts = ({ take,
                     categoryTag = this.props.productList.categoryTag,
                     searchQuery = this.props.productList.searchQuery,
                     flags = this.props.productList.flags } = {}) => {
    if (take === undefined) {
      const currTake = this.props.productList.take;
      take = currTake && currTake % ProductsBatchTake === 0 ? currTake : ProductsBatchTake;
    }
    this.props.fetchProducts(categoryTag, searchQuery, flags, take);
  }

  tryFetchMoreProducts = () => {
    const productList = this.props.productList;
    if (productList.products.length === productList.take) {
      this.fetchProducts({ take: productList.take + ProductsBatchTake });
    }
  }

  onFlagToggled = (flag) => () => {
    const { flags } = this.props.productList;
    if (flags.indexOf(flag) === -1) {
      // Activate
      this.fetchProducts({ take: ProductsBatchTake,
                           flags: flags.concat([flag]) });
    } else {
      // Deactivate
      this.fetchProducts({ take: ProductsBatchTake,
                           flags: flags.filter(f => f !== flag) });
    }
  }

  productSelected = (product) => () => {
    const redirectUrl = this.props.location.pathname + '/' + product.id;
    this.props.history.push(redirectUrl);
  }

  categoryOptions = () =>
    [{ name: "Wszystkie kategorie", tag: AllCategoriesTag }]
      .concat(this.props.categoryList.categories);

  selectedCategoryTag = () => {
    const categoryTag = this.props.productList.categoryTag;
    return categoryTag ? categoryTag : AllCategoriesTag;
  }

  selectCategory = (tag) => {
    const categoryTag = tag === AllCategoriesTag ? null : tag;
    this.fetchProducts({ categoryTag });
  }

  render() {
    const { productList, categoryList } = this.props;
    const productsState = productList.state;
    const categoriesState = categoryList.state;
    const isLoading = productsState === ProductListState.Fetching 
                   || categoriesState === CategoryListState.Fetching;
    const now = moment.now();

    const loader = isLoading ? <Segment basic><Loader active /></Segment> : null;

    const productRows = isLoading
      ? []
      : productList.products.map(product => {
          const availabilityInfo = availabilityDateText(now, product.availableSince);
          const inSale = product.flags.indexOf('instock') !== -1;
          return (
            <Table.Row key={ product.id }
                      onClick={ this.productSelected(product) }
                      className="productTableRow"
                      negative={ !inSale }
                      warning={ product.price === undefined }
                      >
              <Table.Cell>{ product.index }</Table.Cell>
              <Table.Cell>{ product.name }</Table.Cell>
              <Table.Cell>{ availabilityInfo }</Table.Cell>
              <Table.Cell>
                { inSale ? 'W sprzedaży' : 'Wycofany' }
              </Table.Cell>
              <Table.Cell>
                <PriceText basic price={ productPrice(product) }/>
              </Table.Cell>
            </Table.Row>
          );
        });

    const haveProducts = productRows.length > 0;
    const isSuccess = categoriesState === CategoryListState.Idle 
                   && (productsState === ProductListState.Idle 
                    || productsState === ProductListState.FetchingMore);

    const productsTable = haveProducts && isSuccess
      ? <Visibility onBottomVisible={ this.tryFetchMoreProducts } 
                    once={ false }
                    className="adminProductsList">
          <Table singleLine selectable>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Indeks</Table.HeaderCell>
                <Table.HeaderCell>Nazwa</Table.HeaderCell>
                <Table.HeaderCell>Dostępność</Table.HeaderCell>
                <Table.HeaderCell>Stan</Table.HeaderCell>
                <Table.HeaderCell>Cena</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              { productRows }
            </Table.Body>
          </Table>
        </Visibility>
        : null;

    const noProductsMsg = !haveProducts && isSuccess
      ? <Message>Brak produktów</Message>
      : null;

    const noPermissionsMessage = productsState === ProductListState.ErrorNoPermissions
      ? Messages.NoPermissions 
      : null;

    const unknownErrorMessage = !noPermissionsMessage 
                             && (productsState === ProductListState.ErrorUnknown 
                              || categoriesState === CategoryListState.ErrorUnknown)
      ? Messages.UnknownError 
      : null;

    const loaderMore = productsState === ProductListState.FetchingMore
      ? <Segment basic textAlign="center"><Loader active /></Segment>
      : null;

    return (
      <Container>
        <ScrollToTopOnMount/>
        <Header as='h1' icon textAlign='center'>
          <Icon name='list ul' circular/>
          <Header.Content>Lista produktów</Header.Content>
        </Header>
        <Divider hidden/>
        <Grid className="adminProductsBar" verticalAlign="middle" stackable>
          <Grid.Row>
            <Grid.Column width={ 12 }/>
            <Grid.Column textAlign="right" width={ 4 }>
              <SearchField label="Szukaj produktu"
                           searchValue={ this.props.productsSearchQuery }
                           onSearchValueChanged={ this.props.searchProducts }
                           fluid
                           />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={ 2 }>
              <Link to="/admin/products/new" className="ui button">
                <Icon name='add'/>
                Nowy
              </Link>
            </Grid.Column>
            <Grid.Column width={ 10 } textAlign="center" verticalAlign="middle">
              <FilterButton label="Alfabetycznie"
                            active={ productList.flags.indexOf('sort-alphabetically') !== -1 }
                            onClick={ this.onFlagToggled("sort-alphabetically") }
                            />
              <FilterButton label="Nowości" 
                            active={ productList.flags.indexOf('new') !== -1 }
                            onClick={ this.onFlagToggled('new') }
                            />
              <FilterButton label="Promocje" 
                            active={ productList.flags.indexOf('promo') !== -1 }
                            onClick={ this.onFlagToggled('promo') }
                            />
              <FilterButton label="W sprzedaży" 
                            active={ productList.flags.indexOf('instock') !== -1 }
                            onClick={ this.onFlagToggled('instock') }
                            />
            </Grid.Column>
            <Grid.Column textAlign="right" width={ 4 }>
              <ItemPicker items={ this.categoryOptions() } 
                          selectedTag={ this.selectedCategoryTag() }
                          onResultSelect={ this.selectCategory }
                          fluid
                          />
            </Grid.Column>
          </Grid.Row>
        </Grid>
        { loader }
        { productsTable }
        { noProductsMsg }
        { noPermissionsMessage }
        { unknownErrorMessage }
        { loaderMore}
      </Container>
    );

  }
}

const mapStateToProps = state => ({
  categoryList: state.store.categoryList,
  productList: state.store.productList,
  productsSearchQuery: state.store.productsDelayedFetchRequest.searchQuery,
});

const mapDispatchToProps = dispatch => ({
  fetchProducts: (categoryTag, searchQuery, flags, take) => dispatch(
    fetchProducts({ categoryTag, take, searchQuery, flags, productsFor: { admin: true } })
  ),
  fetchCategories: () => dispatch(fetchCategories()),
  searchProducts: (searchQuery) => dispatch(
    requestProductsDelayedFetch({ 
      searchQuery, 
      productsFor: { admin: true }, 
      take: ProductsBatchTake
    })
  ),
});

export const Products = connect(mapStateToProps, mapDispatchToProps)(ProductsPure);
