import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { isUrl, CustomerRegistrationUrl, LoginUrl } from './RoutesCommon';

const PublicRoutePure =
  ({ component, render, isLoginRequired, isCustomerRegistrationRequired, ...rest }) => (
    <Route {...rest} render={ props => {
      return (
        isLoginRequired && !isUrl(props, LoginUrl) 
          ? <Redirect to={{
              pathname: LoginUrl,
              state: { from: props.location },
            }} />
          : isCustomerRegistrationRequired && !isUrl(props, CustomerRegistrationUrl) 
            ? <Redirect to={{
                pathname: CustomerRegistrationUrl,
                state: { from: props.location },
              }} />
            : component ? React.createElement(component, props) : render(props)
      );
    }}/>
  );

const mapStateToProps = state => {
  const session = state.login.session;
  return {
    isLoginRequired: session.isLoginRequired,
    isCustomerRegistrationRequired: !!session.user && !session.user.isAdmin() && !session.customer,
  };
};

export const PublicRoute = connect(mapStateToProps)(PublicRoutePure);
