import React from 'react';
import PropTypes from 'prop-types';
import { Input } from 'semantic-ui-react';
import './SearchField.css';

export class SearchField extends React.PureComponent {
  static propTypes = {
    label: PropTypes.string.isRequired,
    searchValue: PropTypes.string.isRequired,
    onSearchValueChanged: PropTypes.func.isRequired,
    fluid: PropTypes.bool,
    size: PropTypes.string,
  }

  onChange = (event, data) => {
    this.props.onSearchValueChanged(data.value);
  }

  render() {
    return (
      <Input icon="search" 
             size={ this.props.size }
             placeholder={ this.props.label }
             value={ this.props.searchValue }
             onChange={ this.onChange }
             fluid={ this.props.fluid }
             className="productSearch"
             />
    );
  }
}
