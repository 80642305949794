import React from 'react';

export class ScrollToTopOnMount extends React.PureComponent {
  componentDidMount(prevProps) {
    window.scrollTo(0, 0);
  }

  render() {
    return null;
  }
}
